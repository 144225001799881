import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable()
export class ConfirmDialogService {

  private subject = new Subject<any>();

  constructor() { }

  confirmThis(title: string, text: string, nFn: () => void, yFn: () => void) {
    this.setConfirmation(title, text, nFn, yFn);
  }

  setConfirmation(title: string, text: string, nFn: () => void, yFn: () => void) {
    const that = this;
    this.subject.next({
      type: 'confirm',
      title,
      text,
      nFn:
        () => {
          that.subject.next();
          nFn();
        },
      yFn:
        () => {
          that.subject.next();
          yFn();
        }
    });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

}
