<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.HOUSE'|translate" [optional]="'LABEL.ENTITY.HOUSE.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="houseForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.HOUSE'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Key row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('id')">
              <label class="control-label" for="id">
                {{ 'LABEL.FIELD.HOUSE.ID'|translate }}
              </label>
              <input
                formControlName="id"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.ID':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('id')?.msg|translate:err('id')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Key row -->

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('batch')">
              <label class="control-label" for="batch">
                {{ 'LABEL.FIELD.HOUSE.BATCH'|translate }}
              </label>
              <input
                formControlName="batch"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.BATCH':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('batch')?.msg|translate:err('batch')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (2) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('source')">
              <label class="control-label" for="source">
                {{ 'LABEL.FIELD.HOUSE.SOURCE'|translate }}
              </label>
              <input
                formControlName="source"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.SOURCE':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('source')?.msg|translate:err('source')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (3) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('typology')">
              <label class="control-label" for="typology">
                {{ 'LABEL.FIELD.HOUSE.TYPOLOGY'|translate }}
              </label>
              <input
                formControlName="typology"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.TYPOLOGY':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('typology')?.msg|translate:err('typology')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (4) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('location')">
              <label class="control-label" for="location">
                {{ 'LABEL.FIELD.HOUSE.LOCATION'|translate }}
              </label>
              <input
                formControlName="location"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.LOCATION':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('location')?.msg|translate:err('location')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (5) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('section')">
              <label class="control-label" for="section">
                {{ 'LABEL.FIELD.HOUSE.SECTION'|translate }}
              </label>
              <input
                formControlName="section"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.SECTION':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('section')?.msg|translate:err('section')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (6) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('address')">
              <label class="control-label" for="address">
                {{ 'LABEL.FIELD.HOUSE.ADDRESS'|translate }}
              </label>
              <input
                formControlName="address"
                maxlength="200"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.ADDRESS':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('address')?.msg|translate:err('address')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (7) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('town')">
              <label class="control-label" for="town">
                {{ 'LABEL.FIELD.HOUSE.TOWN'|translate }}
              </label>
              <div class="input-group">
                <input
                  formControlName="town"
                  typeaheadOptionField="name"
                  [typeaheadMinLength]="0"
                  [typeaheadAsync]="true"
                  [typeahead]="townoptions"
                  (typeaheadOnSelect)="townOnSelected($event)"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.TOWN':'')|translate }}"
                  class="form-control">
                  <span class="input-group-addon">
                    <i *ngIf="data.town?.id" class="fa fa-search" [routerLink]="['/towns', data.town.id, 'view']"></i>
                    <i *ngIf="!data.town?.id" class="fa fa-search"></i>
                  </span>
                </div>
              <span class="help-block">{{err('town')?.msg|translate:err('town')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (8) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('floor')">
              <label class="control-label" for="floor">
                {{ 'LABEL.FIELD.HOUSE.FLOOR'|translate }}
              </label>
              <input
                formControlName="floor"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.FLOOR':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('floor')?.msg|translate:err('floor')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (9) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('lat')">
              <label class="control-label" for="lat">
                {{ 'LABEL.FIELD.HOUSE.LAT'|translate }}
              </label>
              <input
                formControlName="lat"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.LAT':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('lat')?.msg|translate:err('lat')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (10) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('lon')">
              <label class="control-label" for="lon">
                {{ 'LABEL.FIELD.HOUSE.LON'|translate }}
              </label>
              <input
                formControlName="lon"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.LON':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('lon')?.msg|translate:err('lon')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (11) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('price')">
              <label class="control-label" for="price">
                {{ 'LABEL.FIELD.HOUSE.PRICE'|translate }}
              </label>
              <input
                formControlName="price"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.PRICE':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('price')?.msg|translate:err('price')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (12) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('oldprice')">
              <label class="control-label" for="oldprice">
                {{ 'LABEL.FIELD.HOUSE.OLDPRICE'|translate }}
              </label>
              <input
                formControlName="oldprice"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.OLDPRICE':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('oldprice')?.msg|translate:err('oldprice')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (13) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('intext')">
              <label class="control-label" for="intext">
                {{ 'LABEL.FIELD.HOUSE.INTEXT'|translate }}
              </label>
              <input
                formControlName="intext"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.INTEXT':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('intext')?.msg|translate:err('intext')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (14) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('lift')">
              <label class="control-label" for="lift">
                {{ 'LABEL.FIELD.HOUSE.LIFT'|translate }}
              </label>
              <input
                formControlName="lift"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.LIFT':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('lift')?.msg|translate:err('lift')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (15) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('area')">
              <label class="control-label" for="area">
                {{ 'LABEL.FIELD.HOUSE.AREA'|translate }}
              </label>
              <input
                formControlName="area"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.AREA':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('area')?.msg|translate:err('area')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (16) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('rooms')">
              <label class="control-label" for="rooms">
                {{ 'LABEL.FIELD.HOUSE.ROOMS'|translate }}
              </label>
              <input
                formControlName="rooms"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.ROOMS':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('rooms')?.msg|translate:err('rooms')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (17) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('baths')">
              <label class="control-label" for="baths">
                {{ 'LABEL.FIELD.HOUSE.BATHS'|translate }}
              </label>
              <input
                formControlName="baths"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.BATHS':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('baths')?.msg|translate:err('baths')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (18) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('terrace')">
              <label class="control-label" for="terrace">
                {{ 'LABEL.FIELD.HOUSE.TERRACE'|translate }}
              </label>
              <input
                formControlName="terrace"
                maxlength="100"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.TERRACE':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('terrace')?.msg|translate:err('terrace')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (19) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('parking')">
              <label class="control-label" for="parking">
                {{ 'LABEL.FIELD.HOUSE.PARKING'|translate }}
              </label>
              <input
                formControlName="parking"
                maxlength="100"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.PARKING':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('parking')?.msg|translate:err('parking')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (20) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('pool')">
              <label class="control-label" for="pool">
                {{ 'LABEL.FIELD.HOUSE.POOL'|translate }}
              </label>
              <input
                formControlName="pool"
                maxlength="100"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.POOL':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('pool')?.msg|translate:err('pool')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (21) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('garden')">
              <label class="control-label" for="garden">
                {{ 'LABEL.FIELD.HOUSE.GARDEN'|translate }}
              </label>
              <input
                formControlName="garden"
                maxlength="100"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.GARDEN':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('garden')?.msg|translate:err('garden')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (22) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('condition')">
              <label class="control-label" for="condition">
                {{ 'LABEL.FIELD.HOUSE.CONDITION'|translate }}
              </label>
              <input
                formControlName="condition"
                maxlength="100"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.CONDITION':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('condition')?.msg|translate:err('condition')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (23) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('status')">
              <label class="control-label" for="status">
                {{ 'LABEL.FIELD.HOUSE.STATUS'|translate }}
              </label>
              <input
                formControlName="status"
                maxlength="100"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.STATUS':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('status')?.msg|translate:err('status')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (24) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('comments')">
              <label class="control-label" for="comments">
                {{ 'LABEL.FIELD.HOUSE.COMMENTS'|translate }}
              </label>
              <textarea
                formControlName="comments"
                autogrow [maximumHeight]="300" [minimumHeight]="60"
                (keydown.enter)="$event.stopPropagation();"
                maxlength="4000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.COMMENTS':'')|translate }}"
                class="form-control" autocomplete="off">
              </textarea>
              <span class="help-block">{{err('comments')?.msg|translate:err('comments')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (25) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('features')">
              <label class="control-label" for="features">
                {{ 'LABEL.FIELD.HOUSE.FEATURES'|translate }}
              </label>
              <textarea
                formControlName="features"
                autogrow [maximumHeight]="300" [minimumHeight]="60"
                (keydown.enter)="$event.stopPropagation();"
                maxlength="4000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.FEATURES':'')|translate }}"
                class="form-control" autocomplete="off">
              </textarea>
              <span class="help-block">{{err('features')?.msg|translate:err('features')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (26) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('ownertype')">
              <label class="control-label" for="ownertype">
                {{ 'LABEL.FIELD.HOUSE.OWNERTYPE'|translate }}
              </label>
              <input
                formControlName="ownertype"
                maxlength="10"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.OWNERTYPE':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('ownertype')?.msg|translate:err('ownertype')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (27) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('ownername')">
              <label class="control-label" for="ownername">
                {{ 'LABEL.FIELD.HOUSE.OWNERNAME'|translate }}
              </label>
              <input
                formControlName="ownername"
                maxlength="100"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.OWNERNAME':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('ownername')?.msg|translate:err('ownername')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (28) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('photo')">
              <label class="control-label" for="photo">
                {{ 'LABEL.FIELD.HOUSE.PHOTO'|translate }}
              </label>
              <input
                formControlName="photo"
                maxlength="300"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOUSE.PHOTO':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('photo')?.msg|translate:err('photo')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="houseForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:HOUSE')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="houseForm.invalid || houseForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:HOUSE')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:HOUSE')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>
        <!-- Tabbed panel-->
        <div class="nav-tabs-custom">

          <!-- Tabs labels -->
          <ul class="nav nav-tabs">
            <li class="active">
              <a href="#tabHistory" data-toggle="tab">{{ 'LABEL.ENTITY.HISTORY'|translate }}</a>
            </li>
          </ul>

            <!-- Tabs content -->
            <div class="tab-content">

            <!-- Tab -->
            <div class="active tab-pane" id="tabHistory">
              <div class="box-body">
              <app-history-house-table *ngIf="mode!='add' && data.id" 
                parent="houses" 
                [value]="data.id">
              </app-history-house-table>
              </div>
            </div> <!-- end tab -->
    
            </div> <!-- end tab-content -->

        </div>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
