<div class="content-wrapper">

  <!-- Header -->
  <app-header-content [header]="'LABEL.ENTITY.VALUATION'|translate" [optional]="'LABEL.ENTITY.VALUATION.DESCRIPTION'|translate"></app-header-content>

  <!-- Section -->
  <section class="content">
  <div class="row">
  <div class="col-xs-12">
  <div class="box box-primary">

	<!-- Loading spinner -->
	<app-spinner *ngIf="isLoading"></app-spinner>

	<!-- Data -->
	<div *ngIf="data">

	  <!-- Header -->
	  <div class="box-header with-border">
		<h3 class="box-title">{{ 'LABEL.ENTITY.VALUATION.TABLE'|translate }}</h3>
	  </div> <!-- end Header -->
	
	  <!-- Body -->
	  <div class="box-body dataTables_wrapper form-inline dt-bootstrap">

		<!-- Table -->
		<div class="row">
		<div class="col-sm-12">
		<table id="table-valuation" class="table table-bordered table-hover dataTable" role="grid">

		  <!-- Titles row -->	
		  <thead>
			<tr role="row">
			  <!-- <th style="width:1%;"><div class="checkbox"><input type="checkbox"><label></label></div></th> -->
			  <th class="col-lg-1 " [ngClass]="isSorted('company.name')" (click)="doSort('company.name')">
			    {{'LABEL.FIELD.VALUATION.COMPANY'|translate}}
			  </th>
			  <th class="col-lg-1 " [ngClass]="isSorted('reference')" (click)="doSort('reference')">
			    {{'LABEL.FIELD.VALUATION.REFERENCE'|translate}}
			  </th>
			  <th class="col-lg-1  hidden-xs" [ngClass]="isSorted('zone')" (click)="doSort('zone')">
			    {{'LABEL.FIELD.VALUATION.ZONE'|translate}}
			  </th>
			  <th class="col-lg-1  hidden-xs" [ngClass]="isSorted('address')" (click)="doSort('address')">
			    {{'LABEL.FIELD.VALUATION.ADDRESS'|translate}}
			  </th>
			  <th class="col-lg-1  hidden-sm hidden-xs" [ngClass]="isSorted('zip')" (click)="doSort('zip')">
			    {{'LABEL.FIELD.VALUATION.ZIP'|translate}}
			  </th>
			  <th class="col-lg-1  hidden-sm hidden-xs" [ngClass]="isSorted('city')" (click)="doSort('city')">
			    {{'LABEL.FIELD.VALUATION.CITY'|translate}}
			  </th>
			  <th class="col-lg-1  hidden-md hidden-sm hidden-xs" [ngClass]="isSorted('form')" (click)="doSort('form')">
			    {{'LABEL.FIELD.VALUATION.FORM'|translate}}
			  </th>
			  <th class="col-lg-1  hidden-md hidden-sm hidden-xs" [ngClass]="isSorted('contact.email')" (click)="doSort('contact.email')">
			    {{'LABEL.FIELD.VALUATION.CONTACT'|translate}}
			  </th>
			  <th class="col-lg-1  hidden-md hidden-sm hidden-xs" [ngClass]="isSorted('audit.createdon')" (click)="doSort('audit.createdon')">
			    {{'LABEL.FIELD.VALUATION.CREATEDON'|translate}}
			  </th>
			  <th style="width:1%;">{{'LABEL.ACTIONS'|translate}}</th>
			</tr>
		  </thead>

		  <!-- Filters row -->	
		  <thead>
			<tr role="row">
			  <!-- <th></th> -->
			  <th class="">
			    <select [(ngModel)]="search.company_id" (input)="doFilter($event)" class="form-control" style="width: 100%;">
			  	<option value="">{{ 'LABEL.ALL'|translate }}</option>
			  	<option *ngFor="let item of companyoptions" [value]="item.id">{{ item.name }}</option>
			    </select>
			  </th>
			  <th class="">
			    <input 
			  	[(ngModel)]="search.reference" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.VALUATION.REFERENCE'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th class=" hidden-xs">
			    <input 
			  	[(ngModel)]="search.zone" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.VALUATION.ZONE'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th class=" hidden-xs">
			    <input 
			  	[(ngModel)]="search.address" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.VALUATION.ADDRESS'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th class=" hidden-sm hidden-xs">
			    <input 
			  	[(ngModel)]="search.zip" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.VALUATION.ZIP'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th class=" hidden-sm hidden-xs">
			    <input 
			  	[(ngModel)]="search.city" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.VALUATION.CITY'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th class=" hidden-md hidden-sm hidden-xs">
			    <input 
			  	[(ngModel)]="search.form" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.VALUATION.FORM'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th class=" hidden-md hidden-sm hidden-xs">
			    <select [(ngModel)]="search.contact_id" (input)="doFilter($event)" class="form-control" style="width: 100%;">
			  	<option value="">{{ 'LABEL.ALL'|translate }}</option>
			  	<option *ngFor="let item of contactoptions" [value]="item.id">{{ item.email }}</option>
			    </select>
			  </th>
			  <th class=" hidden-md hidden-sm hidden-xs">
			    <input
			  	[(ngModel)]="search.createdon" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.VALUATION.CREATEDON'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th>
				<button (click)="clearFilter()" type="button" class="btn btn-default btn-sm">
				  <i class="fa fa-filter" style="position: relative;">
					<i class="fa fa-ban" style="position: absolute; left: 0px; color:Tomato"></i>
				  </i>
				</button>
			  </th>
			</tr>
		  </thead>

		  <!-- Data rows -->					
		  <tbody>
			<tr role="row"[ngClass]="{ odd:odd, even:even }" *ngFor="let o of data.content; odd as odd; even as even">
			  <!-- <td><div class="checkbox"><input type="checkbox"><label></label></div></td> -->
			  <td class="">
			    {{ o.company?.name }}
			  </td>
			  <td class="">
			    {{ o.reference }}
			  </td>
			  <td class=" hidden-xs">
			    {{ o.zone }}
			  </td>
			  <td class=" hidden-xs">
			    {{ o.address }}
			  </td>
			  <td class=" hidden-sm hidden-xs">
			    {{ o.zip }}
			  </td>
			  <td class=" hidden-sm hidden-xs">
			    {{ o.city }}
			  </td>
			  <td class=" hidden-md hidden-sm hidden-xs">
			    {{ o.form }}
			  </td>
			  <td class=" hidden-md hidden-sm hidden-xs">
			    {{ o.contact?.email }}
			  </td>
			  <td class=" hidden-md hidden-sm hidden-xs">
			    {{ o.audit.createdon|localDate:'L LTS' }}
			  </td>
			  <td>
				<div class="btn-group">
				  <button *ngIf="mode!='view' && auth.canShow('UPDATE:VALUATION')"
					[routerLink]="['/valuations', o.id, 'edit']"
					type="button" class="btn btn-default btn-sm"><i class="fa fa-edit"></i>
				  </button>
				  <button
					[routerLink]="['/valuations', o.id, 'view']"
					type="button" class="btn btn-default btn-sm"><i class="fa fa-eye"></i>
				  </button>
				</div>
			  </td>
			</tr>
		  </tbody>

		</table>
		</div>
		</div> <!-- end Table -->
	
		<!-- Pagination -->
		<app-pagination [total]="data.totalElements" [size]="data.size" [num]="data.numberOfElements" (go)="doPage($event)"></app-pagination>
	
	  </div> <!-- end Body -->

	</div> <!-- end Data -->
  </div>
  </div>
  </div>
  </section> <!-- end Section --> 

</div> <!-- end content-wrapper -->
