import { Audit } from 'app/shared/model/audit';

// HomeType class
export class HomeType {

  // Key
  id: number;

  // Fields
  name: string;

  // Audit
  audit : Audit = new Audit();
}

