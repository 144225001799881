<div *ngIf="globals.config" class="login-box">

  <app-spinner *ngIf="isLoading"></app-spinner>

  <div *ngIf="globals.config" class="login-logo">
    <a href="#" [innerHTML]="globals.config.company"></a>
  </div>

  <div class="login-box-body">
    
    <p class="login-box-msg">{{ 'LABEL.LOGIN.MESSAGE'|translate }}</p>

    <form [formGroup]="loginForm">

      <div class="form-group" [class.has-error]="err('user')">
        <label class="control-label" for="user">
          {{ 'LABEL.LOGIN.USER'|translate }} <i class="text-danger fa fa-exclamation"></i>
        </label>
        <input
          formControlName="user"
          maxlength="40"
          placeholder="{{ 'MESSAGE.ENTER'|translate }} {{ 'LABEL.LOGIN.USER'|translate }}"
          type="text" class="form-control" autocomplete="off" >
        <span class="help-block">{{err('user')?.msg|translate:err('user')?.param}}</span>
      </div>

      <div class="form-group" [class.has-error]="err('password')">
        <label class="control-label" for="password">
          {{ 'LABEL.LOGIN.PASSWORD'|translate }} <i class="text-danger fa fa-exclamation"></i>
        </label>
        <input
          passwordview
          formControlName="password"
          maxlength="40"
          placeholder="{{ 'MESSAGE.ENTER'|translate }} {{ 'LABEL.LOGIN.PASSWORD'|translate }}"
          type="password" class="form-control" autocomplete="off" >
        <span class="help-block">{{err('password')?.msg|translate:err('password')?.param}}</span>
        <div *ngIf="error" class="error">
          <div>{{ 'LABEL.LOGIN.ERROR'|translate }}</div>
        </div>
      </div>

      <div class="form-group">
        <!--
        <div class="checkbox">
          <input
            formControlName="remember" id="remember" 
            type="checkbox">
          <label for="remembet">{{ 'LABEL.LOGIN.REMEMBERME'|translate }}</label>
        </div>
        -->
        <div>
          <button class="btn btn-block btn-primary" (click)="login()" [disabled]="loginForm.invalid">{{ 'LABEL.LOGIN.LOGIN'|translate }}</button>
        </div>
      </div>

      <a *ngIf="globals.config.login.google" (click)="loginOidc('google')" class="btn btn-block btn-social btn-google">
        <i class="fab fa-google"></i> {{ 'LABEL.LOGIN.SOCIAL'|translate }} Google
      </a>

      <a *ngIf="globals.config.login.okta" (click)="loginOidc('okta')"class="btn btn-block btn-social btn-github">
        <i class="fa fa-code"></i> {{ 'LABEL.LOGIN.SOCIAL'|translate }} Okta
      </a>

      <a *ngIf="globals.config.login.auth0" (click)="loginOidc('auth0')"class="btn btn-block btn-social btn-warning">
        <i class="fa fa-shield-alt"></i> {{ 'LABEL.LOGIN.SOCIAL'|translate }} Auth0
      </a>

    </form>

    <p></p>

    <!--
    <a href="#">{{ 'LABEL.LOGIN.FORGOT'|translate }}</a><br>
    <a href="#" class="text-center">{{ 'LABEL.LOGIN.REGISTER'|translate }}</a>
    -->

  </div>

</div>