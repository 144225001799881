import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap';

import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';
import { SessionService } from 'app/shared/services/session.service';
import { Page } from 'app/shared/model/page';

import { PrincipalService } from 'app/crud/system/principal/principal.service';
import { Principal } from 'app/crud/system/principal/principal';

import { GroupService } from 'app/crud/system/group/group.service';
import { Group } from 'app/crud/system/group/group';
import { RoleService } from 'app/crud/system/role/role.service';
import { Role } from 'app/crud/system/role/role';

@Component({
  selector: 'app-principal-table',
  templateUrl: './principal-table.component.html',
})
export class PrincipalTableComponent implements OnInit {

  // Select fields
  groupoptions: Group[] = [];
  roleoptions: Role[] = [];
  provideroptions: string[] = ['INTERNAL', 'GOOGLE', 'OKTA', 'AUTH0'];

  // Defalt form mode
  mode = 'edit';

  // Search fields
  search = {
    id: '',
    name: '',
    role_id: '',
    group_id: '',
    group_name: '',
    provider: '',
  };

  // Current page
  page = 0;

  // Sort field and direction
  sort = 'id';
  dir = '+';

  // Current filter
  filter = '';

  // One page of data
  data: Page<Principal>;

  // Loading indicator
  isLoading = true;

  // Event debouncer
  debounce: any;

  constructor(
    private apiGroup: GroupService,
    private apiRole: RoleService,
    private api: PrincipalService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private session: SessionService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
      if (this.session.search.form == 'Principal') {
        this.search = this.session.search.data;
        this.sort = this.session.search.sort;
        this.dir = this.session.search.dir;
        this.setFilter();  
      }
    }

  ngOnInit() {
    // Mode, if sub-table
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Load group lookup
    this.loadGroup();

    // Load role lookup
    this.loadRole();

    // Loads the table
    this.load();
  }

  // Loads the table
  load() {
    this.isLoading = true;
    this.api.read(this.page, this.dir + this.sort, this.filter).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('read', 'PRINCIPAL', err);
      }
    );
  }

  isSorted(field: string) {
    if (field === this.sort && this.dir === '-') {
      return 'sorting_desc';
    } else if (field === this.sort && this.dir === '+') {
      return 'sorting_asc';
    }
    return 'sorting';
  }

  doPage(page: number) {
    // Change page
    this.page = page;
    this.load();
  }

  doSort(field: string) {
    // Not sorted? Sort asc
    if (this.sort !== field) {
      if (field === 'createdon' || field === 'updatedon')
      	this.sort = 'audit.' + field;
      else
      	this.sort = field;
      this.dir = '+';
    } else {
      // Sorted asc? Sort desc
      if (this.dir === '+') {
        this.dir = '-';
      } else {
        // Else do not sort
        this.sort = '';
        this.dir = '';
      }
    }

    // Save sort
    this.session.search.form = 'Principal';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;

    // Reload
    this.load();
  }

  setFilter() {
    // Filter string
    const f: string[] = [];
    Globals.pushFilter(f, 'id', this.search.id, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'name', this.search.name, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'role.id', this.search.role_id, 'contains', this.session.lang.code, 'Long');
    Globals.pushFilter(f, 'group.id', this.search.group_id, 'eq', this.session.lang.code, 'Long');
    Globals.pushFilter(f, 'group.name', this.search.group_name, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'provider', this.search.provider, 'eq', this.session.lang.code, 'Provider');
    this.filter = Globals.encode(f.join(','));

    // Save filter
    this.session.search.form = 'Principal';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;
  }

  doFilter(event: any) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.setFilter();
      this.page = 0;
      this.load();
    }, 400);
  }

  // Clear filter
  clearFilter() {
    // Clear filter string
    this.search.id = '';
    this.search.name = '';
    this.search.role_id = '';
    this.search.group_id = '';
    this.search.group_name = '';
    this.search.provider = '';

    // Clear
    this.session.search.data = this.search;

    // Search
    this.page = 0;
    this.filter = '';
    this.load();
  }

  // Load group lookup
  loadGroup() {
    this.apiGroup.readAll('').subscribe(
      res => {
        this.groupoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'GROUP', err);
      }
    );
  }

  // Load role lookup
  loadRole() {
    this.apiRole.readAll('').subscribe(
      res => {
        this.roleoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'ROLE', err);
      }
    );
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
