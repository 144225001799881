import { Component, OnInit } from '@angular/core';
import { Globals } from 'app/shared/services/globals.service';

@Component({
  selector: 'app-sidebar-settings',
  templateUrl: './sidebar-settings.component.html'
})
export class SidebarSettingsComponent implements OnInit {

  tabs = [
    {
      name: 'home',
      icon: 'fa-home'
    },
    {
      name: 'stats',
      icon: 'fa-bar-chart'
    },
    {
      name: 'settings',
      icon: 'fa-gears'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
