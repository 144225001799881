import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'app/shared/services/auth.service';

@Component({
  template: 'logout'
})
export class LogoutComponent implements OnInit {

  constructor(
    private router: Router,
    private auth: AuthService,
  ) { }

  // Logout
  ngOnInit() {
    this.auth.doLogout();
    this.router.navigate(['/']);
  }

}
