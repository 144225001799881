import { Home } from 'app/crud/houses/home/home';
import { Audit } from 'app/shared/model/audit';

// FloorPlan class
export class FloorPlan {

  // Key
  id: number;

  // Fields
  home: Home; // = new Home();
  position: number;
  floor: string;
  model: string;
  before: any;
  beforetype: string;
  beforename: string;
  beforebytes: any;
  after: any;
  aftertype: string;
  aftername: string;
  afterbytes: any;

  // Audit
  audit : Audit = new Audit();
}

