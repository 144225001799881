// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Province } from 'app/crud/valuator/province/province';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class ProvinceService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all provinces
  readAll(sort: string): Observable<Page<Province>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of provinces
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Province>> {
    let url = `${this.globals.server}provinces?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Province>>(url).pipe(
      // tap(_ => console.log('read provinces')),
      catchError(this.handleError<Page<Province>>('read Province'))
    );
  }

  // Read one province
  readOne(id: string): Observable<Province> {
    return this.http.get<Province>(`${this.globals.server}provinces/${id}`).pipe(
      // tap(_ => console.log(`readOne province ${id} `)),
      catchError(this.handleError<Province>(`readOne Province ${id} `))
    );
  }

  // Count provinces
  count(): Observable<Province[]> {
    return this.http.get<Province[]>(`${this.globals.server}provinces/count`).pipe(
      // tap(_ => console.log('count provinces')),
      catchError(this.handleError('count Province', []))
    );
  }

  // Create province
  create(o: Province): Observable<Province> {
    return this.http.post<Province>(`${this.globals.server}provinces`, o, httpOptions).pipe(
      // tap(_ => console.log('create province')),
      catchError(this.handleError<Province>('create Province'))
    );
  }

  // Update province
  update(id: string, o: Province): Observable<any> {
    return this.http.put(`${this.globals.server}provinces/${id}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update province ${id} `)),
      catchError(this.handleError<any>(`update Province ${id} `))
    );
  }

  // Delete province
  delete(id: string): Observable<Province> {
    return this.http.delete<Province>(`${this.globals.server}provinces/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete province ${id} `)),
      catchError(this.handleError<Province>(`delete Province ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
