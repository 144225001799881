// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Home } from 'app/crud/houses/home/home';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class HomeService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Query housesbycity
  housesByCity(): Observable<any> {
    const url = `${this.globals.server}homes/q/housesbycity`;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError<any>('query housesByCity'))
    );
  }

  // Query housesbycompany
  housesByCompany(): Observable<any> {
    const url = `${this.globals.server}homes/q/housesbycompany`;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError<any>('query housesByCompany'))
    );
  }

  // Read all homes
  readAll(sort: string): Observable<Page<Home>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of homes
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Home>> {
    let url = `${this.globals.server}homes?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Home>>(url).pipe(
      // tap(_ => console.log('read homes')),
      catchError(this.handleError<Page<Home>>('read Home'))
    );
  }

  // Read one home
  readOne(code: string): Observable<Home> {
    return this.http.get<Home>(`${this.globals.server}homes/${code}`).pipe(
      // tap(_ => console.log(`readOne home ${code} `)),
      catchError(this.handleError<Home>(`readOne Home ${code} `))
    );
  }

  // Count homes
  count(): Observable<Home[]> {
    return this.http.get<Home[]>(`${this.globals.server}homes/count`).pipe(
      // tap(_ => console.log('count homes')),
      catchError(this.handleError('count Home', []))
    );
  }

  // Create home
  create(o: Home): Observable<Home> {
    const input = new FormData();
    if (o.image) { input.append('image', o.image, o.imagename); }
    delete o.image;
    input.append('data', JSON.stringify(o));
    return this.http.post<Home>(`${this.globals.server}homes`, input).pipe(
      // tap(_ => console.log('create home')),
      catchError(this.handleError<Home>('create Home'))
    );
  }

  // Update home
  update(code: string, o: Home): Observable<any> {
    const input = new FormData();
    if (o.image) { input.append('image', o.image, o.imagename); }
    delete o.image;
    input.append('data', JSON.stringify(o));
    return this.http.put(`${this.globals.server}homes/${code}`, input).pipe(
      // tap(_ => console.log(`update home ${code} `)),
      catchError(this.handleError<any>(`update Home ${code} `))
    );
  }

  // Delete home
  delete(code: string): Observable<Home> {
    return this.http.delete<Home>(`${this.globals.server}homes/${code}`, httpOptions).pipe(
      // tap(_ => console.log(`delete home ${code} `)),
      catchError(this.handleError<Home>(`delete Home ${code} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
