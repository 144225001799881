import { Source } from 'app/crud/customers/source/source';
import { Audit } from 'app/shared/model/audit';

// Contact class
export class Contact {

  // Key
  id: number;

  // Fields
  crmid: string;
  email: string;
  name: string;
  surname: string;
  phone: string;
  mobile: string;
  address: string;
  zip: string;
  city: string;
  comments: string;
  rgpd: string;
  confirm: string;
  source: Source; // = new Source();

  // Audit
  audit : Audit = new Audit();
}

