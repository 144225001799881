<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.ROLE'|translate" [optional]="'LABEL.ENTITY.ROLE.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="roleForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.ROLE'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('name')">
              <label class="control-label" for="name">
                {{ 'LABEL.FIELD.ROLE.NAME'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <input
                formControlName="name"
                maxlength="120"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.ROLE.NAME':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('name')?.msg|translate:err('name')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="roleForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:ROLE')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="roleForm.invalid || roleForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:ROLE')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:ROLE')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>
        <!-- Tabbed panel-->
        <div class="nav-tabs-custom">

          <!-- Tabs labels -->
          <ul class="nav nav-tabs">
            <li class="active">
              <a href="#tabPrivilege" data-toggle="tab">{{ 'LABEL.ENTITY.PRIVILEGE'|translate }}</a>
            </li>
          </ul>

            <!-- Tabs content -->
            <div class="tab-content">

            <!-- Tab -->
            <div class="active tab-pane" id="tabPrivilege">
              <div class="box-body">
              <app-privilege-role-table *ngIf="mode!='add' && data.id" 
                parent="roles" 
                [value]="data.id">
              </app-privilege-role-table>
              </div>
            </div> <!-- end tab -->
    
            </div> <!-- end tab-content -->

        </div>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
