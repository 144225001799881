// tslint:disable: no-string-literal
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { AuthService } from 'app/shared/services/auth.service';
import { Globals } from 'app/shared/services/globals.service';
import { SessionService } from 'app/shared/services/session.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  // Form
  loginForm = this.fb.group({
    user: [{ value: '', disabled: false }, [Validators.required]],
    password: [{ value: '', disabled: false }, [Validators.required]],
    remember: [{ value: false, disabled: false }, []]
  });

  // Loading indicator
  isLoading = false;

  // Auth code
  code = '';

  // Return URL
  returnUrl: string;

  // Error indicator
  error = '';

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    public globals: Globals
  ) {}

  ngOnInit() {
    // Get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    // Logout
    this.auth.doLogout();
  }

  login() {
    // Form invalid?
    if (this.loginForm.invalid) {
      return;
    }

    // Call login service
    this.isLoading = true;
    this.auth.doLogin(this.loginForm.controls.user.value, this.loginForm.controls.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.isLoading = false;
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.isLoading = false;
          this.error = error;
          console.log(error);
        }
      );
  }

  loginOidc(type) {
    this.auth.doLoginOidc(type, this.returnUrl);
  }

  // Field error status and message
  err(name: string) {
    return Globals.err(this.loginForm, name);
  }

}
