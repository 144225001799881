// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Note } from 'app/crud/customers/note/note';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class NoteService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all notes
  readAll(sort: string): Observable<Page<Note>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of notes
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Note>> {
    let url = `${this.globals.server}notes?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Note>>(url).pipe(
      // tap(_ => console.log('read notes')),
      catchError(this.handleError<Page<Note>>('read Note'))
    );
  }

  // Read pages of notes from contacts, contacts cannot have composite keys
  readFromContact(contact: number, page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Note>> {
    let url = `${this.globals.server}contacts/${contact}/notes?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Note>>(url).pipe(
      // tap(_ => console.log('read notes')),
      catchError(this.handleError<Page<Note>>('read Note'))
    );
  }

  // Read one note
  readOne(id: number): Observable<Note> {
    return this.http.get<Note>(`${this.globals.server}notes/${id}`).pipe(
      // tap(_ => console.log(`readOne note ${id} `)),
      catchError(this.handleError<Note>(`readOne Note ${id} `))
    );
  }

  // Count notes
  count(): Observable<Note[]> {
    return this.http.get<Note[]>(`${this.globals.server}notes/count`).pipe(
      // tap(_ => console.log('count notes')),
      catchError(this.handleError('count Note', []))
    );
  }

  // Create note
  create(o: Note): Observable<Note> {
    return this.http.post<Note>(`${this.globals.server}notes`, o, httpOptions).pipe(
      // tap(_ => console.log('create note')),
      catchError(this.handleError<Note>('create Note'))
    );
  }

  // Update note
  update(id: number, o: Note): Observable<any> {
    return this.http.put(`${this.globals.server}notes/${id}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update note ${id} `)),
      catchError(this.handleError<any>(`update Note ${id} `))
    );
  }

  // Delete note
  delete(id: number): Observable<Note> {
    return this.http.delete<Note>(`${this.globals.server}notes/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete note ${id} `)),
      catchError(this.handleError<Note>(`delete Note ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
