import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Validators, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap';
import * as moment from 'moment';

import { HouseService } from 'app/crud/valuator/house/house.service';
import { House } from 'app/crud/valuator/house/house';

import { TownService } from 'app/crud/valuator/town/town.service';
import { Town } from 'app/crud/valuator/town/town';

import { ConfirmDialogService } from 'app/layout/modal/confirm-dialog.service';
import { SessionService } from 'app/shared/services/session.service';
import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-house-form',
  templateUrl: './house-form.component.html',
})
export class HouseFormComponent implements OnInit {

  // Defalt form mode
  mode = 'add';

  // Select fields
  townoptions: Observable<Town[]>;

  // Form
  houseForm = this.fb.group({
    // Key
    id: ['', [Validators.minLength(0), Validators.maxLength(50), ]],
    // Fields
    batch: [{ value: '', disabled: false }, [this.dateValidator('L LTS'), ]],
    source: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(50), ]],
    typology: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(50), ]],
    location: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(50), ]],
    section: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(50), ]],
    address: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(200), ]],
    town: [{ value: '', disabled: false }, []],
    floor: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(50), ]],
    lat: [{ value: '', disabled: false }, []],
    lon: [{ value: '', disabled: false }, []],
    price: [{ value: '', disabled: false }, []],
    oldprice: [{ value: '', disabled: false }, []],
    intext: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(50), ]],
    lift: [{ value: '', disabled: false }, []],
    area: [{ value: '', disabled: false }, []],
    rooms: [{ value: '', disabled: false }, []],
    baths: [{ value: '', disabled: false }, []],
    terrace: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(100), ]],
    parking: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(100), ]],
    pool: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(100), ]],
    garden: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(100), ]],
    condition: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(100), ]],
    status: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(100), ]],
    comments: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(4000), ]],
    features: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(4000), ]],
    ownertype: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(10), ]],
    ownername: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(100), ]],
    photo: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(300), ]],
    createdon: [{ value: '', disabled: true }],
    updatedon: [{ value: '', disabled: true }],
  });

  // Working record
  data: House = new House();

  // Loading indicators
  isLoading = false;

  // Components constructor
  constructor(
    private apiTown: TownService,
    private api: HouseService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private location: Location,
    private confirm: ConfirmDialogService,
    private session: SessionService,
    private auth: AuthService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
  }

  // Component initialization
  ngOnInit() {
    // Init data
    this.data = new House();
    this.data.town = new Town();

    // Load town lookup
    this.loadTownAsync();

    // Get form mode
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Disable fields in view mode
    if (this.mode === 'view') {
      Object.keys(this.houseForm.controls).forEach(key => {
        this.houseForm.get(key).disable();
      });
    }

    // Disable key fields in edit mode
    if (this.mode === 'edit') {
      this.houseForm.get('id').disable();
    }

    // Loads the record
    if (this.mode !== 'add') {
      this.load(
        this.route.snapshot.params.id
      );
    }
  }

  mapDataToForm(data: House, form: FormGroup) {
    form.get('id').setValue(data.id);
    this.setDateTime(form.get('batch'), data.batch, 'L LTS');
    form.get('source').setValue(data.source);
    form.get('typology').setValue(data.typology);
    form.get('location').setValue(data.location);
    form.get('section').setValue(data.section);
    form.get('address').setValue(data.address);
    if (data.town) { form.get('town').setValue(data.town.name); } else { data.town = new Town(); }
    form.get('floor').setValue(data.floor);
    form.get('lat').setValue(data.lat);
    form.get('lon').setValue(data.lon);
    form.get('price').setValue(data.price);
    form.get('oldprice').setValue(data.oldprice);
    form.get('intext').setValue(data.intext);
    form.get('lift').setValue(data.lift);
    form.get('area').setValue(data.area);
    form.get('rooms').setValue(data.rooms);
    form.get('baths').setValue(data.baths);
    form.get('terrace').setValue(data.terrace);
    form.get('parking').setValue(data.parking);
    form.get('pool').setValue(data.pool);
    form.get('garden').setValue(data.garden);
    form.get('condition').setValue(data.condition);
    form.get('status').setValue(data.status);
    form.get('comments').setValue(data.comments);
    form.get('features').setValue(data.features);
    form.get('ownertype').setValue(data.ownertype);
    form.get('ownername').setValue(data.ownername);
    form.get('photo').setValue(data.photo);
  }

  mapFormToData(form: FormGroup, data: House) {
    data.id = form.get('id').value;
    data.batch = this.getDateTime(form.get('batch'), data.batch, 'L LTS');
    data.source = form.get('source').value;
    data.typology = form.get('typology').value;
    data.location = form.get('location').value;
    data.section = form.get('section').value;
    data.address = form.get('address').value;
    if (!form.get('town').value) { data.town = null; }
    data.floor = form.get('floor').value;
    data.lat = form.get('lat').value;
    data.lon = form.get('lon').value;
    data.price = form.get('price').value;
    data.oldprice = form.get('oldprice').value;
    data.intext = form.get('intext').value;
    data.lift = form.get('lift').value;
    data.area = form.get('area').value;
    data.rooms = form.get('rooms').value;
    data.baths = form.get('baths').value;
    data.terrace = form.get('terrace').value;
    data.parking = form.get('parking').value;
    data.pool = form.get('pool').value;
    data.garden = form.get('garden').value;
    data.condition = form.get('condition').value;
    data.status = form.get('status').value;
    data.comments = form.get('comments').value;
    data.features = form.get('features').value;
    data.ownertype = form.get('ownertype').value;
    data.ownername = form.get('ownername').value;
    data.photo = form.get('photo').value;
  }

  // Load selected record
  load(id) {
    this.isLoading = true;
    this.api.readOne(id).subscribe(
      res => {
        this.data = res;
        this.mapDataToForm(this.data, this.houseForm);
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('view', 'HOUSE', err);
      });
  }

  // Create a new record
  createData() {
    this.isLoading = true;
    this.mapFormToData(this.houseForm, this.data);
    this.api.create(this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('add', 'HOUSE');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('add', 'HOUSE', err);
      });
  }

  // Update record
  updateData() {
    this.isLoading = true;
    this.mapFormToData(this.houseForm, this.data);
    this.api.update(this.data.id, this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('update', 'HOUSE');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('update', 'HOUSE', err);
      });
  }

  // Delete record
  deleteData() {
    const title = this.translate.instant('MESSAGE.DELETE') + ' ' + this.translate.instant('LABEL.ENTITY.HOUSE');
    const text  = this.translate.instant('MESSAGE.SURE');
    this.confirm.confirmThis(title, text, () => {}, () => {
      this.isLoading = true;
      this.api.delete(this.data.id).subscribe(
        res => {
          this.isLoading = false;
          this.globals.crudOk('delete', 'HOUSE');
          this.back();
        },
        err => {
          this.isLoading = false;
          this.globals.crudKo('delete', 'HOUSE', err);
        });
    });
  }

  // Return to list page
  back() {
    this.location.back();
  }

  // Field error status and  message
  err(name: string) {
    return Globals.err(this.houseForm, name);
  }

  // Load town lookup async
  loadTownAsync() {
    this.townoptions = this.apiTown
      .read(0, '+name', '', 20)
      .pipe(map(data => data.content));
    this.townoptions = Observable.create((observer: any) => {
      observer.next(this.houseForm.get('town').value);
    })
    .pipe(
      mergeMap((value: string) => {
        return this.apiTown
          .read(0, '+name', 'name:like:' + value, 20)
          .pipe(map(data => data.content));
      })
    );
  }

  // Lookup selected
  townOnSelected(event) {
    this.houseForm.markAsDirty();
    this.data.town.id = event.item.id;
  }

  dateValidator(format: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (control.value === null || control.value === '') return null;
      moment.locale(this.session.lang.code);
      const date = moment.utc(control.value, format);
      return date.isValid() ? null : { invaliddate: true };
    };
  }

  getDateTime(control: AbstractControl, date: any, format: string) {
    if (control.value === null || control.value === '') return null;
    // Ñapa
    const aux = control.value;
    if (typeof aux != 'string')
      aux.setHours(aux.getHours() - aux.getTimezoneOffset() / 60);
    // Ñapa
    moment.locale(this.session.lang.code);
    const d = moment.utc(aux, format);
    if (d.isValid()) {
      return d.toISOString();
    } else {
      return date;
    }
  }

  setDateTime(control: AbstractControl, date: any, format: string) {
    moment.locale(this.session.lang.code);
    const d = moment.utc(date, 'YYYY-MM-DD');
    if (d.isValid()) {
      control.setValue(d.format(format));
    }
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
