import { Town } from 'app/crud/valuator/town/town';
import { Audit } from 'app/shared/model/audit';

// House class
export class House {

  // Key
  id: string;

  // Fields
  batch: string;
  source: string;
  typology: string;
  location: string;
  section: string;
  address: string;
  town: Town; // = new Town();
  floor: string;
  lat: number;
  lon: number;
  price: number;
  oldprice: number;
  intext: string;
  lift: number;
  area: number;
  rooms: number;
  baths: number;
  terrace: string;
  parking: string;
  pool: string;
  garden: string;
  condition: string;
  status: string;
  comments: string;
  features: string;
  ownertype: string;
  ownername: string;
  photo: string;

  // Audit
  audit : Audit = new Audit();
}

