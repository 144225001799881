import { Audit } from 'app/shared/model/audit';

// Config class
export class Config {

  // Key
  code: string;

  // Fields
  content: string;

  // Audit
  audit : Audit = new Audit();
}

