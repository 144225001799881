<div *ngIf="globals.config" class="wrapper">

  <app-header></app-header>
  <app-sidebar></app-sidebar>

  <div>
  <router-outlet (activate)='fix()'></router-outlet>
  </div>

  <app-footer *ngIf="globals.config.elements.footer"></app-footer>
  <app-sidebar-settings *ngIf="globals.config.elements.header.settings"></app-sidebar-settings>

</div>