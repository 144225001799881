<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.SHOP'|translate" [optional]="'LABEL.ENTITY.SHOP.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="shopForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.SHOP'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('name')">
              <label class="control-label" for="name">
                {{ 'LABEL.FIELD.SHOP.NAME'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <input
                formControlName="name"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SHOP.NAME':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('name')?.msg|translate:err('name')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (2) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('web')">
              <label class="control-label" for="web">
                {{ 'LABEL.FIELD.SHOP.WEB'|translate }}
              </label>
              <input
                formControlName="web"
                maxlength="250"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SHOP.WEB':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('web')?.msg|translate:err('web')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (3) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('logo')">
              <label class="control-label" for="logo">
                {{ 'LABEL.FIELD.SHOP.LOGO'|translate }}
              </label>
              &nbsp;<i (click)="onFileDelete('logo', $event)" class="fa fa-trash"></i>
              <input *ngIf="mode!='view'"
                (change)="onFileChange('logo', $event)"
                type="file"
                class="form-control" autocomplete="off" style="margin-bottom:10px;">
              <div *ngIf="mode!='add' && !data?.logoFile">
                <div *ngIf="data?.logotype?.startsWith('image/')" style="margin-bottom:10px;">
                  <!--<img width="120px" src="data:{{data.logotype}};base64,{{data.logoBytes}}">-->
                  <img [hidden]="!logoLoaded" (load)="logoLoaded=true" width="120px" [src]="globals.server + 'shops/' + data.id + '/logo' | secure | async">
                </div>
                <!--<a *ngIf="data?.logotype" href="{{ globals.server }}shops/{{ data.id }}/logo" type="{{ data.logotype }}">-->
                <a *ngIf="data?.logotype" target="_blank" [href]="globals.server + 'shops/' + data.id + '/logo' | secure | async" type="{{ data.logotype }}">
                  <i [class]="'fa fa-lg ' + mimeIcon(data?.logotype)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
                </a>
              </div>
              <span class="help-block">{{err('logo')?.msg|translate:err('logo')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (4) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('details')">
              <label class="control-label" for="details">
                {{ 'LABEL.FIELD.SHOP.DETAILS'|translate }}
              </label>
              <textarea
                formControlName="details"
                autogrow [maximumHeight]="300" [minimumHeight]="60"
                (keydown.enter)="$event.stopPropagation();"
                maxlength="2000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SHOP.DETAILS':'')|translate }}"
                class="form-control" autocomplete="off">
              </textarea>
              <span class="help-block">{{err('details')?.msg|translate:err('details')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="shopForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:SHOP')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="shopForm.invalid || shopForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:SHOP')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:SHOP')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
