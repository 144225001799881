import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap';

import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';
import { SessionService } from 'app/shared/services/session.service';
import { Page } from 'app/shared/model/page';

import { HouseService } from 'app/crud/valuator/house/house.service';
import { House } from 'app/crud/valuator/house/house';

import { TownService } from 'app/crud/valuator/town/town.service';
import { Town } from 'app/crud/valuator/town/town';

@Component({
  selector: 'app-house-table',
  templateUrl: './house-table.component.html',
})
export class HouseTableComponent implements OnInit {

  // Select fields
  townoptions: Town[] = [];

  // Defalt form mode
  mode = 'edit';

  // Search fields
  search = {
    id: '',
    batch: '',
    source: '',
    typology: '',
    location: '',
    section: '',
    address: '',
    price: '',
    intext: '',
    area: '',
  };

  // Current page
  page = 0;

  // Sort field and direction
  sort = 'id';
  dir = '+';

  // Current filter
  filter = '';

  // One page of data
  data: Page<House>;

  // Loading indicator
  isLoading = true;

  // Event debouncer
  debounce: any;

  constructor(
    private apiTown: TownService,
    private api: HouseService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private session: SessionService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
      if (this.session.search.form == 'House') {
        this.search = this.session.search.data;
        this.sort = this.session.search.sort;
        this.dir = this.session.search.dir;
        this.setFilter();  
      }
    }

  ngOnInit() {
    // Mode, if sub-table
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Loads the table
    this.load();
  }

  // Loads the table
  load() {
    this.isLoading = true;
    this.api.read(this.page, this.dir + this.sort, this.filter).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('read', 'HOUSE', err);
      }
    );
  }

  isSorted(field: string) {
    if (field === this.sort && this.dir === '-') {
      return 'sorting_desc';
    } else if (field === this.sort && this.dir === '+') {
      return 'sorting_asc';
    }
    return 'sorting';
  }

  doPage(page: number) {
    // Change page
    this.page = page;
    this.load();
  }

  doSort(field: string) {
    // Not sorted? Sort asc
    if (this.sort !== field) {
      if (field === 'createdon' || field === 'updatedon')
      	this.sort = 'audit.' + field;
      else
      	this.sort = field;
      this.dir = '+';
    } else {
      // Sorted asc? Sort desc
      if (this.dir === '+') {
        this.dir = '-';
      } else {
        // Else do not sort
        this.sort = '';
        this.dir = '';
      }
    }

    // Save sort
    this.session.search.form = 'House';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;

    // Reload
    this.load();
  }

  setFilter() {
    // Filter string
    const f: string[] = [];
    Globals.pushFilter(f, 'id', this.search.id, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'batch', this.search.batch, 'eq', this.session.lang.code, 'Date');
    Globals.pushFilter(f, 'source', this.search.source, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'typology', this.search.typology, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'location', this.search.location, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'section', this.search.section, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'address', this.search.address, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'price', this.search.price, 'eq', this.session.lang.code, 'Double');
    Globals.pushFilter(f, 'intext', this.search.intext, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'area', this.search.area, 'eq', this.session.lang.code, 'Long');
    this.filter = Globals.encode(f.join(','));

    // Save filter
    this.session.search.form = 'House';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;
  }

  doFilter(event: any) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.setFilter();
      this.page = 0;
      this.load();
    }, 400);
  }

  // Clear filter
  clearFilter() {
    // Clear filter string
    this.search.id = '';
    this.search.batch = '';
    this.search.source = '';
    this.search.typology = '';
    this.search.location = '';
    this.search.section = '';
    this.search.address = '';
    this.search.price = '';
    this.search.intext = '';
    this.search.area = '';

    // Clear
    this.session.search.data = this.search;

    // Search
    this.page = 0;
    this.filter = '';
    this.load();
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
