import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Validators, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap';
import * as moment from 'moment';

import { HomeService } from 'app/crud/houses/home/home.service';
import { Home } from 'app/crud/houses/home/home';

import { CityService } from 'app/crud/houses/city/city.service';
import { City } from 'app/crud/houses/city/city';
import { CompanyService } from 'app/crud/houses/company/company.service';
import { Company } from 'app/crud/houses/company/company';
import { HomeTypeService } from 'app/crud/houses/hometype/hometype.service';
import { HomeType } from 'app/crud/houses/hometype/hometype';

import { ConfirmDialogService } from 'app/layout/modal/confirm-dialog.service';
import { SessionService } from 'app/shared/services/session.service';
import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-home-form',
  templateUrl: './home-form.component.html',
})
export class HomeFormComponent implements OnInit {

  // Defalt form mode
  mode = 'add';

  // Select fields
  companyoptions: Company[] = [];
  cityoptions: City[] = [];
  hometypeoptions: HomeType[] = [];

  // Form
  homeForm = this.fb.group({
    // Key
    code: ['XXX000', [Validators.required, Validators.minLength(6), Validators.maxLength(10), ]],
    // Fields
    active: [{ value: true, disabled: false }],
    web: [{ value: false, disabled: false }],
    sold: [{ value: false, disabled: false }],
    company: [{ value: '', disabled: false }, [Validators.required, ]],
    city: [{ value: '', disabled: false }, [Validators.required, ]],
    hometype: [{ value: '', disabled: false }, [Validators.required, ]],
    address: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1), Validators.maxLength(100), ]],
    refcat: [{ value: '', disabled: false }, [Validators.minLength(1), Validators.maxLength(20), ]],
    aproxaddress: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1), Validators.maxLength(100), ]],
    num: [{ value: '', disabled: false }, [Validators.minLength(1), Validators.maxLength(10), ]],
    sellprice: [{ value: '', disabled: false }, []],
    renoprice: [{ value: '', disabled: false }, []],
    addprice: [{ value: true, disabled: false }],
    description: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1), Validators.maxLength(2000), ]],
    details: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1), Validators.maxLength(5000), ]],
    squaremeters: [{ value: '', disabled: false }, [Validators.required, ]],
    bedrooms: [{ value: '', disabled: false }, [Validators.required, ]],
    bathrooms: [{ value: '', disabled: false }, [Validators.required, ]],
    garage: [{ value: '', disabled: false }, [Validators.required, ]],
    features: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(1000), ]],
    equipment: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(1000), ]],
    building: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(1000), ]],
    pricing: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(1000), ]],
    lat: [{ value: '', disabled: false }, [Validators.required, ]],
    lng: [{ value: '', disabled: false }, [Validators.required, ]],
    image: [{ value: '', disabled: false }, []],
    createdon: [{ value: '', disabled: true }],
    updatedon: [{ value: '', disabled: true }],
  });

  // Working record
  data: Home = new Home();

  // Loading indicators
  isLoading = false;
  imageLoaded = false;

  // Components constructor
  constructor(
    private apiCity: CityService,
    private apiCompany: CompanyService,
    private apiHomeType: HomeTypeService,
    private api: HomeService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private location: Location,
    private confirm: ConfirmDialogService,
    private session: SessionService,
    private auth: AuthService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
  }

  // Component initialization
  ngOnInit() {
    // Init data
    this.data = new Home();
    this.data.company = new Company();
    this.data.city = new City();
    this.data.hometype = new HomeType();

    // Load company lookup
    this.loadCompany();

    // Load city lookup
    this.loadCity();

    // Load hometype lookup
    this.loadHomeType();

    // Get form mode
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Disable fields in view mode
    if (this.mode === 'view') {
      Object.keys(this.homeForm.controls).forEach(key => {
        this.homeForm.get(key).disable();
      });
    }

    // Disable key fields in edit mode
    if (this.mode === 'edit') {
      this.homeForm.get('code').disable();
    }

    // Loads the record
    if (this.mode !== 'add') {
      this.load(
        this.route.snapshot.params.code
      );
    }
  }

  mapDataToForm(data: Home, form: FormGroup) {
    form.get('code').setValue(data.code);
    form.get('active').setValue(data.active);
    form.get('web').setValue(data.web);
    form.get('sold').setValue(data.sold);
    if (data.company) { form.get('company').setValue(data.company.id); } else { data.company = new Company(); }
    if (data.city) { form.get('city').setValue(data.city.id); } else { data.city = new City(); }
    if (data.hometype) { form.get('hometype').setValue(data.hometype.id); } else { data.hometype = new HomeType(); }
    form.get('address').setValue(data.address);
    form.get('refcat').setValue(data.refcat);
    form.get('aproxaddress').setValue(data.aproxaddress);
    form.get('num').setValue(data.num);
    form.get('sellprice').setValue(data.sellprice);
    form.get('renoprice').setValue(data.renoprice);
    form.get('addprice').setValue(data.addprice);
    form.get('description').setValue(data.description);
    form.get('details').setValue(data.details);
    form.get('squaremeters').setValue(data.squaremeters);
    form.get('bedrooms').setValue(data.bedrooms);
    form.get('bathrooms').setValue(data.bathrooms);
    form.get('garage').setValue(data.garage);
    form.get('features').setValue(data.features);
    form.get('equipment').setValue(data.equipment);
    form.get('building').setValue(data.building);
    form.get('pricing').setValue(data.pricing);
    form.get('lat').setValue(data.lat);
    form.get('lng').setValue(data.lng);
  }

  mapFormToData(form: FormGroup, data: Home) {
    data.code = form.get('code').value;
    data.active = form.get('active').value;
    data.web = form.get('web').value;
    data.sold = form.get('sold').value;
    if (!form.get('company').value) { data.company = null; } else { data.company.id = form.get('company').value; }
    if (!form.get('city').value) { data.city = null; } else { data.city.id = form.get('city').value; }
    if (!form.get('hometype').value) { data.hometype = null; } else { data.hometype.id = form.get('hometype').value; }
    data.address = form.get('address').value;
    data.refcat = form.get('refcat').value;
    data.aproxaddress = form.get('aproxaddress').value;
    data.num = form.get('num').value;
    data.sellprice = form.get('sellprice').value;
    data.renoprice = form.get('renoprice').value;
    data.addprice = form.get('addprice').value;
    data.description = form.get('description').value;
    data.details = form.get('details').value;
    data.squaremeters = form.get('squaremeters').value;
    data.bedrooms = form.get('bedrooms').value;
    data.bathrooms = form.get('bathrooms').value;
    data.garage = form.get('garage').value;
    data.features = form.get('features').value;
    data.equipment = form.get('equipment').value;
    data.building = form.get('building').value;
    data.pricing = form.get('pricing').value;
    data.lat = form.get('lat').value;
    data.lng = form.get('lng').value;
  }

  // Load selected record
  load(code) {
    this.isLoading = true;
    this.api.readOne(code).subscribe(
      res => {
        this.data = res;
        this.mapDataToForm(this.data, this.homeForm);
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('view', 'HOME', err);
      });
  }

  // Create a new record
  createData() {
    this.isLoading = true;
    this.mapFormToData(this.homeForm, this.data);
    this.api.create(this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('add', 'HOME');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('add', 'HOME', err);
      });
  }

  // Update record
  updateData() {
    this.isLoading = true;
    this.mapFormToData(this.homeForm, this.data);
    this.api.update(this.data.code, this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('update', 'HOME');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('update', 'HOME', err);
      });
  }

  // Delete record
  deleteData() {
    const title = this.translate.instant('MESSAGE.DELETE') + ' ' + this.translate.instant('LABEL.ENTITY.HOME');
    const text  = this.translate.instant('MESSAGE.SURE');
    this.confirm.confirmThis(title, text, () => {}, () => {
      this.isLoading = true;
      this.api.delete(this.data.code).subscribe(
        res => {
          this.isLoading = false;
          this.globals.crudOk('delete', 'HOME');
          this.back();
        },
        err => {
          this.isLoading = false;
          this.globals.crudKo('delete', 'HOME', err);
        });
    });
  }

  // Return to list page
  back() {
    this.location.back();
  }

  // Selected file
  onFileChange(field, event) {
    if (event.target.files && event.target.files.length) {
      this.data[field] = event.target.files[0];
      this.data[field + 'type'] = event.target.files[0].type;
      this.data[field + 'name'] = event.target.files[0].name;
      this.homeForm.markAsDirty();
    }
  }

  // Delete file
  onFileDelete(field, event) {
    this.data[field] = null;
    this.data[field + 'type'] = null;
    this.data[field + 'name'] = '{Delete}';
    this.homeForm.markAsDirty();
  }

  // Field error status and  message
  err(name: string) {
    return Globals.err(this.homeForm, name);
  }

  // Load company lookup
  loadCompany() {
    this.apiCompany.readAll('+name').subscribe(
      res => {
        this.companyoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load city lookup
  loadCity() {
    this.apiCity.readAll('+name').subscribe(
      res => {
        this.cityoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load hometype lookup
  loadHomeType() {
    this.apiHomeType.readAll('+name').subscribe(
      res => {
        this.hometypeoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  dateValidator(format: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (control.value === null || control.value === '') return null;
      moment.locale(this.session.lang.code);
      const date = moment.utc(control.value, format);
      return date.isValid() ? null : { invaliddate: true };
    };
  }

  getDateTime(control: AbstractControl, date: any, format: string) {
    if (control.value === null || control.value === '') return null;
    // Ñapa
    const aux = control.value;
    if (typeof aux != 'string')
      aux.setHours(aux.getHours() - aux.getTimezoneOffset() / 60);
    // Ñapa
    moment.locale(this.session.lang.code);
    const d = moment.utc(aux, format);
    if (d.isValid()) {
      return d.toISOString();
    } else {
      return date;
    }
  }

  setDateTime(control: AbstractControl, date: any, format: string) {
    moment.locale(this.session.lang.code);
    const d = moment.utc(date, 'YYYY-MM-DD');
    if (d.isValid()) {
      control.setValue(d.format(format));
    }
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
