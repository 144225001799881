import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap';

import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';
import { SessionService } from 'app/shared/services/session.service';
import { Page } from 'app/shared/model/page';

import { FurnitureService } from 'app/crud/decoration/furniture/furniture.service';
import { Furniture } from 'app/crud/decoration/furniture/furniture';

import { ShopService } from 'app/crud/decoration/shop/shop.service';
import { Shop } from 'app/crud/decoration/shop/shop';
import { CategoryService } from 'app/crud/decoration/category/category.service';
import { Category } from 'app/crud/decoration/category/category';

@Component({
  selector: 'app-furniture-table',
  templateUrl: './furniture-table.component.html',
})
export class FurnitureTableComponent implements OnInit {

  // Select fields
  shopoptions: Shop[] = [];
  categoryoptions: Category[] = [];

  // Defalt form mode
  mode = 'edit';

  // Search fields
  search = {
    name: '',
    ref: '',
    shop_id: '',
    shop_name: '',
    category_id: '',
    price: '',
    description: '',
  };

  // Current page
  page = 0;

  // Sort field and direction
  sort = 'name';
  dir = '+';

  // Current filter
  filter = '';

  // One page of data
  data: Page<Furniture>;

  // Loading indicator
  isLoading = true;

  // Event debouncer
  debounce: any;

  constructor(
    private apiShop: ShopService,
    private apiCategory: CategoryService,
    private api: FurnitureService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private session: SessionService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
      if (this.session.search.form == 'Furniture') {
        this.search = this.session.search.data;
        this.sort = this.session.search.sort;
        this.dir = this.session.search.dir;
        this.setFilter();  
      }
    }

  ngOnInit() {
    // Mode, if sub-table
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Load shop lookup
    this.loadShop();

    // Load category lookup
    this.loadCategory();

    // Loads the table
    this.load();
  }

  // Loads the table
  load() {
    this.isLoading = true;
    this.api.read(this.page, this.dir + this.sort, this.filter).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('read', 'FURNITURE', err);
      }
    );
  }

  isSorted(field: string) {
    if (field === this.sort && this.dir === '-') {
      return 'sorting_desc';
    } else if (field === this.sort && this.dir === '+') {
      return 'sorting_asc';
    }
    return 'sorting';
  }

  doPage(page: number) {
    // Change page
    this.page = page;
    this.load();
  }

  doSort(field: string) {
    // Not sorted? Sort asc
    if (this.sort !== field) {
      if (field === 'createdon' || field === 'updatedon')
      	this.sort = 'audit.' + field;
      else
      	this.sort = field;
      this.dir = '+';
    } else {
      // Sorted asc? Sort desc
      if (this.dir === '+') {
        this.dir = '-';
      } else {
        // Else do not sort
        this.sort = '';
        this.dir = '';
      }
    }

    // Save sort
    this.session.search.form = 'Furniture';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;

    // Reload
    this.load();
  }

  setFilter() {
    // Filter string
    const f: string[] = [];
    Globals.pushFilter(f, 'name', this.search.name, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'ref', this.search.ref, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'shop.id', this.search.shop_id, 'eq', this.session.lang.code, 'Long');
    Globals.pushFilter(f, 'shop.name', this.search.shop_name, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'category.id', this.search.category_id, 'contains', this.session.lang.code, 'Long');
    Globals.pushFilter(f, 'price', this.search.price, 'eq', this.session.lang.code, 'Double');
    Globals.pushFilter(f, 'description', this.search.description, 'like', this.session.lang.code, 'String');
    this.filter = Globals.encode(f.join(','));

    // Save filter
    this.session.search.form = 'Furniture';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;
  }

  doFilter(event: any) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.setFilter();
      this.page = 0;
      this.load();
    }, 400);
  }

  // Clear filter
  clearFilter() {
    // Clear filter string
    this.search.name = '';
    this.search.ref = '';
    this.search.shop_id = '';
    this.search.shop_name = '';
    this.search.category_id = '';
    this.search.price = '';
    this.search.description = '';

    // Clear
    this.session.search.data = this.search;

    // Search
    this.page = 0;
    this.filter = '';
    this.load();
  }

  // Load shop lookup
  loadShop() {
    this.apiShop.readAll('').subscribe(
      res => {
        this.shopoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'SHOP', err);
      }
    );
  }

  // Load category lookup
  loadCategory() {
    this.apiCategory.readAll('').subscribe(
      res => {
        this.categoryoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'CATEGORY', err);
      }
    );
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
