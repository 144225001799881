import { Category } from 'app/crud/decoration/category/category';
import { Shop } from 'app/crud/decoration/shop/shop';
import { Audit } from 'app/shared/model/audit';

// Furniture class
export class Furniture {

  // Key
  id: number;

  // Fields
  name: string;
  ref: string;
  shop: Shop; // = new Shop();
  category: Category[]; // = new Category();
  price: number;
  description: string;
  photo: any;
  phototype: string;
  photoname: string;
  photobytes: any;
  width: number;
  height: number;
  depth: number;
  url: string;

  // Audit
  audit : Audit = new Audit();
}

