<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.FURNITURE'|translate" [optional]="'LABEL.ENTITY.FURNITURE.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="furnitureForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.FURNITURE'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('name')">
              <label class="control-label" for="name">
                {{ 'LABEL.FIELD.FURNITURE.NAME'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <input
                formControlName="name"
                maxlength="200"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.FURNITURE.NAME':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('name')?.msg|translate:err('name')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (2) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('ref')">
              <label class="control-label" for="ref">
                {{ 'LABEL.FIELD.FURNITURE.REF'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <input
                formControlName="ref"
                maxlength="100"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.FURNITURE.REF':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('ref')?.msg|translate:err('ref')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (3) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('shop')">
              <label class="control-label" for="shop">
                {{ 'LABEL.FIELD.FURNITURE.SHOP'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <ng-select 
                formControlName="shop"
                clearable="false"
                [items]="shopoptions"
                bindLabel="name"
                bindValue="id"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.FURNITURE.SHOP':'')|translate }}"
                style="width: 100%;">
              </ng-select>
              <span class="help-block">{{err('shop')?.msg|translate:err('shop')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (4) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('category')">
              <label class="control-label" for="category">
                {{ 'LABEL.FIELD.FURNITURE.CATEGORY'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <ng-select 
                formControlName="category"
                multiple="true"
                clearable="false"
                [items]="categoryoptions"
                bindLabel="name"
                bindValue="id"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.FURNITURE.CATEGORY':'')|translate }}"
                style="width: 100%;">
              </ng-select>
              <span class="help-block">{{err('category')?.msg|translate:err('category')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (5) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('price')">
              <label class="control-label" for="price">
                {{ 'LABEL.FIELD.FURNITURE.PRICE'|translate }}
              </label>
              <input
                formControlName="price"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.FURNITURE.PRICE':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('price')?.msg|translate:err('price')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (6) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('description')">
              <label class="control-label" for="description">
                {{ 'LABEL.FIELD.FURNITURE.DESCRIPTION'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <textarea
                formControlName="description"
                autogrow [maximumHeight]="300" [minimumHeight]="60"
                (keydown.enter)="$event.stopPropagation();"
                maxlength="2000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.FURNITURE.DESCRIPTION':'')|translate }}"
                class="form-control" autocomplete="off">
              </textarea>
              <span class="help-block">{{err('description')?.msg|translate:err('description')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (7) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('photo')">
              <label class="control-label" for="photo">
                {{ 'LABEL.FIELD.FURNITURE.PHOTO'|translate }}
              </label>
              &nbsp;<i (click)="onFileDelete('photo', $event)" class="fa fa-trash"></i>
              <input *ngIf="mode!='view'"
                (change)="onFileChange('photo', $event)"
                type="file"
                class="form-control" autocomplete="off" style="margin-bottom:10px;">
              <div *ngIf="mode!='add' && !data?.photoFile">
                <div *ngIf="data?.phototype?.startsWith('image/')" style="margin-bottom:10px;">
                  <!--<img width="120px" src="data:{{data.phototype}};base64,{{data.photoBytes}}">-->
                  <img [hidden]="!photoLoaded" (load)="photoLoaded=true" width="120px" [src]="globals.server + 'furnitures/' + data.id + '/photo' | secure | async">
                </div>
                <!--<a *ngIf="data?.phototype" href="{{ globals.server }}furnitures/{{ data.id }}/photo" type="{{ data.phototype }}">-->
                <a *ngIf="data?.phototype" target="_blank" [href]="globals.server + 'furnitures/' + data.id + '/photo' | secure | async" type="{{ data.phototype }}">
                  <i [class]="'fa fa-lg ' + mimeIcon(data?.phototype)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
                </a>
              </div>
              <span class="help-block">{{err('photo')?.msg|translate:err('photo')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (8) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('width')">
              <label class="control-label" for="width">
                {{ 'LABEL.FIELD.FURNITURE.WIDTH'|translate }}
              </label>
              <input
                formControlName="width"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.FURNITURE.WIDTH':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('width')?.msg|translate:err('width')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (9) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('height')">
              <label class="control-label" for="height">
                {{ 'LABEL.FIELD.FURNITURE.HEIGHT'|translate }}
              </label>
              <input
                formControlName="height"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.FURNITURE.HEIGHT':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('height')?.msg|translate:err('height')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (10) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('depth')">
              <label class="control-label" for="depth">
                {{ 'LABEL.FIELD.FURNITURE.DEPTH'|translate }}
              </label>
              <input
                formControlName="depth"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.FURNITURE.DEPTH':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('depth')?.msg|translate:err('depth')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (11) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('url')">
              <label class="control-label" for="url">
                {{ 'LABEL.FIELD.FURNITURE.URL'|translate }}
              </label>
              <input
                formControlName="url"
                maxlength="500"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.FURNITURE.URL':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('url')?.msg|translate:err('url')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="furnitureForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:FURNITURE')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="furnitureForm.invalid || furnitureForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:FURNITURE')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:FURNITURE')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
