import { Audit } from 'app/shared/model/audit';

// Shop class
export class Shop {

  // Key
  id: number;

  // Fields
  name: string;
  web: string;
  logo: any;
  logotype: string;
  logoname: string;
  logobytes: any;
  details: string;

  // Audit
  audit : Audit = new Audit();
}

