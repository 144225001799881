import { Component, OnDestroy, Renderer2, Inject } from '@angular/core';
import { Globals } from 'app/shared/services/globals.service';
import { DOCUMENT } from '@angular/common';

declare var $: any;

@Component({
  templateUrl: './framed.component.html'
})
export class FramedLayoutComponent implements OnDestroy {

  private langs: string[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public globals: Globals) {
    // Change body class
    this.renderer.addClass(document.body, 'sidebar-mini');

    // Fix layout
    this.fix();
  }

  ngOnDestroy() {
    // Remove body class
    this.renderer.removeClass(document.body, 'sidebar-mini');
  }

  fix() {
    // Fix layout
    $('body').layout('fix');
  }
}
