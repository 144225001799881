import { Furniture } from 'app/crud/decoration/furniture/furniture';
import { Home } from 'app/crud/houses/home/home';
import { Audit } from 'app/shared/model/audit';

// Items class
export class Items {

  // Key
  id: number;

  // Fields
  home: Home; // = new Home();
  item: Furniture; // = new Furniture();
  room: string;
  comments: string;

  // Audit
  audit : Audit = new Audit();
}

