import { City } from 'app/crud/houses/city/city';
import { Company } from 'app/crud/houses/company/company';
import { HomeType } from 'app/crud/houses/hometype/hometype';
import { Audit } from 'app/shared/model/audit';

// Home class
export class Home {

  // Key
  code: string;

  // Fields
  active: string;
  web: string;
  sold: string;
  company: Company; // = new Company();
  city: City; // = new City();
  hometype: HomeType; // = new HomeType();
  address: string;
  refcat: string;
  aproxaddress: string;
  num: string;
  sellprice: number;
  renoprice: number;
  addprice: string;
  description: string;
  details: string;
  squaremeters: number;
  bedrooms: number;
  bathrooms: number;
  garage: number;
  features: string;
  equipment: string;
  building: string;
  pricing: string;
  lat: number;
  lng: number;
  image: any;
  imagetype: string;
  imagename: string;
  imagebytes: any;

  // Audit
  audit : Audit = new Audit();
}

