import { Home } from 'app/crud/houses/home/home';
import { Audit } from 'app/shared/model/audit';

// Document class
export class Document {

  // Key
  id: number;

  // Fields
  home: Home; // = new Home();
  position: number;
  description: string;
  file: any;
  filetype: string;
  filename: string;
  filebytes: any;

  // Audit
  audit : Audit = new Audit();
}

