<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.SCAN'|translate" [optional]="'LABEL.ENTITY.SCAN.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="scanForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.SCAN'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('source')">
              <label class="control-label" for="source">
                {{ 'LABEL.FIELD.SCAN.SOURCE'|translate }}
              </label>
              <input
                formControlName="source"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.SOURCE':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('source')?.msg|translate:err('source')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (2) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('batch')">
              <label class="control-label" for="batch">
                {{ 'LABEL.FIELD.SCAN.BATCH'|translate }}
              </label>
              <input
                formControlName="batch"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.BATCH':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('batch')?.msg|translate:err('batch')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (3) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('job')">
              <label class="control-label" for="job">
                {{ 'LABEL.FIELD.SCAN.JOB'|translate }}
              </label>
              <input
                formControlName="job"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.JOB':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('job')?.msg|translate:err('job')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (4) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('starttime')">
              <label class="control-label" for="starttime">
                {{ 'LABEL.FIELD.SCAN.STARTTIME'|translate }}
              </label>
              <input
                formControlName="starttime"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.STARTTIME':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('starttime')?.msg|translate:err('starttime')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (5) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('finishtime')">
              <label class="control-label" for="finishtime">
                {{ 'LABEL.FIELD.SCAN.FINISHTIME'|translate }}
              </label>
              <input
                formControlName="finishtime"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.FINISHTIME':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('finishtime')?.msg|translate:err('finishtime')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (6) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('elapsedtime')">
              <label class="control-label" for="elapsedtime">
                {{ 'LABEL.FIELD.SCAN.ELAPSEDTIME'|translate }}
              </label>
              <input
                formControlName="elapsedtime"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.ELAPSEDTIME':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('elapsedtime')?.msg|translate:err('elapsedtime')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (7) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('trypages')">
              <label class="control-label" for="trypages">
                {{ 'LABEL.FIELD.SCAN.TRYPAGES'|translate }}
              </label>
              <input
                formControlName="trypages"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.TRYPAGES':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('trypages')?.msg|translate:err('trypages')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (8) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('mappages')">
              <label class="control-label" for="mappages">
                {{ 'LABEL.FIELD.SCAN.MAPPAGES'|translate }}
              </label>
              <input
                formControlName="mappages"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.MAPPAGES':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('mappages')?.msg|translate:err('mappages')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (9) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('datapages')">
              <label class="control-label" for="datapages">
                {{ 'LABEL.FIELD.SCAN.DATAPAGES'|translate }}
              </label>
              <input
                formControlName="datapages"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.DATAPAGES':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('datapages')?.msg|translate:err('datapages')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (10) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('trycards')">
              <label class="control-label" for="trycards">
                {{ 'LABEL.FIELD.SCAN.TRYCARDS'|translate }}
              </label>
              <input
                formControlName="trycards"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.TRYCARDS':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('trycards')?.msg|translate:err('trycards')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (11) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('cards')">
              <label class="control-label" for="cards">
                {{ 'LABEL.FIELD.SCAN.CARDS'|translate }}
              </label>
              <input
                formControlName="cards"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.CARDS':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('cards')?.msg|translate:err('cards')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (12) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('tryhouses')">
              <label class="control-label" for="tryhouses">
                {{ 'LABEL.FIELD.SCAN.TRYHOUSES'|translate }}
              </label>
              <input
                formControlName="tryhouses"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.TRYHOUSES':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('tryhouses')?.msg|translate:err('tryhouses')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (13) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('houses')">
              <label class="control-label" for="houses">
                {{ 'LABEL.FIELD.SCAN.HOUSES'|translate }}
              </label>
              <input
                formControlName="houses"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.HOUSES':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('houses')?.msg|translate:err('houses')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (14) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('errors')">
              <label class="control-label" for="errors">
                {{ 'LABEL.FIELD.SCAN.ERRORS'|translate }}
              </label>
              <input
                formControlName="errors"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.ERRORS':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('errors')?.msg|translate:err('errors')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (15) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('requests')">
              <label class="control-label" for="requests">
                {{ 'LABEL.FIELD.SCAN.REQUESTS'|translate }}
              </label>
              <input
                formControlName="requests"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.REQUESTS':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('requests')?.msg|translate:err('requests')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (16) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('reqbytes')">
              <label class="control-label" for="reqbytes">
                {{ 'LABEL.FIELD.SCAN.REQBYTES'|translate }}
              </label>
              <input
                formControlName="reqbytes"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.REQBYTES':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('reqbytes')?.msg|translate:err('reqbytes')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (17) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('responses')">
              <label class="control-label" for="responses">
                {{ 'LABEL.FIELD.SCAN.RESPONSES'|translate }}
              </label>
              <input
                formControlName="responses"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.RESPONSES':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('responses')?.msg|translate:err('responses')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (18) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('resbytes')">
              <label class="control-label" for="resbytes">
                {{ 'LABEL.FIELD.SCAN.RESBYTES'|translate }}
              </label>
              <input
                formControlName="resbytes"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.RESBYTES':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('resbytes')?.msg|translate:err('resbytes')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (19) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('stats')">
              <label class="control-label" for="stats">
                {{ 'LABEL.FIELD.SCAN.STATS'|translate }}
              </label>
              <input
                formControlName="stats"
                maxlength="2000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SCAN.STATS':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('stats')?.msg|translate:err('stats')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="scanForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:SCAN')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="scanForm.invalid || scanForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:SCAN')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:SCAN')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
