import { Component, OnInit } from '@angular/core';

import { Login } from 'app/shared/model/login';

@Component({
  selector: 'app-sidebar-user',
  templateUrl: './sidebar-user.component.html'
})
export class SidebarUserComponent implements OnInit {

  login: Login = new Login();
  photo: string;

  constructor() { }

  ngOnInit() {
    // Get current login
    this.login = JSON.parse(localStorage.getItem('login'));

    // Get photo, from the token, if exists
    const pic = this.login.decoded['picture'];
    if (pic) {
      this.photo = pic;
    } else {
      this.photo = 'assets/user.png';
    }
  }

}
