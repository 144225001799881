// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { City } from 'app/crud/houses/city/city';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class CityService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Query allcities
  allCities(): Observable<any> {
    const url = `${this.globals.server}cities/q/allcities`;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError<any>('query allCities'))
    );
  }

  // Query citiesbycompany
  citiesByCompany(qname: string): Observable<any> {
    const url = `${this.globals.server}cities/q/citiesbycompany/${qname}`;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError<any>('query citiesByCompany'))
    );
  }

  // Read all cities
  readAll(sort: string): Observable<Page<City>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of cities
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<City>> {
    let url = `${this.globals.server}cities?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<City>>(url).pipe(
      // tap(_ => console.log('read cities')),
      catchError(this.handleError<Page<City>>('read City'))
    );
  }

  // Read one city
  readOne(id: number): Observable<City> {
    return this.http.get<City>(`${this.globals.server}cities/${id}`).pipe(
      // tap(_ => console.log(`readOne city ${id} `)),
      catchError(this.handleError<City>(`readOne City ${id} `))
    );
  }

  // Count cities
  count(): Observable<City[]> {
    return this.http.get<City[]>(`${this.globals.server}cities/count`).pipe(
      // tap(_ => console.log('count cities')),
      catchError(this.handleError('count City', []))
    );
  }

  // Create city
  create(o: City): Observable<City> {
    const input = new FormData();
    if (o.image) { input.append('image', o.image, o.imagename); }
    delete o.image;
    input.append('data', JSON.stringify(o));
    return this.http.post<City>(`${this.globals.server}cities`, input).pipe(
      // tap(_ => console.log('create city')),
      catchError(this.handleError<City>('create City'))
    );
  }

  // Update city
  update(id: number, o: City): Observable<any> {
    const input = new FormData();
    if (o.image) { input.append('image', o.image, o.imagename); }
    delete o.image;
    input.append('data', JSON.stringify(o));
    return this.http.put(`${this.globals.server}cities/${id}`, input).pipe(
      // tap(_ => console.log(`update city ${id} `)),
      catchError(this.handleError<any>(`update City ${id} `))
    );
  }

  // Delete city
  delete(id: number): Observable<City> {
    return this.http.delete<City>(`${this.globals.server}cities/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete city ${id} `)),
      catchError(this.handleError<City>(`delete City ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
