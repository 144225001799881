import { Audit } from 'app/shared/model/audit';

// Close class
export class Close {

  // Key
  reference: string;

  // Fields
  oldprice: number;
  newprice: number;
  renoprice: number;
  closedate: string;
  condition: string;

  // Audit
  audit : Audit = new Audit();
}

