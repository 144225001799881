<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.INTERACTION'|translate" [optional]="'LABEL.ENTITY.INTERACTION.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="interactionForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.INTERACTION'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('interaction')">
              <label class="control-label" for="interaction">
                {{ 'LABEL.FIELD.INTERACTION.INTERACTION'|translate }}
              </label>
              <input
                formControlName="interaction"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.INTERACTION.INTERACTION':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('interaction')?.msg|translate:err('interaction')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (2) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('subject')">
              <label class="control-label" for="subject">
                {{ 'LABEL.FIELD.INTERACTION.SUBJECT'|translate }}
              </label>
              <input
                formControlName="subject"
                maxlength="200"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.INTERACTION.SUBJECT':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('subject')?.msg|translate:err('subject')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (3) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('message')">
              <label class="control-label" for="message">
                {{ 'LABEL.FIELD.INTERACTION.MESSAGE'|translate }}
              </label>
              <textarea
                formControlName="message"
                autogrow [maximumHeight]="300" [minimumHeight]="60"
                (keydown.enter)="$event.stopPropagation();"
                maxlength="2000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.INTERACTION.MESSAGE':'')|translate }}"
                class="form-control" autocomplete="off">
              </textarea>
              <span class="help-block">{{err('message')?.msg|translate:err('message')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (4) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('comments')">
              <label class="control-label" for="comments">
                {{ 'LABEL.FIELD.INTERACTION.COMMENTS'|translate }}
              </label>
              <textarea
                formControlName="comments"
                autogrow [maximumHeight]="300" [minimumHeight]="60"
                (keydown.enter)="$event.stopPropagation();"
                maxlength="2000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.INTERACTION.COMMENTS':'')|translate }}"
                class="form-control" autocomplete="off">
              </textarea>
              <span class="help-block">{{err('comments')?.msg|translate:err('comments')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (5) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('tags')">
              <label class="control-label" for="tags">
                {{ 'LABEL.FIELD.INTERACTION.TAGS'|translate }}
              </label>
              <textarea
                formControlName="tags"
                autogrow [maximumHeight]="300" [minimumHeight]="60"
                (keydown.enter)="$event.stopPropagation();"
                maxlength="1000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.INTERACTION.TAGS':'')|translate }}"
                class="form-control" autocomplete="off">
              </textarea>
              <span class="help-block">{{err('tags')?.msg|translate:err('tags')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="interactionForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:INTERACTION')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="interactionForm.invalid || interactionForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:INTERACTION')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:INTERACTION')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
