import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from 'app/shared/services/auth.service';
import { Login } from 'app/shared/model/login';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  inflightAuthRequest = null;

  intercept(req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {

    // Skip if not auth ready
    if (this.auth == null) {
      return next.handle(req);
    }

    // Skip request with authorization header
    if (req.headers.has('Authorization')) {
      return next.handle(req);
    }

    // Get current token
    if (!this.inflightAuthRequest) {
      this.inflightAuthRequest = of(this.auth.login);
    }

    return this.inflightAuthRequest.pipe(

      switchMap((login: Login) => {
        // Add token to request
        this.inflightAuthRequest = null;
        if (login) {
          req = req.clone({ setHeaders: { TokenIssuer: `${login.issuer}`, Authorization: `Bearer ${login.token}` } });
        }
        return next.handle(req);
      }),

      catchError(error => {
        // Checks http error
        if (error.status === 401) {

          // Not already requested the refresh token, request one
          if (!this.inflightAuthRequest) {
            console.log('get refreshed token');
            this.inflightAuthRequest = this.auth.getRefreshedToken();
          }

          // Already requesting
          return this.inflightAuthRequest.pipe(

            switchMap((login: Login) => {
              // Add token to request
              this.inflightAuthRequest = null;
              if (login) {
                req = req.clone({ setHeaders: { TokenIssuer: `${login.issuer}`, Authorization: `Bearer ${login.token}` } });
              }
              return next.handle(req);
            }),

            catchError(e => {
              // Error, go to login
              this.inflightAuthRequest = null;
              this.auth.doLogout();
              this.router.navigate(['/']);
              return throwError(e);
            })
          );

        // Other error
        } else {
          this.inflightAuthRequest = null;
          return throwError(error);
        }

      })
    );
  }

}
