<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.CONTACT'|translate" [optional]="'LABEL.ENTITY.CONTACT.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="contactForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.CONTACT'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('crmid')">
              <label class="control-label" for="crmid">
                {{ 'LABEL.FIELD.CONTACT.CRMID'|translate }}
              </label>
              <input
                formControlName="crmid"
                maxlength="8"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTACT.CRMID':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('crmid')?.msg|translate:err('crmid')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (2) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('email')">
              <label class="control-label" for="email">
                {{ 'LABEL.FIELD.CONTACT.EMAIL'|translate }}
              </label>
              <input
                formControlName="email"
                maxlength="200"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTACT.EMAIL':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('email')?.msg|translate:err('email')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (3) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('name')">
              <label class="control-label" for="name">
                {{ 'LABEL.FIELD.CONTACT.NAME'|translate }}
              </label>
              <input
                formControlName="name"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTACT.NAME':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('name')?.msg|translate:err('name')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (4) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('surname')">
              <label class="control-label" for="surname">
                {{ 'LABEL.FIELD.CONTACT.SURNAME'|translate }}
              </label>
              <input
                formControlName="surname"
                maxlength="200"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTACT.SURNAME':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('surname')?.msg|translate:err('surname')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (5) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('phone')">
              <label class="control-label" for="phone">
                {{ 'LABEL.FIELD.CONTACT.PHONE'|translate }}
              </label>
              <input
                formControlName="phone"
                maxlength="20"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTACT.PHONE':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('phone')?.msg|translate:err('phone')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (6) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('mobile')">
              <label class="control-label" for="mobile">
                {{ 'LABEL.FIELD.CONTACT.MOBILE'|translate }}
              </label>
              <input
                formControlName="mobile"
                maxlength="20"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTACT.MOBILE':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('mobile')?.msg|translate:err('mobile')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (7) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('address')">
              <label class="control-label" for="address">
                {{ 'LABEL.FIELD.CONTACT.ADDRESS'|translate }}
              </label>
              <input
                formControlName="address"
                maxlength="200"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTACT.ADDRESS':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('address')?.msg|translate:err('address')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (8) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('zip')">
              <label class="control-label" for="zip">
                {{ 'LABEL.FIELD.CONTACT.ZIP'|translate }}
              </label>
              <input
                formControlName="zip"
                maxlength="5"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTACT.ZIP':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('zip')?.msg|translate:err('zip')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (9) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('city')">
              <label class="control-label" for="city">
                {{ 'LABEL.FIELD.CONTACT.CITY'|translate }}
              </label>
              <input
                formControlName="city"
                maxlength="200"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTACT.CITY':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('city')?.msg|translate:err('city')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (10) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('comments')">
              <label class="control-label" for="comments">
                {{ 'LABEL.FIELD.CONTACT.COMMENTS'|translate }}
              </label>
              <textarea
                formControlName="comments"
                autogrow [maximumHeight]="300" [minimumHeight]="60"
                (keydown.enter)="$event.stopPropagation();"
                maxlength="2000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTACT.COMMENTS':'')|translate }}"
                class="form-control" autocomplete="off">
              </textarea>
              <span class="help-block">{{err('comments')?.msg|translate:err('comments')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (11) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('rgpd')">
              <label class="control-label" for="rgpd">
                {{ 'LABEL.FIELD.CONTACT.RGPD'|translate }}
              </label>
              <div class="checkbox">
                <input
                  formControlName="rgpd" id="rgpd" 
                  type="checkbox">
                <label for="rgpd">{{ 'LABEL.FIELD.CONTACT.RGPD'|translate }}</label>
              </div>
              <span class="help-block">{{err('rgpd')?.msg|translate:err('rgpd')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (12) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('confirm')">
              <label class="control-label" for="confirm">
                {{ 'LABEL.FIELD.CONTACT.CONFIRM'|translate }}
              </label>
              <div class="checkbox">
                <input
                  formControlName="confirm" id="confirm" 
                  type="checkbox">
                <label for="confirm">{{ 'LABEL.FIELD.CONTACT.CONFIRM'|translate }}</label>
              </div>
              <span class="help-block">{{err('confirm')?.msg|translate:err('confirm')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (13) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('source')">
              <label class="control-label" for="source">
                {{ 'LABEL.FIELD.CONTACT.SOURCE'|translate }}
              </label>
              <ng-select 
                formControlName="source"
                [items]="sourceoptions"
                bindLabel="name"
                bindValue="id"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTACT.SOURCE':'')|translate }}"
                style="width: 100%;">
              </ng-select>
              <span class="help-block">{{err('source')?.msg|translate:err('source')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (14) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('createdon')">
              <label class="control-label" for="createdon">
                {{ 'LABEL.FIELD.CONTACT.CREATEDON'|translate }}
              </label>
              <input
                formControlName="createdon"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTACT.CREATEDON':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('createdon')?.msg|translate:err('createdon')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (15) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('updatedon')">
              <label class="control-label" for="updatedon">
                {{ 'LABEL.FIELD.CONTACT.UPDATEDON'|translate }}
              </label>
              <input
                formControlName="updatedon"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTACT.UPDATEDON':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('updatedon')?.msg|translate:err('updatedon')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="contactForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:CONTACT')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="contactForm.invalid || contactForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:CONTACT')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:CONTACT')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>
        <!-- Tabbed panel-->
        <div class="nav-tabs-custom">

          <!-- Tabs labels -->
          <ul class="nav nav-tabs">
            <li class="active">
              <a href="#tabValuation" data-toggle="tab">{{ 'LABEL.ENTITY.VALUATION'|translate }}</a>
            </li>
            <li>
              <a href="#tabInteraction" data-toggle="tab">{{ 'LABEL.ENTITY.INTERACTION'|translate }}</a>
            </li>
            <li>
              <a href="#tabTask" data-toggle="tab">{{ 'LABEL.ENTITY.TASK'|translate }}</a>
            </li>
            <li>
              <a href="#tabNote" data-toggle="tab">{{ 'LABEL.ENTITY.NOTE'|translate }}</a>
            </li>
          </ul>

            <!-- Tabs content -->
            <div class="tab-content">

            <!-- Tab -->
            <div class="active tab-pane" id="tabValuation">
              <div class="box-body">
              <app-valuation-contact-table *ngIf="mode!='add' && data.id" 
                parent="contacts" 
                [value]="data.id">
              </app-valuation-contact-table>
              </div>
            </div> <!-- end tab -->
    
            <!-- Tab -->
            <div class=" tab-pane" id="tabInteraction">
              <div class="box-body">
              <app-interaction-contact-table *ngIf="mode!='add' && data.id" 
                parent="contacts" 
                [value]="data.id">
              </app-interaction-contact-table>
              </div>
            </div> <!-- end tab -->
    
            <!-- Tab -->
            <div class=" tab-pane" id="tabTask">
              <div class="box-body">
              <app-task-contact-table *ngIf="mode!='add' && data.id" 
                parent="contacts" 
                [value]="data.id">
              </app-task-contact-table>
              </div>
            </div> <!-- end tab -->
    
            <!-- Tab -->
            <div class=" tab-pane" id="tabNote">
              <div class="box-body">
              <app-note-contact-table *ngIf="mode!='add' && data.id" 
                parent="contacts" 
                [value]="data.id">
              </app-note-contact-table>
              </div>
            </div> <!-- end tab -->
    
            </div> <!-- end tab-content -->

        </div>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
