// tslint:disable: directive-selector
import { Directive, ElementRef, AfterContentChecked, AfterViewInit, Renderer2 } from '@angular/core';

@Directive({
  selector: 'form[focus]'
})
export class AutofocusDirective implements AfterViewInit {

  constructor(
    private eRef: ElementRef) {
  }

  private _getInputElement(nativeElement: any): any {
    // Element is not defined?
    if (!nativeElement || !nativeElement.children) {
      return undefined;
    }

    // Is not an input?
    if (!nativeElement.children.length && nativeElement.localName === 'input' && !nativeElement.hidden) {
      return nativeElement;
    }

    // Scan each element recursively
    let input;
    [].slice.call(nativeElement.children).every(c => {
      input = this._getInputElement(c);
      if (input) {
        return false;
      }
      return true;
    });

    // Return first input
    return input;
  }

  ngAfterViewInit() {
    // Get all form children
    const formChildren = [].slice.call(this.eRef.nativeElement.children);

    // Scan every element to find first input
    formChildren.every(child => {
      const input = this._getInputElement(child);
      if (input) {
        input.focus();
        return false;
      }
      return true;
    });
  }

}
