import { Company } from 'app/crud/houses/company/company';
import { Contact } from 'app/crud/customers/contact/contact';
import { Audit } from 'app/shared/model/audit';

// Valuation class
export class Valuation {

  // Key
  id: number;

  // Fields
  company: Company; // = new Company();
  reference: string;
  zone: string;
  address: string;
  zip: string;
  city: string;
  form: string;
  contact: Contact; // = new Contact();
  why: string;
  timeframe: string;
  tags: string;
  comments: string;
  file: any;
  filetype: string;
  filename: string;
  filebytes: any;

  // Audit
  audit : Audit = new Audit();
}

