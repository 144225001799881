import { Component, Input } from '@angular/core';
import { SessionService } from 'app/shared/services/session.service';
import { AuthService } from 'app/shared/services/auth.service';
import { Menu } from 'app/shared/model/menu';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html'
})
export class SidebarMenuComponent {

  @Input()
  title: string;

  constructor(
    private http: HttpClient,
    public auth: AuthService,
    public session: SessionService
  ) {
    // Read menu
    this.http.get<Menu>('assets/json/menu.json').subscribe(data => {

      // Get only parent menus with access
      const m = data.menu.filter(o => this.auth.canShow('VIEW:' + o.code));

      for (const i of m) {
        if (i.menu) {
          // Restrict access to child menus
          i.menu = i.menu.filter(o => this.auth.canShow('VIEW:' + o.code));

          // Update parent route
          if (i.menu.length > 0) {
            i.route = i.menu[0].route;
          }
        }
      }

      // Assign resulting menu
      this.session.menu = m;
    });
  }

}
