<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.CITY'|translate" [optional]="'LABEL.ENTITY.CITY.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="cityForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.CITY'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('name')">
              <label class="control-label" for="name">
                {{ 'LABEL.FIELD.CITY.NAME'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <input
                formControlName="name"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CITY.NAME':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('name')?.msg|translate:err('name')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (2) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('lat')">
              <label class="control-label" for="lat">
                {{ 'LABEL.FIELD.CITY.LAT'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <input
                formControlName="lat"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CITY.LAT':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('lat')?.msg|translate:err('lat')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (3) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('lng')">
              <label class="control-label" for="lng">
                {{ 'LABEL.FIELD.CITY.LNG'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <input
                formControlName="lng"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CITY.LNG':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('lng')?.msg|translate:err('lng')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (4) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('image')">
              <label class="control-label" for="image">
                {{ 'LABEL.FIELD.CITY.IMAGE'|translate }}
              </label>
              &nbsp;<i (click)="onFileDelete('image', $event)" class="fa fa-trash"></i>
              <input *ngIf="mode!='view'"
                (change)="onFileChange('image', $event)"
                type="file"
                class="form-control" autocomplete="off" style="margin-bottom:10px;">
              <div *ngIf="mode!='add' && !data?.imageFile">
                <div *ngIf="data?.imagetype?.startsWith('image/')" style="margin-bottom:10px;">
                  <!--<img width="120px" src="data:{{data.imagetype}};base64,{{data.imageBytes}}">-->
                  <img [hidden]="!imageLoaded" (load)="imageLoaded=true" width="120px" [src]="globals.server + 'cities/' + data.id + '/image' | secure | async">
                </div>
                <!--<a *ngIf="data?.imagetype" href="{{ globals.server }}cities/{{ data.id }}/image" type="{{ data.imagetype }}">-->
                <a *ngIf="data?.imagetype" target="_blank" [href]="globals.server + 'cities/' + data.id + '/image' | secure | async" type="{{ data.imagetype }}">
                  <i [class]="'fa fa-lg ' + mimeIcon(data?.imagetype)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
                </a>
              </div>
              <span class="help-block">{{err('image')?.msg|translate:err('image')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="cityForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:CITY')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="cityForm.invalid || cityForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:CITY')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:CITY')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
