<div class="content-wrapper">
  
  <app-header-content [header]="'LABEL.HOME'|translate" [optional]="'LABEL.HOME'|translate"></app-header-content>
  <app-spinner *ngIf="isLoading"></app-spinner>

  <section class="content">

    <div class="row">

      <!-- Colors -->
      <!-- 
      <div class="col-md-6">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">Colors</h3>
          </div>
          <div class="box-body">
            <div *ngFor="let p of palette">
              <button  
                *ngFor="let c of p; let i = index" 
                type="button" 
                class="btn colorbutton" 
                [style.color]="i > 2 ? '#000' : '#fff'"
                [style.background-color]="c">
                {{ c }}
              </button>
            </div>
          </div>
        </div>
      </div>
       -->

      <!-- Chart 1 -->
      <!--
      <div class="col-md-6">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">Casas por Inmobiliaria</h3>
          </div>
          <div class="box-body">
            <div class="chart">
              <canvas id="houses-by-company" height="300"></canvas>
            </div>
          </div>
        </div>
      </div>
      -->

      <!-- Chart 2 -->
      <!--
      <div class="col-md-6">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">Casas por ciudad</h3>
          </div>
          <div class="box-body">
            <div class="chart">
              <canvas id="houses-by-city" height="300"></canvas>
            </div>
          </div>
        </div>
      </div>
      -->

    </div>

  </section>

  <pre style="font-size:10px;margin:20px;">{{ ok | json }}</pre>
  
</div>
