// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { History } from 'app/crud/valuator/history/history';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all histories
  readAll(sort: string): Observable<Page<History>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of histories
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<History>> {
    let url = `${this.globals.server}histories?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<History>>(url).pipe(
      // tap(_ => console.log('read histories')),
      catchError(this.handleError<Page<History>>('read History'))
    );
  }

  // Read pages of histories from houses, houses cannot have composite keys
  readFromHouse(house: string, page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<History>> {
    let url = `${this.globals.server}houses/${house}/histories?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<History>>(url).pipe(
      // tap(_ => console.log('read histories')),
      catchError(this.handleError<Page<History>>('read History'))
    );
  }

  // Read one history
  readOne(hash: string): Observable<History> {
    return this.http.get<History>(`${this.globals.server}histories/${hash}`).pipe(
      // tap(_ => console.log(`readOne history ${hash} `)),
      catchError(this.handleError<History>(`readOne History ${hash} `))
    );
  }

  // Count histories
  count(): Observable<History[]> {
    return this.http.get<History[]>(`${this.globals.server}histories/count`).pipe(
      // tap(_ => console.log('count histories')),
      catchError(this.handleError('count History', []))
    );
  }

  // Create history
  create(o: History): Observable<History> {
    return this.http.post<History>(`${this.globals.server}histories`, o, httpOptions).pipe(
      // tap(_ => console.log('create history')),
      catchError(this.handleError<History>('create History'))
    );
  }

  // Update history
  update(hash: string, o: History): Observable<any> {
    return this.http.put(`${this.globals.server}histories/${hash}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update history ${hash} `)),
      catchError(this.handleError<any>(`update History ${hash} `))
    );
  }

  // Delete history
  delete(hash: string): Observable<History> {
    return this.http.delete<History>(`${this.globals.server}histories/${hash}`, httpOptions).pipe(
      // tap(_ => console.log(`delete history ${hash} `)),
      catchError(this.handleError<History>(`delete History ${hash} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
