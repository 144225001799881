<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.PHOTO'|translate" [optional]="'LABEL.ENTITY.PHOTO.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="photoForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.PHOTO'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.PHOTO.GENERAL'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('position')">
                <label class="control-label" for="position">
                  {{ 'LABEL.FIELD.PHOTO.POSITION'|translate }}
                </label>
                <input
                  formControlName="position"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PHOTO.POSITION':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('position')?.msg|translate:err('position')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-10">
              <div class="form-group" [class.has-error]="err('description')">
                <label class="control-label" for="description">
                  {{ 'LABEL.FIELD.PHOTO.DESCRIPTION'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="description"
                  maxlength="50"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PHOTO.DESCRIPTION':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('description')?.msg|translate:err('description')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('plan')">
                <label class="control-label" for="plan">
                  {{ 'LABEL.FIELD.PHOTO.PLAN'|translate }}
                </label>
                <input
                  formControlName="plan"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PHOTO.PLAN':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('plan')?.msg|translate:err('plan')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('x')">
                <label class="control-label" for="x">
                  {{ 'LABEL.FIELD.PHOTO.X'|translate }}
                </label>
                <input
                  formControlName="x"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PHOTO.X':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('x')?.msg|translate:err('x')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('y')">
                <label class="control-label" for="y">
                  {{ 'LABEL.FIELD.PHOTO.Y'|translate }}
                </label>
                <input
                  formControlName="y"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PHOTO.Y':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('y')?.msg|translate:err('y')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('angle')">
                <label class="control-label" for="angle">
                  {{ 'LABEL.FIELD.PHOTO.ANGLE'|translate }}
                </label>
                <input
                  formControlName="angle"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PHOTO.ANGLE':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('angle')?.msg|translate:err('angle')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.PHOTO.PHOTOS'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('file')">
                <label class="control-label" for="file">
                  {{ 'LABEL.FIELD.PHOTO.FILE'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="file"
                  maxlength="50"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PHOTO.FILE':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('file')?.msg|translate:err('file')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('before')">
                <label class="control-label" for="before">
                  {{ 'LABEL.FIELD.PHOTO.BEFORE'|translate }}
                </label>
                &nbsp;<i (click)="onFileDelete('before', $event)" class="fa fa-trash"></i>
                <input *ngIf="mode!='view'"
                  (change)="onFileChange('before', $event)"
                  type="file"
                  class="form-control" autocomplete="off" style="margin-bottom:10px;">
                <div *ngIf="mode!='add' && !data?.beforeFile">
                  <div *ngIf="data?.beforetype?.startsWith('image/')" style="margin-bottom:10px;">
                    <!--<img width="120px" src="data:{{data.beforetype}};base64,{{data.beforeBytes}}">-->
                    <img [hidden]="!beforeLoaded" (load)="beforeLoaded=true" width="120px" [src]="globals.server + 'photos/' + data.id + '/before' | secure | async">
                  </div>
                  <!--<a *ngIf="data?.beforetype" href="{{ globals.server }}photos/{{ data.id }}/before" type="{{ data.beforetype }}">-->
                  <a *ngIf="data?.beforetype" target="_blank" [href]="globals.server + 'photos/' + data.id + '/before' | secure | async" type="{{ data.beforetype }}">
                    <i [class]="'fa fa-lg ' + mimeIcon(data?.beforetype)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
                  </a>
                </div>
                <span class="help-block">{{err('before')?.msg|translate:err('before')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('after')">
                <label class="control-label" for="after">
                  {{ 'LABEL.FIELD.PHOTO.AFTER'|translate }}
                </label>
                &nbsp;<i (click)="onFileDelete('after', $event)" class="fa fa-trash"></i>
                <input *ngIf="mode!='view'"
                  (change)="onFileChange('after', $event)"
                  type="file"
                  class="form-control" autocomplete="off" style="margin-bottom:10px;">
                <div *ngIf="mode!='add' && !data?.afterFile">
                  <div *ngIf="data?.aftertype?.startsWith('image/')" style="margin-bottom:10px;">
                    <!--<img width="120px" src="data:{{data.aftertype}};base64,{{data.afterBytes}}">-->
                    <img [hidden]="!afterLoaded" (load)="afterLoaded=true" width="120px" [src]="globals.server + 'photos/' + data.id + '/after' | secure | async">
                  </div>
                  <!--<a *ngIf="data?.aftertype" href="{{ globals.server }}photos/{{ data.id }}/after" type="{{ data.aftertype }}">-->
                  <a *ngIf="data?.aftertype" target="_blank" [href]="globals.server + 'photos/' + data.id + '/after' | secure | async" type="{{ data.aftertype }}">
                    <i [class]="'fa fa-lg ' + mimeIcon(data?.aftertype)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
                  </a>
                </div>
                <span class="help-block">{{err('after')?.msg|translate:err('after')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('option1')">
                <label class="control-label" for="option1">
                  {{ 'LABEL.FIELD.PHOTO.OPTION1'|translate }}
                </label>
                &nbsp;<i (click)="onFileDelete('option1', $event)" class="fa fa-trash"></i>
                <input *ngIf="mode!='view'"
                  (change)="onFileChange('option1', $event)"
                  type="file"
                  class="form-control" autocomplete="off" style="margin-bottom:10px;">
                <div *ngIf="mode!='add' && !data?.option1File">
                  <div *ngIf="data?.option1type?.startsWith('image/')" style="margin-bottom:10px;">
                    <!--<img width="120px" src="data:{{data.option1type}};base64,{{data.option1Bytes}}">-->
                    <img [hidden]="!option1Loaded" (load)="option1Loaded=true" width="120px" [src]="globals.server + 'photos/' + data.id + '/option1' | secure | async">
                  </div>
                  <!--<a *ngIf="data?.option1type" href="{{ globals.server }}photos/{{ data.id }}/option1" type="{{ data.option1type }}">-->
                  <a *ngIf="data?.option1type" target="_blank" [href]="globals.server + 'photos/' + data.id + '/option1' | secure | async" type="{{ data.option1type }}">
                    <i [class]="'fa fa-lg ' + mimeIcon(data?.option1type)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
                  </a>
                </div>
                <span class="help-block">{{err('option1')?.msg|translate:err('option1')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('option2')">
                <label class="control-label" for="option2">
                  {{ 'LABEL.FIELD.PHOTO.OPTION2'|translate }}
                </label>
                &nbsp;<i (click)="onFileDelete('option2', $event)" class="fa fa-trash"></i>
                <input *ngIf="mode!='view'"
                  (change)="onFileChange('option2', $event)"
                  type="file"
                  class="form-control" autocomplete="off" style="margin-bottom:10px;">
                <div *ngIf="mode!='add' && !data?.option2File">
                  <div *ngIf="data?.option2type?.startsWith('image/')" style="margin-bottom:10px;">
                    <!--<img width="120px" src="data:{{data.option2type}};base64,{{data.option2Bytes}}">-->
                    <img [hidden]="!option2Loaded" (load)="option2Loaded=true" width="120px" [src]="globals.server + 'photos/' + data.id + '/option2' | secure | async">
                  </div>
                  <!--<a *ngIf="data?.option2type" href="{{ globals.server }}photos/{{ data.id }}/option2" type="{{ data.option2type }}">-->
                  <a *ngIf="data?.option2type" target="_blank" [href]="globals.server + 'photos/' + data.id + '/option2' | secure | async" type="{{ data.option2type }}">
                    <i [class]="'fa fa-lg ' + mimeIcon(data?.option2type)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
                  </a>
                </div>
                <span class="help-block">{{err('option2')?.msg|translate:err('option2')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('option3')">
                <label class="control-label" for="option3">
                  {{ 'LABEL.FIELD.PHOTO.OPTION3'|translate }}
                </label>
                &nbsp;<i (click)="onFileDelete('option3', $event)" class="fa fa-trash"></i>
                <input *ngIf="mode!='view'"
                  (change)="onFileChange('option3', $event)"
                  type="file"
                  class="form-control" autocomplete="off" style="margin-bottom:10px;">
                <div *ngIf="mode!='add' && !data?.option3File">
                  <div *ngIf="data?.option3type?.startsWith('image/')" style="margin-bottom:10px;">
                    <!--<img width="120px" src="data:{{data.option3type}};base64,{{data.option3Bytes}}">-->
                    <img [hidden]="!option3Loaded" (load)="option3Loaded=true" width="120px" [src]="globals.server + 'photos/' + data.id + '/option3' | secure | async">
                  </div>
                  <!--<a *ngIf="data?.option3type" href="{{ globals.server }}photos/{{ data.id }}/option3" type="{{ data.option3type }}">-->
                  <a *ngIf="data?.option3type" target="_blank" [href]="globals.server + 'photos/' + data.id + '/option3' | secure | async" type="{{ data.option3type }}">
                    <i [class]="'fa fa-lg ' + mimeIcon(data?.option3type)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
                  </a>
                </div>
                <span class="help-block">{{err('option3')?.msg|translate:err('option3')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="photoForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:PHOTO')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="photoForm.invalid || photoForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:PHOTO')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:PHOTO')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->


    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
