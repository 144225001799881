import { Province } from 'app/crud/valuator/province/province';
import { Audit } from 'app/shared/model/audit';

// Town class
export class Town {

  // Key
  id: string;

  // Fields
  name: string;
  province: Province; // = new Province();

  // Audit
  audit : Audit = new Audit();
}

