<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.VALUATION'|translate" [optional]="'LABEL.ENTITY.VALUATION.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="valuationForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.VALUATION'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('company')">
              <label class="control-label" for="company">
                {{ 'LABEL.FIELD.VALUATION.COMPANY'|translate }}
              </label>
              <ng-select 
                formControlName="company"
                [items]="companyoptions"
                bindLabel="name"
                bindValue="id"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.VALUATION.COMPANY':'')|translate }}"
                style="width: 100%;">
              </ng-select>
              <span class="help-block">{{err('company')?.msg|translate:err('company')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (2) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('reference')">
              <label class="control-label" for="reference">
                {{ 'LABEL.FIELD.VALUATION.REFERENCE'|translate }}
              </label>
              <input
                formControlName="reference"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.VALUATION.REFERENCE':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('reference')?.msg|translate:err('reference')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (3) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('zone')">
              <label class="control-label" for="zone">
                {{ 'LABEL.FIELD.VALUATION.ZONE'|translate }}
              </label>
              <input
                formControlName="zone"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.VALUATION.ZONE':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('zone')?.msg|translate:err('zone')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (4) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('address')">
              <label class="control-label" for="address">
                {{ 'LABEL.FIELD.VALUATION.ADDRESS'|translate }}
              </label>
              <textarea
                formControlName="address"
                autogrow [maximumHeight]="300" [minimumHeight]="60"
                (keydown.enter)="$event.stopPropagation();"
                maxlength="1000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.VALUATION.ADDRESS':'')|translate }}"
                class="form-control" autocomplete="off">
              </textarea>
              <span class="help-block">{{err('address')?.msg|translate:err('address')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (5) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('zip')">
              <label class="control-label" for="zip">
                {{ 'LABEL.FIELD.VALUATION.ZIP'|translate }}
              </label>
              <input
                formControlName="zip"
                maxlength="10"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.VALUATION.ZIP':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('zip')?.msg|translate:err('zip')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (6) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('city')">
              <label class="control-label" for="city">
                {{ 'LABEL.FIELD.VALUATION.CITY'|translate }}
              </label>
              <input
                formControlName="city"
                maxlength="1000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.VALUATION.CITY':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('city')?.msg|translate:err('city')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (7) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('form')">
              <label class="control-label" for="form">
                {{ 'LABEL.FIELD.VALUATION.FORM'|translate }}
              </label>
              <textarea
                formControlName="form"
                autogrow [maximumHeight]="300" [minimumHeight]="60"
                (keydown.enter)="$event.stopPropagation();"
                maxlength="1000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.VALUATION.FORM':'')|translate }}"
                class="form-control" autocomplete="off">
              </textarea>
              <span class="help-block">{{err('form')?.msg|translate:err('form')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (8) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('why')">
              <label class="control-label" for="why">
                {{ 'LABEL.FIELD.VALUATION.WHY'|translate }}
              </label>
              <input
                formControlName="why"
                maxlength="100"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.VALUATION.WHY':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('why')?.msg|translate:err('why')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (9) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('timeframe')">
              <label class="control-label" for="timeframe">
                {{ 'LABEL.FIELD.VALUATION.TIMEFRAME'|translate }}
              </label>
              <input
                formControlName="timeframe"
                maxlength="100"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.VALUATION.TIMEFRAME':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('timeframe')?.msg|translate:err('timeframe')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (10) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('tags')">
              <label class="control-label" for="tags">
                {{ 'LABEL.FIELD.VALUATION.TAGS'|translate }}
              </label>
              <textarea
                formControlName="tags"
                autogrow [maximumHeight]="300" [minimumHeight]="60"
                (keydown.enter)="$event.stopPropagation();"
                maxlength="1000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.VALUATION.TAGS':'')|translate }}"
                class="form-control" autocomplete="off">
              </textarea>
              <span class="help-block">{{err('tags')?.msg|translate:err('tags')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (11) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('comments')">
              <label class="control-label" for="comments">
                {{ 'LABEL.FIELD.VALUATION.COMMENTS'|translate }}
              </label>
              <textarea
                formControlName="comments"
                autogrow [maximumHeight]="300" [minimumHeight]="60"
                (keydown.enter)="$event.stopPropagation();"
                maxlength="2000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.VALUATION.COMMENTS':'')|translate }}"
                class="form-control" autocomplete="off">
              </textarea>
              <span class="help-block">{{err('comments')?.msg|translate:err('comments')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (12) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('file')">
              <label class="control-label" for="file">
                {{ 'LABEL.FIELD.VALUATION.FILE'|translate }}
              </label>
              &nbsp;<i (click)="onFileDelete('file', $event)" class="fa fa-trash"></i>
              <input *ngIf="mode!='view'"
                (change)="onFileChange('file', $event)"
                type="file"
                class="form-control" autocomplete="off" style="margin-bottom:10px;">
              <div *ngIf="mode!='add' && !data?.fileFile">
                <div *ngIf="data?.filetype?.startsWith('image/')" style="margin-bottom:10px;">
                  <!--<img width="120px" src="data:{{data.filetype}};base64,{{data.fileBytes}}">-->
                  <img [hidden]="!fileLoaded" (load)="fileLoaded=true" width="120px" [src]="globals.server + 'valuations/' + data.id + '/file' | secure | async">
                </div>
                <!--<a *ngIf="data?.filetype" href="{{ globals.server }}valuations/{{ data.id }}/file" type="{{ data.filetype }}">-->
                <a *ngIf="data?.filetype" target="_blank" [href]="globals.server + 'valuations/' + data.id + '/file' | secure | async" type="{{ data.filetype }}">
                  <i [class]="'fa fa-lg ' + mimeIcon(data?.filetype)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
                </a>
              </div>
              <span class="help-block">{{err('file')?.msg|translate:err('file')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (13) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('createdon')">
              <label class="control-label" for="createdon">
                {{ 'LABEL.FIELD.VALUATION.CREATEDON'|translate }}
              </label>
              <input
                formControlName="createdon"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.VALUATION.CREATEDON':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('createdon')?.msg|translate:err('createdon')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="valuationForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:VALUATION')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="valuationForm.invalid || valuationForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:VALUATION')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:VALUATION')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
