import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Validators, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap';
import * as moment from 'moment';

import { HistoryService } from 'app/crud/valuator/history/history.service';
import { History } from 'app/crud/valuator/history/history';

import { HouseService } from 'app/crud/valuator/house/house.service';
import { House } from 'app/crud/valuator/house/house';

import { ConfirmDialogService } from 'app/layout/modal/confirm-dialog.service';
import { SessionService } from 'app/shared/services/session.service';
import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-history-house-form',
  templateUrl: './history-house-form.component.html',
})
export class HistoryHouseFormComponent implements OnInit {

  // Defalt form mode
  mode = 'add';

  // Select fields

  // Form
  historyForm = this.fb.group({
    // Key
    hash: ['', [Validators.minLength(0), Validators.maxLength(50), ]],
    // Fields
    batch: [{ value: '', disabled: false }, []],
    price: [{ value: '', disabled: false }, []],
    oldprice: [{ value: '', disabled: false }, []],
    intext: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(50), ]],
    lift: [{ value: '', disabled: false }, []],
    area: [{ value: '', disabled: false }, []],
    rooms: [{ value: '', disabled: false }, []],
    createdon: [{ value: '', disabled: true }],
    updatedon: [{ value: '', disabled: true }],
  });

  // Working record
  data: History = new History();

  // Loading indicators
  isLoading = false;

  // Components constructor
  constructor(
    private apiHouse: HouseService,
    private api: HistoryService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private location: Location,
    private confirm: ConfirmDialogService,
    private session: SessionService,
    private auth: AuthService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
  }

  // Component initialization
  ngOnInit() {
    // Init data
    this.data = new History();

    // Set the master relation field
    this.data.house = new House();
    this.data.house.id = this.route.snapshot.params.house;
    this.mapDataToForm(this.data, this.historyForm);

    // Get form mode
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Disable fields in view mode
    if (this.mode === 'view') {
      Object.keys(this.historyForm.controls).forEach(key => {
        this.historyForm.get(key).disable();
      });
    }

    // Disable key fields in edit mode
    if (this.mode === 'edit') {
      this.historyForm.get('hash').disable();
    }

    // Loads the record
    if (this.mode !== 'add') {
      this.load(
        this.route.snapshot.params.hash
      );
    }
  }

  mapDataToForm(data: History, form: FormGroup) {
    form.get('hash').setValue(data.hash);
    form.get('batch').setValue(data.batch);
    form.get('price').setValue(data.price);
    form.get('oldprice').setValue(data.oldprice);
    form.get('intext').setValue(data.intext);
    form.get('lift').setValue(data.lift);
    form.get('area').setValue(data.area);
    form.get('rooms').setValue(data.rooms);
  }

  mapFormToData(form: FormGroup, data: History) {
    data.hash = form.get('hash').value;
    data.batch = form.get('batch').value;
    data.price = form.get('price').value;
    data.oldprice = form.get('oldprice').value;
    data.intext = form.get('intext').value;
    data.lift = form.get('lift').value;
    data.area = form.get('area').value;
    data.rooms = form.get('rooms').value;
  }

  // Load selected record
  load(hash) {
    this.isLoading = true;
    this.api.readOne(hash).subscribe(
      res => {
        this.data = res;
        this.mapDataToForm(this.data, this.historyForm);
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('view', 'HISTORY', err);
      });
  }

  // Create a new record
  createData() {
    this.isLoading = true;
    this.mapFormToData(this.historyForm, this.data);
    this.api.create(this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('add', 'HISTORY');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('add', 'HISTORY', err);
      });
  }

  // Update record
  updateData() {
    this.isLoading = true;
    this.mapFormToData(this.historyForm, this.data);
    this.api.update(this.data.hash, this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('update', 'HISTORY');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('update', 'HISTORY', err);
      });
  }

  // Delete record
  deleteData() {
    const title = this.translate.instant('MESSAGE.DELETE') + ' ' + this.translate.instant('LABEL.ENTITY.HISTORY');
    const text  = this.translate.instant('MESSAGE.SURE');
    this.confirm.confirmThis(title, text, () => {}, () => {
      this.isLoading = true;
      this.api.delete(this.data.hash).subscribe(
        res => {
          this.isLoading = false;
          this.globals.crudOk('delete', 'HISTORY');
          this.back();
        },
        err => {
          this.isLoading = false;
          this.globals.crudKo('delete', 'HISTORY', err);
        });
    });
  }

  // Return to list page
  back() {
    this.location.back();
  }

  // Field error status and  message
  err(name: string) {
    return Globals.err(this.historyForm, name);
  }

  dateValidator(format: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (control.value === null || control.value === '') return null;
      moment.locale(this.session.lang.code);
      const date = moment.utc(control.value, format);
      return date.isValid() ? null : { invaliddate: true };
    };
  }

  getDateTime(control: AbstractControl, date: any, format: string) {
    if (control.value === null || control.value === '') return null;
    // Ñapa
    const aux = control.value;
    if (typeof aux != 'string')
      aux.setHours(aux.getHours() - aux.getTimezoneOffset() / 60);
    // Ñapa
    moment.locale(this.session.lang.code);
    const d = moment.utc(aux, format);
    if (d.isValid()) {
      return d.toISOString();
    } else {
      return date;
    }
  }

  setDateTime(control: AbstractControl, date: any, format: string) {
    moment.locale(this.session.lang.code);
    const d = moment.utc(date, 'YYYY-MM-DD');
    if (d.isValid()) {
      control.setValue(d.format(format));
    }
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
