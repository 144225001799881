// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';

// OpenID login
import { OAuthModule } from 'angular-oauth2-oidc';

// i18n
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Main component
import { AppComponent } from 'app/app.component';

// Routing
import { AppRoutingModule } from 'app/app-routing.module';
import { GeneralRoutingModule } from 'app/general/general-routing.module';
import { CrudRoutingModule } from 'app/crud/crud-routing.module';

// Shared modules
import { SharedModule } from 'app/shared/shared.module';
import { LayoutModule } from 'app/layout/layout.module';
import { GeneralModule } from 'app/general/general.module';
import { CrudModule } from 'app/crud/crud.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    GeneralModule,
    SharedModule,
    CrudModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    OAuthModule.forRoot(),
    CrudRoutingModule,
    GeneralRoutingModule,
    AppRoutingModule
  ],
  providers: [
  ],
  exports: [
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
