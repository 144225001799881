import { Audit } from 'app/shared/model/audit';

// Scan class
export class Scan {

  // Key
  id: number;

  // Fields
  source: string;
  batch: string;
  job: string;
  starttime: string;
  finishtime: string;
  elapsedtime: number;
  trypages: number;
  mappages: number;
  datapages: number;
  trycards: number;
  cards: number;
  tryhouses: number;
  houses: number;
  errors: number;
  requests: number;
  reqbytes: number;
  responses: number;
  resbytes: number;
  stats: string;

  // Audit
  audit : Audit = new Audit();
}

