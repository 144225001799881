import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap';

import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';
import { SessionService } from 'app/shared/services/session.service';
import { Page } from 'app/shared/model/page';

import { ItemsService } from 'app/crud/houses/items/items.service';
import { Items } from 'app/crud/houses/items/items';

import { HomeService } from 'app/crud/houses/home/home.service';
import { Home } from 'app/crud/houses/home/home';
import { FurnitureService } from 'app/crud/decoration/furniture/furniture.service';
import { Furniture } from 'app/crud/decoration/furniture/furniture';

@Component({
  selector: 'app-items-home-table',
  templateUrl: './items-home-table.component.html',
})
export class ItemsHomeTableComponent implements OnInit {

  // Select fields
  homeoptions: Home[] = [];
  furnitureoptions: Furniture[] = [];

  // Parent
  @Input() parent: string;
  @Input() value: any;

  // Defalt form mode
  mode = 'edit';

  // Search fields
  search = {
    item_id: '',
    item_name: '',
    item_ref: '',
    room: '',
    comments: '',
  };

  // Current page
  page = 0;

  // Sort field and direction
  sort = 'item';
  dir = '+';

  // Current filter
  filter = '';

  // One page of data
  data: Page<Items>;

  // Loading indicator
  isLoading = true;

  // Event debouncer
  debounce: any;

  constructor(
    private apiHome: HomeService,
    private apiFurniture: FurnitureService,
    private api: ItemsService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private session: SessionService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
      if (this.session.search.form == 'ItemsHome') {
        this.search = this.session.search.data;
        this.sort = this.session.search.sort;
        this.dir = this.session.search.dir;
        this.setFilter();  
      }
    }

  ngOnInit() {
    // Mode, if sub-table
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Load furniture lookup
    this.loadFurniture();

    // Loads the table
    this.load();
  }

  // Loads the table
  load() {
    this.isLoading = true;
    this.api.readFromHome(this.value, this.page, this.dir + this.sort, this.filter).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('read', 'ITEMS', err);
      }
    );
  }

  isSorted(field: string) {
    if (field === this.sort && this.dir === '-') {
      return 'sorting_desc';
    } else if (field === this.sort && this.dir === '+') {
      return 'sorting_asc';
    }
    return 'sorting';
  }

  doPage(page: number) {
    // Change page
    this.page = page;
    this.load();
  }

  doSort(field: string) {
    // Not sorted? Sort asc
    if (this.sort !== field) {
      if (field === 'createdon' || field === 'updatedon')
      	this.sort = 'audit.' + field;
      else
      	this.sort = field;
      this.dir = '+';
    } else {
      // Sorted asc? Sort desc
      if (this.dir === '+') {
        this.dir = '-';
      } else {
        // Else do not sort
        this.sort = '';
        this.dir = '';
      }
    }

    // Save sort
    this.session.search.form = 'ItemsHome';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;

    // Reload
    this.load();
  }

  setFilter() {
    // Filter string
    const f: string[] = [];
    Globals.pushFilter(f, 'item.id', this.search.item_id, 'eq', this.session.lang.code, 'Long');
    Globals.pushFilter(f, 'item.name', this.search.item_name, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'item.ref', this.search.item_ref, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'room', this.search.room, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'comments', this.search.comments, 'like', this.session.lang.code, 'String');
    this.filter = Globals.encode(f.join(','));

    // Save filter
    this.session.search.form = 'ItemsHome';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;
  }

  doFilter(event: any) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.setFilter();
      this.page = 0;
      this.load();
    }, 400);
  }

  // Clear filter
  clearFilter() {
    // Clear filter string
    this.search.item_id = '';
    this.search.item_name = '';
    this.search.item_ref = '';
    this.search.room = '';
    this.search.comments = '';

    // Clear
    this.session.search.data = this.search;

    // Search
    this.page = 0;
    this.filter = '';
    this.load();
  }

  // Load furniture lookup
  loadFurniture() {
    this.apiFurniture.readAll('').subscribe(
      res => {
        this.furnitureoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'FURNITURE', err);
      }
    );
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
