// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Furniture } from 'app/crud/decoration/furniture/furniture';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class FurnitureService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all furnitures
  readAll(sort: string): Observable<Page<Furniture>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of furnitures
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Furniture>> {
    let url = `${this.globals.server}furnitures?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Furniture>>(url).pipe(
      // tap(_ => console.log('read furnitures')),
      catchError(this.handleError<Page<Furniture>>('read Furniture'))
    );
  }

  // Read one furniture
  readOne(id: number): Observable<Furniture> {
    return this.http.get<Furniture>(`${this.globals.server}furnitures/${id}`).pipe(
      // tap(_ => console.log(`readOne furniture ${id} `)),
      catchError(this.handleError<Furniture>(`readOne Furniture ${id} `))
    );
  }

  // Count furnitures
  count(): Observable<Furniture[]> {
    return this.http.get<Furniture[]>(`${this.globals.server}furnitures/count`).pipe(
      // tap(_ => console.log('count furnitures')),
      catchError(this.handleError('count Furniture', []))
    );
  }

  // Create furniture
  create(o: Furniture): Observable<Furniture> {
    const input = new FormData();
    if (o.photo) { input.append('photo', o.photo, o.photoname); }
    delete o.photo;
    input.append('data', JSON.stringify(o));
    return this.http.post<Furniture>(`${this.globals.server}furnitures`, input).pipe(
      // tap(_ => console.log('create furniture')),
      catchError(this.handleError<Furniture>('create Furniture'))
    );
  }

  // Update furniture
  update(id: number, o: Furniture): Observable<any> {
    const input = new FormData();
    if (o.photo) { input.append('photo', o.photo, o.photoname); }
    delete o.photo;
    input.append('data', JSON.stringify(o));
    return this.http.put(`${this.globals.server}furnitures/${id}`, input).pipe(
      // tap(_ => console.log(`update furniture ${id} `)),
      catchError(this.handleError<any>(`update Furniture ${id} `))
    );
  }

  // Delete furniture
  delete(id: number): Observable<Furniture> {
    return this.http.delete<Furniture>(`${this.globals.server}furnitures/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete furniture ${id} `)),
      catchError(this.handleError<Furniture>(`delete Furniture ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
