<div class="content-wrapper">

  <!-- Header -->
  <app-header-content [header]="'LABEL.ENTITY.SCAN'|translate" [optional]="'LABEL.ENTITY.SCAN.DESCRIPTION'|translate"></app-header-content>

  <!-- Section -->
  <section class="content">
  <div class="row">
  <div class="col-xs-12">
  <div class="box box-primary">

	<!-- Loading spinner -->
	<app-spinner *ngIf="isLoading"></app-spinner>

	<!-- Data -->
	<div *ngIf="data">

	  <!-- Header -->
	  <div class="box-header with-border">
		<h3 class="box-title">{{ 'LABEL.ENTITY.SCAN.TABLE'|translate }}</h3>
		<button *ngIf="mode!='view' && auth.canShow('CREATE:SCAN')"
		  [routerLink]="['/scans', 'add']"
		  type="button" class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }} {{ 'LABEL.ENTITY.SCAN'|translate }}</button>
	  </div> <!-- end Header -->
	
	  <!-- Body -->
	  <div class="box-body dataTables_wrapper form-inline dt-bootstrap">

		<!-- Table -->
		<div class="row">
		<div class="col-sm-12">
		<table id="table-scan" class="table table-bordered table-hover dataTable" role="grid">

		  <!-- Titles row -->	
		  <thead>
			<tr role="row">
			  <!-- <th style="width:1%;"><div class="checkbox"><input type="checkbox"><label></label></div></th> -->
			  <th class="col-lg-1 " [ngClass]="isSorted('source')" (click)="doSort('source')">
			    {{'LABEL.FIELD.SCAN.SOURCE'|translate}}
			  </th>
			  <th class="col-lg-1 " [ngClass]="isSorted('batch')" (click)="doSort('batch')">
			    {{'LABEL.FIELD.SCAN.BATCH'|translate}}
			  </th>
			  <th class="col-lg-1  hidden-xs" [ngClass]="isSorted('job')" (click)="doSort('job')">
			    {{'LABEL.FIELD.SCAN.JOB'|translate}}
			  </th>
			  <th class="col-lg-1  hidden-xs" [ngClass]="isSorted('cards')" (click)="doSort('cards')">
			    {{'LABEL.FIELD.SCAN.CARDS'|translate}}
			  </th>
			  <th class="col-lg-1  hidden-sm hidden-xs" [ngClass]="isSorted('houses')" (click)="doSort('houses')">
			    {{'LABEL.FIELD.SCAN.HOUSES'|translate}}
			  </th>
			  <th class="col-lg-1  hidden-sm hidden-xs" [ngClass]="isSorted('requests')" (click)="doSort('requests')">
			    {{'LABEL.FIELD.SCAN.REQUESTS'|translate}}
			  </th>
			  <th style="width:1%;">{{'LABEL.ACTIONS'|translate}}</th>
			</tr>
		  </thead>

		  <!-- Filters row -->	
		  <thead>
			<tr role="row">
			  <!-- <th></th> -->
			  <th class="">
			    <input 
			  	[(ngModel)]="search.source" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.SCAN.SOURCE'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th class="">
			  <!--
			    <div class="input-group">
			    <input
			  	bsDatepicker [bsConfig]="{containerClass:'theme-dark-blue'}" #batch="bsDatepicker"
			  	[(ngModel)]="search.batch" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.SCAN.BATCH'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			    <div class="input-group-addon" (click)="batch.toggle()"><i class="fa fa-calendar"></i></div>
			    </div>
			  -->
			    <input
			  	[(ngModel)]="search.batch" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.SCAN.BATCH'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th class=" hidden-xs">
			    <input 
			  	[(ngModel)]="search.job" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.SCAN.JOB'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th class=" hidden-xs">
			    <input 
			  	[(ngModel)]="search.cards" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.SCAN.CARDS'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th class=" hidden-sm hidden-xs">
			    <input 
			  	[(ngModel)]="search.houses" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.SCAN.HOUSES'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th class=" hidden-sm hidden-xs">
			    <input 
			  	[(ngModel)]="search.requests" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.SCAN.REQUESTS'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th>
				<button (click)="clearFilter()" type="button" class="btn btn-default btn-sm">
				  <i class="fa fa-filter" style="position: relative;">
					<i class="fa fa-ban" style="position: absolute; left: 0px; color:Tomato"></i>
				  </i>
				</button>
			  </th>
			</tr>
		  </thead>

		  <!-- Data rows -->					
		  <tbody>
			<tr role="row"[ngClass]="{ odd:odd, even:even }" *ngFor="let o of data.content; odd as odd; even as even">
			  <!-- <td><div class="checkbox"><input type="checkbox"><label></label></div></td> -->
			  <td class="">
			    {{ o.source }}
			  </td>
			  <td class="">
			    {{ o.batch|localDate:'L LTS' }}
			  </td>
			  <td class=" hidden-xs">
			    {{ o.job }}
			  </td>
			  <td class="text-right  hidden-xs">
			    {{ o.cards|localNumber:'0-true' }}
			  </td>
			  <td class="text-right  hidden-sm hidden-xs">
			    {{ o.houses|localNumber:'0-true' }}
			  </td>
			  <td class="text-right  hidden-sm hidden-xs">
			    {{ o.requests|localNumber:'0-true' }}
			  </td>
			  <td>
				<div class="btn-group">
				  <button *ngIf="mode!='view' && auth.canShow('UPDATE:SCAN')"
					[routerLink]="['/scans', o.id, 'edit']"
					type="button" class="btn btn-default btn-sm"><i class="fa fa-edit"></i>
				  </button>
				  <button
					[routerLink]="['/scans', o.id, 'view']"
					type="button" class="btn btn-default btn-sm"><i class="fa fa-eye"></i>
				  </button>
				</div>
			  </td>
			</tr>
		  </tbody>

		</table>
		</div>
		</div> <!-- end Table -->
	
		<!-- Pagination -->
		<app-pagination [total]="data.totalElements" [size]="data.size" [num]="data.numberOfElements" (go)="doPage($event)"></app-pagination>
	
	  </div> <!-- end Body -->

	</div> <!-- end Data -->
  </div>
  </div>
  </div>
  </section> <!-- end Section --> 

</div> <!-- end content-wrapper -->
