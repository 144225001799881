// Angular common modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

// Routing
import { GeneralRoutingModule } from 'app/general/general-routing.module';

// Shared modules
import { SharedModule } from 'app/shared/shared.module';
import { LayoutModule } from 'app/layout/layout.module';

// General components
import { RootComponent } from 'app/general/root/root.component';
import { LoginComponent } from 'app/general/login/login.component';
import { LogoutComponent } from 'app/general/logout/logout.component';
import { HomeComponent } from 'app/general/home/home.component';

@NgModule({
  declarations: [
    RootComponent,
    LoginComponent,
    LogoutComponent,
    HomeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GeneralRoutingModule,
    SharedModule,
    LayoutModule
  ],
  providers: [
  ],
})
export class GeneralModule { }
