<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.COMPANY'|translate" [optional]="'LABEL.ENTITY.COMPANY.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="companyForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.COMPANY'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('name')">
              <label class="control-label" for="name">
                {{ 'LABEL.FIELD.COMPANY.NAME'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <input
                formControlName="name"
                maxlength="50"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.COMPANY.NAME':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('name')?.msg|translate:err('name')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (2) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('address')">
              <label class="control-label" for="address">
                {{ 'LABEL.FIELD.COMPANY.ADDRESS'|translate }}
              </label>
              <input
                formControlName="address"
                maxlength="250"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.COMPANY.ADDRESS':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('address')?.msg|translate:err('address')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (3) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('phone')">
              <label class="control-label" for="phone">
                {{ 'LABEL.FIELD.COMPANY.PHONE'|translate }}
              </label>
              <input
                formControlName="phone"
                maxlength="250"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.COMPANY.PHONE':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('phone')?.msg|translate:err('phone')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (4) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('web')">
              <label class="control-label" for="web">
                {{ 'LABEL.FIELD.COMPANY.WEB'|translate }}
              </label>
              <input
                formControlName="web"
                maxlength="250"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.COMPANY.WEB':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('web')?.msg|translate:err('web')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (5) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('logo')">
              <label class="control-label" for="logo">
                {{ 'LABEL.FIELD.COMPANY.LOGO'|translate }}
              </label>
              &nbsp;<i (click)="onFileDelete('logo', $event)" class="fa fa-trash"></i>
              <input *ngIf="mode!='view'"
                (change)="onFileChange('logo', $event)"
                type="file"
                class="form-control" autocomplete="off" style="margin-bottom:10px;">
              <div *ngIf="mode!='add' && !data?.logoFile">
                <div *ngIf="data?.logotype?.startsWith('image/')" style="margin-bottom:10px;">
                  <!--<img width="120px" src="data:{{data.logotype}};base64,{{data.logoBytes}}">-->
                  <img [hidden]="!logoLoaded" (load)="logoLoaded=true" width="120px" [src]="globals.server + 'companies/' + data.id + '/logo' | secure | async">
                </div>
                <!--<a *ngIf="data?.logotype" href="{{ globals.server }}companies/{{ data.id }}/logo" type="{{ data.logotype }}">-->
                <a *ngIf="data?.logotype" target="_blank" [href]="globals.server + 'companies/' + data.id + '/logo' | secure | async" type="{{ data.logotype }}">
                  <i [class]="'fa fa-lg ' + mimeIcon(data?.logotype)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
                </a>
              </div>
              <span class="help-block">{{err('logo')?.msg|translate:err('logo')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (6) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('addprice')">
              <label class="control-label" for="addprice">
                {{ 'LABEL.FIELD.COMPANY.ADDPRICE'|translate }}
              </label>
              <div class="checkbox">
                <input
                  formControlName="addprice" id="addprice" 
                  type="checkbox">
                <label for="addprice">{{ 'LABEL.FIELD.COMPANY.ADDPRICE'|translate }}</label>
              </div>
              <span class="help-block">{{err('addprice')?.msg|translate:err('addprice')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="companyForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:COMPANY')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="companyForm.invalid || companyForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:COMPANY')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:COMPANY')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
