import { Audit } from 'app/shared/model/audit';

// City class
export class City {

  // Key
  id: number;

  // Fields
  name: string;
  lat: number;
  lng: number;
  image: any;
  imagetype: string;
  imagename: string;
  imagebytes: any;

  // Audit
  audit : Audit = new Audit();
}

