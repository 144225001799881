import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header-content',
  templateUrl: './header-content.component.html'
})
export class HeaderContentComponent implements OnInit {

  @Input()
  header: string;

  @Input()
  optional: string;

  constructor() { }

  ngOnInit() {
  }

}
