import { Component, Input, OnInit } from '@angular/core';

import { ConfirmDialogService } from './confirm-dialog.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html'
})
export class ConfirmDialogComponent implements OnInit {

  message: any;

  constructor(
    private translate: TranslateService,
    private confirmDialogService: ConfirmDialogService
  ) { }

  ngOnInit() {
    this.confirmDialogService.getMessage().subscribe(message => {
      this.message = message;
    });
  }

}
