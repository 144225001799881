import { Home } from 'app/crud/houses/home/home';
import { Audit } from 'app/shared/model/audit';

// Photo class
export class Photo {

  // Key
  id: number;

  // Fields
  home: Home; // = new Home();
  position: number;
  description: string;
  plan: number;
  x: number;
  y: number;
  angle: number;
  file: string;
  before: any;
  beforetype: string;
  beforename: string;
  beforebytes: any;
  after: any;
  aftertype: string;
  aftername: string;
  afterbytes: any;
  option1: any;
  option1type: string;
  option1name: string;
  option1bytes: any;
  option2: any;
  option2type: string;
  option2name: string;
  option2bytes: any;
  option3: any;
  option3type: string;
  option3name: string;
  option3bytes: any;

  // Audit
  audit : Audit = new Audit();
}

