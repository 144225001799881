<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.TASK'|translate" [optional]="'LABEL.ENTITY.TASK.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="taskForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.TASK'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('contact')">
              <label class="control-label" for="contact">
                {{ 'LABEL.FIELD.TASK.CONTACT'|translate }}
              </label>
              <div class="input-group">
                <input
                  formControlName="contact"
                  typeaheadOptionField="email"
                  [typeaheadMinLength]="0"
                  [typeaheadAsync]="true"
                  [typeahead]="contactoptions"
                  (typeaheadOnSelect)="contactOnSelected($event)"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.TASK.CONTACT':'')|translate }}"
                  class="form-control">
                  <span class="input-group-addon">
                    <i *ngIf="data.contact?.id" class="fa fa-search" [routerLink]="['/contacts', data.contact.id, 'view']"></i>
                    <i *ngIf="!data.contact?.id" class="fa fa-search"></i>
                  </span>
                </div>
              <span class="help-block">{{err('contact')?.msg|translate:err('contact')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (2) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('tasktype')">
              <label class="control-label" for="tasktype">
                {{ 'LABEL.FIELD.TASK.TASKTYPE'|translate }}
              </label>
              <ng-select 
                formControlName="tasktype"
                [items]="tasktypeoptions"
                bindLabel="name"
                bindValue="id"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.TASK.TASKTYPE':'')|translate }}"
                style="width: 100%;">
              </ng-select>
              <span class="help-block">{{err('tasktype')?.msg|translate:err('tasktype')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (3) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('details')">
              <label class="control-label" for="details">
                {{ 'LABEL.FIELD.TASK.DETAILS'|translate }}
              </label>
              <textarea
                formControlName="details"
                autogrow [maximumHeight]="300" [minimumHeight]="60"
                (keydown.enter)="$event.stopPropagation();"
                maxlength="4000"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.TASK.DETAILS':'')|translate }}"
                class="form-control" autocomplete="off">
              </textarea>
              <span class="help-block">{{err('details')?.msg|translate:err('details')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (4) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('createdon')">
              <label class="control-label" for="createdon">
                {{ 'LABEL.FIELD.TASK.CREATEDON'|translate }}
              </label>
              <input
                formControlName="createdon"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.TASK.CREATEDON':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('createdon')?.msg|translate:err('createdon')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (5) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('plandate')">
              <label class="control-label" for="plandate">
                {{ 'LABEL.FIELD.TASK.PLANDATE'|translate }}
              </label>
              <div class="input-group">
              <input
                formControlName="plandate"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.TASK.PLANDATE':'')|translate }}"
                type="text"
                bsDatepicker
                [bsConfig]="{containerClass:'theme-dark-blue'}"
                #plandate="bsDatepicker"
                class="form-control" autocomplete="off" >
              <div class="input-group-addon" (click)="plandate.toggle()"><i class="fa fa-calendar"></i></div>
              </div> <!-- End input group -->
              <span class="help-block">{{err('plandate')?.msg|translate:err('plandate')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (6) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('donedate')">
              <label class="control-label" for="donedate">
                {{ 'LABEL.FIELD.TASK.DONEDATE'|translate }}
              </label>
              <div class="input-group">
              <input
                formControlName="donedate"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.TASK.DONEDATE':'')|translate }}"
                type="text"
                bsDatepicker
                [bsConfig]="{containerClass:'theme-dark-blue'}"
                #donedate="bsDatepicker"
                class="form-control" autocomplete="off" >
              <div class="input-group-addon" (click)="donedate.toggle()"><i class="fa fa-calendar"></i></div>
              </div> <!-- End input group -->
              <span class="help-block">{{err('donedate')?.msg|translate:err('donedate')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="taskForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:TASK')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="taskForm.invalid || taskForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:TASK')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:TASK')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
