<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.FLOORPLAN'|translate" [optional]="'LABEL.ENTITY.FLOORPLAN.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="floorplanForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.FLOORPLAN'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('position')">
                <label class="control-label" for="position">
                  {{ 'LABEL.FIELD.FLOORPLAN.POSITION'|translate }}
                </label>
                <input
                  formControlName="position"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.FLOORPLAN.POSITION':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('position')?.msg|translate:err('position')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-10">
              <div class="form-group" [class.has-error]="err('floor')">
                <label class="control-label" for="floor">
                  {{ 'LABEL.FIELD.FLOORPLAN.FLOOR'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="floor"
                  maxlength="50"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.FLOORPLAN.FLOOR':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('floor')?.msg|translate:err('floor')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('after')">
                <label class="control-label" for="after">
                  {{ 'LABEL.FIELD.FLOORPLAN.AFTER'|translate }}
                </label>
                &nbsp;<i (click)="onFileDelete('after', $event)" class="fa fa-trash"></i>
                <input *ngIf="mode!='view'"
                  (change)="onFileChange('after', $event)"
                  type="file"
                  class="form-control" autocomplete="off" style="margin-bottom:10px;">
                <div *ngIf="mode!='add' && !data?.afterFile">
                  <div *ngIf="data?.aftertype?.startsWith('image/')" style="margin-bottom:10px;">
                    <!--<img width="120px" src="data:{{data.aftertype}};base64,{{data.afterBytes}}">-->
                    <img [hidden]="!afterLoaded" (load)="afterLoaded=true" width="120px" [src]="globals.server + 'floorplans/' + data.id + '/after' | secure | async">
                  </div>
                  <!--<a *ngIf="data?.aftertype" href="{{ globals.server }}floorplans/{{ data.id }}/after" type="{{ data.aftertype }}">-->
                  <a *ngIf="data?.aftertype" target="_blank" [href]="globals.server + 'floorplans/' + data.id + '/after' | secure | async" type="{{ data.aftertype }}">
                    <i [class]="'fa fa-lg ' + mimeIcon(data?.aftertype)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
                  </a>
                </div>
                <span class="help-block">{{err('after')?.msg|translate:err('after')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="floorplanForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:FLOORPLAN')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="floorplanForm.invalid || floorplanForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:FLOORPLAN')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:FLOORPLAN')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->


    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
