import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-search',
  templateUrl: './sidebar-search.component.html'
})
export class SidebarSearchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
