// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Close } from 'app/crud/valuator/close/close';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class CloseService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all closes
  readAll(sort: string): Observable<Page<Close>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of closes
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Close>> {
    let url = `${this.globals.server}closes?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Close>>(url).pipe(
      // tap(_ => console.log('read closes')),
      catchError(this.handleError<Page<Close>>('read Close'))
    );
  }

  // Read one close
  readOne(reference: string): Observable<Close> {
    return this.http.get<Close>(`${this.globals.server}closes/${reference}`).pipe(
      // tap(_ => console.log(`readOne close ${reference} `)),
      catchError(this.handleError<Close>(`readOne Close ${reference} `))
    );
  }

  // Count closes
  count(): Observable<Close[]> {
    return this.http.get<Close[]>(`${this.globals.server}closes/count`).pipe(
      // tap(_ => console.log('count closes')),
      catchError(this.handleError('count Close', []))
    );
  }

  // Create close
  create(o: Close): Observable<Close> {
    return this.http.post<Close>(`${this.globals.server}closes`, o, httpOptions).pipe(
      // tap(_ => console.log('create close')),
      catchError(this.handleError<Close>('create Close'))
    );
  }

  // Update close
  update(reference: string, o: Close): Observable<any> {
    return this.http.put(`${this.globals.server}closes/${reference}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update close ${reference} `)),
      catchError(this.handleError<any>(`update Close ${reference} `))
    );
  }

  // Delete close
  delete(reference: string): Observable<Close> {
    return this.http.delete<Close>(`${this.globals.server}closes/${reference}`, httpOptions).pipe(
      // tap(_ => console.log(`delete close ${reference} `)),
      catchError(this.handleError<Close>(`delete Close ${reference} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
