// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Task } from 'app/crud/customers/task/task';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class TaskService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all tasks
  readAll(sort: string): Observable<Page<Task>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of tasks
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Task>> {
    let url = `${this.globals.server}tasks?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Task>>(url).pipe(
      // tap(_ => console.log('read tasks')),
      catchError(this.handleError<Page<Task>>('read Task'))
    );
  }

  // Read pages of tasks from contacts, contacts cannot have composite keys
  readFromContact(contact: number, page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Task>> {
    let url = `${this.globals.server}contacts/${contact}/tasks?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Task>>(url).pipe(
      // tap(_ => console.log('read tasks')),
      catchError(this.handleError<Page<Task>>('read Task'))
    );
  }

  // Read one task
  readOne(id: number): Observable<Task> {
    return this.http.get<Task>(`${this.globals.server}tasks/${id}`).pipe(
      // tap(_ => console.log(`readOne task ${id} `)),
      catchError(this.handleError<Task>(`readOne Task ${id} `))
    );
  }

  // Count tasks
  count(): Observable<Task[]> {
    return this.http.get<Task[]>(`${this.globals.server}tasks/count`).pipe(
      // tap(_ => console.log('count tasks')),
      catchError(this.handleError('count Task', []))
    );
  }

  // Create task
  create(o: Task): Observable<Task> {
    return this.http.post<Task>(`${this.globals.server}tasks`, o, httpOptions).pipe(
      // tap(_ => console.log('create task')),
      catchError(this.handleError<Task>('create Task'))
    );
  }

  // Update task
  update(id: number, o: Task): Observable<any> {
    return this.http.put(`${this.globals.server}tasks/${id}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update task ${id} `)),
      catchError(this.handleError<any>(`update Task ${id} `))
    );
  }

  // Delete task
  delete(id: number): Observable<Task> {
    return this.http.delete<Task>(`${this.globals.server}tasks/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete task ${id} `)),
      catchError(this.handleError<Task>(`delete Task ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
