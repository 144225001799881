<ul *ngIf="session.menu" class="sidebar-menu" data-widget="tree">

  <!-- Menu title -->
  <li class="header">{{ title | uppercase }}</li>

  <!-- Each menu entry -->
  <li routerLinkActive="active" class="treeview" *ngFor="let item of session.menu">

    <!-- Link -->
    <a [id]="item.code" [routerLink]="item.route" *ngIf="auth.canShow('VIEW:'+item.code)">
      <i class="fa" [ngClass]="item.icon"></i>
      <span>{{ item.name | translate }}</span>
      <span *ngIf="item.menu != undefined" class="pull-right-container"><i class="fa fa-angle-left pull-right"></i></span>
    </a>

    <!-- Submenu items-->
    <ul class="treeview-menu" *ngIf="item.menu!=undefined && auth.canShow('VIEW:'+item.code)" >

      <!-- Each sub menu entry -->
      <li routerLinkActive="active" *ngFor="let subitem of item.menu">
        <a [id]="subitem.code" [routerLink]="subitem.route" *ngIf="auth.canShow('VIEW:'+subitem.code)">
          <i class="fa" [ngClass]="subitem.icon"></i>
          <span>{{ subitem.name | translate }}</span>
        </a>
      </li>
      
    </ul>
  </li>

</ul>