import { House } from 'app/crud/valuator/house/house';
import { Audit } from 'app/shared/model/audit';

// History class
export class History {

  // Key
  hash: string;

  // Fields
  house: House; // = new House();
  batch: number;
  price: number;
  oldprice: number;
  intext: string;
  lift: number;
  area: number;
  rooms: number;

  // Audit
  audit : Audit = new Audit();
}

