// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { FloorPlan } from 'app/crud/houses/floorplan/floorplan';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class FloorPlanService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all floorplans
  readAll(sort: string): Observable<Page<FloorPlan>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of floorplans
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<FloorPlan>> {
    let url = `${this.globals.server}floorplans?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<FloorPlan>>(url).pipe(
      // tap(_ => console.log('read floorplans')),
      catchError(this.handleError<Page<FloorPlan>>('read FloorPlan'))
    );
  }

  // Read pages of floorplans from homes, homes cannot have composite keys
  readFromHome(home: string, page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<FloorPlan>> {
    let url = `${this.globals.server}homes/${home}/floorplans?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<FloorPlan>>(url).pipe(
      // tap(_ => console.log('read floorplans')),
      catchError(this.handleError<Page<FloorPlan>>('read FloorPlan'))
    );
  }

  // Read one floorplan
  readOne(id: number): Observable<FloorPlan> {
    return this.http.get<FloorPlan>(`${this.globals.server}floorplans/${id}`).pipe(
      // tap(_ => console.log(`readOne floorplan ${id} `)),
      catchError(this.handleError<FloorPlan>(`readOne FloorPlan ${id} `))
    );
  }

  // Count floorplans
  count(): Observable<FloorPlan[]> {
    return this.http.get<FloorPlan[]>(`${this.globals.server}floorplans/count`).pipe(
      // tap(_ => console.log('count floorplans')),
      catchError(this.handleError('count FloorPlan', []))
    );
  }

  // Create floorplan
  create(o: FloorPlan): Observable<FloorPlan> {
    const input = new FormData();
    if (o.before) { input.append('before', o.before, o.beforename); }
    delete o.before;
    if (o.after) { input.append('after', o.after, o.aftername); }
    delete o.after;
    input.append('data', JSON.stringify(o));
    return this.http.post<FloorPlan>(`${this.globals.server}floorplans`, input).pipe(
      // tap(_ => console.log('create floorplan')),
      catchError(this.handleError<FloorPlan>('create FloorPlan'))
    );
  }

  // Update floorplan
  update(id: number, o: FloorPlan): Observable<any> {
    const input = new FormData();
    if (o.before) { input.append('before', o.before, o.beforename); }
    delete o.before;
    if (o.after) { input.append('after', o.after, o.aftername); }
    delete o.after;
    input.append('data', JSON.stringify(o));
    return this.http.put(`${this.globals.server}floorplans/${id}`, input).pipe(
      // tap(_ => console.log(`update floorplan ${id} `)),
      catchError(this.handleError<any>(`update FloorPlan ${id} `))
    );
  }

  // Delete floorplan
  delete(id: number): Observable<FloorPlan> {
    return this.http.delete<FloorPlan>(`${this.globals.server}floorplans/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete floorplan ${id} `)),
      catchError(this.handleError<FloorPlan>(`delete FloorPlan ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
