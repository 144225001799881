import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap';

import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';
import { SessionService } from 'app/shared/services/session.service';
import { Page } from 'app/shared/model/page';

import { TaskService } from 'app/crud/customers/task/task.service';
import { Task } from 'app/crud/customers/task/task';

import { ContactService } from 'app/crud/customers/contact/contact.service';
import { Contact } from 'app/crud/customers/contact/contact';
import { TasktypeService } from 'app/crud/customers/tasktype/tasktype.service';
import { Tasktype } from 'app/crud/customers/tasktype/tasktype';

@Component({
  selector: 'app-task-table',
  templateUrl: './task-table.component.html',
})
export class TaskTableComponent implements OnInit {

  // Select fields
  contactoptions: Contact[] = [];
  tasktypeoptions: Tasktype[] = [];

  // Defalt form mode
  mode = 'edit';

  // Search fields
  search = {
    contact_id: '',
    contact_email: '',
    tasktype_id: '',
    tasktype_name: '',
    details: '',
    createdon: '',
    plandate: '',
    donedate: '',
  };

  // Current page
  page = 0;

  // Sort field and direction
  sort = 'contact';
  dir = '+';

  // Current filter
  filter = '';

  // One page of data
  data: Page<Task>;

  // Loading indicator
  isLoading = true;

  // Event debouncer
  debounce: any;

  constructor(
    private apiContact: ContactService,
    private apiTasktype: TasktypeService,
    private api: TaskService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private session: SessionService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
      if (this.session.search.form == 'Task') {
        this.search = this.session.search.data;
        this.sort = this.session.search.sort;
        this.dir = this.session.search.dir;
        this.setFilter();  
      }
    }

  ngOnInit() {
    // Mode, if sub-table
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Load contact lookup
    this.loadContact();

    // Load tasktype lookup
    this.loadTasktype();

    // Loads the table
    this.load();
  }

  // Loads the table
  load() {
    this.isLoading = true;
    this.api.read(this.page, this.dir + this.sort, this.filter).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('read', 'TASK', err);
      }
    );
  }

  isSorted(field: string) {
    if (field === this.sort && this.dir === '-') {
      return 'sorting_desc';
    } else if (field === this.sort && this.dir === '+') {
      return 'sorting_asc';
    }
    return 'sorting';
  }

  doPage(page: number) {
    // Change page
    this.page = page;
    this.load();
  }

  doSort(field: string) {
    // Not sorted? Sort asc
    if (this.sort !== field) {
      if (field === 'createdon' || field === 'updatedon')
      	this.sort = 'audit.' + field;
      else
      	this.sort = field;
      this.dir = '+';
    } else {
      // Sorted asc? Sort desc
      if (this.dir === '+') {
        this.dir = '-';
      } else {
        // Else do not sort
        this.sort = '';
        this.dir = '';
      }
    }

    // Save sort
    this.session.search.form = 'Task';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;

    // Reload
    this.load();
  }

  setFilter() {
    // Filter string
    const f: string[] = [];
    Globals.pushFilter(f, 'contact.id', this.search.contact_id, 'eq', this.session.lang.code, 'Long');
    Globals.pushFilter(f, 'contact.email', this.search.contact_email, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'tasktype.id', this.search.tasktype_id, 'eq', this.session.lang.code, 'Long');
    Globals.pushFilter(f, 'tasktype.name', this.search.tasktype_name, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'details', this.search.details, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'audit.createdon', this.search.createdon, 'eq', this.session.lang.code, 'Date');
    Globals.pushFilter(f, 'plandate', this.search.plandate, 'eq', this.session.lang.code, 'LocalDate');
    Globals.pushFilter(f, 'donedate', this.search.donedate, 'eq', this.session.lang.code, 'LocalDate');
    this.filter = Globals.encode(f.join(','));

    // Save filter
    this.session.search.form = 'Task';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;
  }

  doFilter(event: any) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.setFilter();
      this.page = 0;
      this.load();
    }, 400);
  }

  // Clear filter
  clearFilter() {
    // Clear filter string
    this.search.contact_id = '';
    this.search.contact_email = '';
    this.search.tasktype_id = '';
    this.search.tasktype_name = '';
    this.search.details = '';
    this.search.createdon = '';
    this.search.plandate = '';
    this.search.donedate = '';

    // Clear
    this.session.search.data = this.search;

    // Search
    this.page = 0;
    this.filter = '';
    this.load();
  }

  // Load contact lookup
  loadContact() {
    this.apiContact.readAll('').subscribe(
      res => {
        this.contactoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'CONTACT', err);
      }
    );
  }

  // Load tasktype lookup
  loadTasktype() {
    this.apiTasktype.readAll('').subscribe(
      res => {
        this.tasktypeoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'TASKTYPE', err);
      }
    );
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
