// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Photo } from 'app/crud/houses/photo/photo';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all photos
  readAll(sort: string): Observable<Page<Photo>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of photos
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Photo>> {
    let url = `${this.globals.server}photos?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Photo>>(url).pipe(
      // tap(_ => console.log('read photos')),
      catchError(this.handleError<Page<Photo>>('read Photo'))
    );
  }

  // Read pages of photos from homes, homes cannot have composite keys
  readFromHome(home: string, page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Photo>> {
    let url = `${this.globals.server}homes/${home}/photos?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Photo>>(url).pipe(
      // tap(_ => console.log('read photos')),
      catchError(this.handleError<Page<Photo>>('read Photo'))
    );
  }

  // Read one photo
  readOne(id: number): Observable<Photo> {
    return this.http.get<Photo>(`${this.globals.server}photos/${id}`).pipe(
      // tap(_ => console.log(`readOne photo ${id} `)),
      catchError(this.handleError<Photo>(`readOne Photo ${id} `))
    );
  }

  // Count photos
  count(): Observable<Photo[]> {
    return this.http.get<Photo[]>(`${this.globals.server}photos/count`).pipe(
      // tap(_ => console.log('count photos')),
      catchError(this.handleError('count Photo', []))
    );
  }

  // Create photo
  create(o: Photo): Observable<Photo> {
    const input = new FormData();
    if (o.before) { input.append('before', o.before, o.beforename); }
    delete o.before;
    if (o.after) { input.append('after', o.after, o.aftername); }
    delete o.after;
    if (o.option1) { input.append('option1', o.option1, o.option1name); }
    delete o.option1;
    if (o.option2) { input.append('option2', o.option2, o.option2name); }
    delete o.option2;
    if (o.option3) { input.append('option3', o.option3, o.option3name); }
    delete o.option3;
    input.append('data', JSON.stringify(o));
    return this.http.post<Photo>(`${this.globals.server}photos`, input).pipe(
      // tap(_ => console.log('create photo')),
      catchError(this.handleError<Photo>('create Photo'))
    );
  }

  // Update photo
  update(id: number, o: Photo): Observable<any> {
    const input = new FormData();
    if (o.before) { input.append('before', o.before, o.beforename); }
    delete o.before;
    if (o.after) { input.append('after', o.after, o.aftername); }
    delete o.after;
    if (o.option1) { input.append('option1', o.option1, o.option1name); }
    delete o.option1;
    if (o.option2) { input.append('option2', o.option2, o.option2name); }
    delete o.option2;
    if (o.option3) { input.append('option3', o.option3, o.option3name); }
    delete o.option3;
    input.append('data', JSON.stringify(o));
    return this.http.put(`${this.globals.server}photos/${id}`, input).pipe(
      // tap(_ => console.log(`update photo ${id} `)),
      catchError(this.handleError<any>(`update Photo ${id} `))
    );
  }

  // Delete photo
  delete(id: number): Observable<Photo> {
    return this.http.delete<Photo>(`${this.globals.server}photos/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete photo ${id} `)),
      catchError(this.handleError<Photo>(`delete Photo ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
