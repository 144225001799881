import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Language } from 'app/shared/model/language';
import { Globals } from './globals.service';
import { Menu } from '../model/menu';
import { Login } from '../model/login';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  // Current user menu
  menu: Menu[] = [];

  // Saved filters
  search = {
    form: '',
    data: null,
    sort: '',
    dir: '+'
  }

  // Saved sort

  // Current user language
  private l: Language;

  constructor(
    private globals: Globals,
    private translate: TranslateService) {

    console.log('Session created');

    // Set default language
    this.l = this.globals.langs[0];
  }

  get lang(): Language {
    return this.l;
  }

  setLang(value: string) {
    this.l = this.globals.langs.find( (x) => x.code === value );
    this.translate.use(this.l.code);
    console.log(`Language '${value}' set`);
  }

}
