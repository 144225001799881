<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.HOME'|translate" [optional]="'LABEL.ENTITY.HOME.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="homeForm" focus>

    <!-- Tabbed panel-->
    <div class="nav-tabs-custom">

      <!-- Tabs labels -->
      <ul class="nav nav-tabs">
        <li class="pull-right header">{{ 'LABEL.ENTITY.HOME'|translate }}</li>
        <li class="active">
          <a href="#tabinformation" data-toggle="tab">{{ 'LABEL.TAB.HOME.INFORMATION'|translate }}</a>
        </li>
        <li>
          <a href="#tabfeatures" data-toggle="tab">{{ 'LABEL.TAB.HOME.FEATURES'|translate }}</a>
        </li>
      </ul> <!-- end Tabbed panel -->

      <!-- Tabs content -->
      <div class="tab-content">

      <!-- Tab information -->
      <div class="active tab-pane" id="tabinformation">

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.HOME.GENERAL'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('code')">
                <label class="control-label" for="code">
                  {{ 'LABEL.FIELD.HOME.CODE'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="code"
                  maxlength="10"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.CODE':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('code')?.msg|translate:err('code')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('city')">
                <label class="control-label" for="city">
                  {{ 'LABEL.FIELD.HOME.CITY'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <ng-select 
                  formControlName="city"
                  clearable="false"
                  [items]="cityoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.CITY':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('city')?.msg|translate:err('city')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('hometype')">
                <label class="control-label" for="hometype">
                  {{ 'LABEL.FIELD.HOME.HOMETYPE'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <ng-select 
                  formControlName="hometype"
                  clearable="false"
                  [items]="hometypeoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.HOMETYPE':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('hometype')?.msg|translate:err('hometype')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('active')">
                <label class="control-label" for="active">
                  {{ 'LABEL.FIELD.HOME.ACTIVE'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <div class="checkbox">
                  <input
                    formControlName="active" id="active" 
                    type="checkbox">
                  <label for="active">{{ 'LABEL.FIELD.HOME.ACTIVE'|translate }}</label>
                </div>
                <span class="help-block">{{err('active')?.msg|translate:err('active')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('web')">
                <label class="control-label" for="web">
                  {{ 'LABEL.FIELD.HOME.WEB'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <div class="checkbox">
                  <input
                    formControlName="web" id="web" 
                    type="checkbox">
                  <label for="web">{{ 'LABEL.FIELD.HOME.WEB'|translate }}</label>
                </div>
                <span class="help-block">{{err('web')?.msg|translate:err('web')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('sold')">
                <label class="control-label" for="sold">
                  {{ 'LABEL.FIELD.HOME.SOLD'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <div class="checkbox">
                  <input
                    formControlName="sold" id="sold" 
                    type="checkbox">
                  <label for="sold">{{ 'LABEL.FIELD.HOME.SOLD'|translate }}</label>
                </div>
                <span class="help-block">{{err('sold')?.msg|translate:err('sold')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('refcat')">
                <label class="control-label" for="refcat">
                  {{ 'LABEL.FIELD.HOME.REFCAT'|translate }}
                </label>
                <input
                  formControlName="refcat"
                  maxlength="20"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.REFCAT':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('refcat')?.msg|translate:err('refcat')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('company')">
                <label class="control-label" for="company">
                  {{ 'LABEL.FIELD.HOME.COMPANY'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <ng-select 
                  formControlName="company"
                  clearable="false"
                  [items]="companyoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.COMPANY':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('company')?.msg|translate:err('company')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('addprice')">
                <label class="control-label" for="addprice">
                  {{ 'LABEL.FIELD.HOME.ADDPRICE'|translate }}
                </label>
                <div class="checkbox">
                  <input
                    formControlName="addprice" id="addprice" 
                    type="checkbox">
                  <label for="addprice">{{ 'LABEL.FIELD.HOME.ADDPRICE'|translate }}</label>
                </div>
                <span class="help-block">{{err('addprice')?.msg|translate:err('addprice')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('sellprice')">
                <label class="control-label" for="sellprice">
                  {{ 'LABEL.FIELD.HOME.SELLPRICE'|translate }}
                </label>
                <input
                  formControlName="sellprice"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.SELLPRICE':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('sellprice')?.msg|translate:err('sellprice')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('renoprice')">
                <label class="control-label" for="renoprice">
                  {{ 'LABEL.FIELD.HOME.RENOPRICE'|translate }}
                </label>
                <input
                  formControlName="renoprice"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.RENOPRICE':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('renoprice')?.msg|translate:err('renoprice')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('address')">
                <label class="control-label" for="address">
                  {{ 'LABEL.FIELD.HOME.ADDRESS'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="address"
                  maxlength="100"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.ADDRESS':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('address')?.msg|translate:err('address')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('num')">
                <label class="control-label" for="num">
                  {{ 'LABEL.FIELD.HOME.NUM'|translate }}
                </label>
                <input
                  formControlName="num"
                  maxlength="10"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.NUM':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('num')?.msg|translate:err('num')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('lat')">
                <label class="control-label" for="lat">
                  {{ 'LABEL.FIELD.HOME.LAT'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="lat"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.LAT':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('lat')?.msg|translate:err('lat')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('lng')">
                <label class="control-label" for="lng">
                  {{ 'LABEL.FIELD.HOME.LNG'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="lng"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.LNG':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('lng')?.msg|translate:err('lng')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('aproxaddress')">
                <label class="control-label" for="aproxaddress">
                  {{ 'LABEL.FIELD.HOME.APROXADDRESS'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="aproxaddress"
                  maxlength="100"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.APROXADDRESS':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('aproxaddress')?.msg|translate:err('aproxaddress')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.HOME.DETAILS'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('description')">
                <label class="control-label" for="description">
                  {{ 'LABEL.FIELD.HOME.DESCRIPTION'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <textarea
                  formControlName="description"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="2000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.DESCRIPTION':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('description')?.msg|translate:err('description')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('details')">
                <label class="control-label" for="details">
                  {{ 'LABEL.FIELD.HOME.DETAILS'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <textarea
                  formControlName="details"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="5000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.DETAILS':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('details')?.msg|translate:err('details')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('squaremeters')">
                <label class="control-label" for="squaremeters">
                  {{ 'LABEL.FIELD.HOME.SQUAREMETERS'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="squaremeters"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.SQUAREMETERS':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('squaremeters')?.msg|translate:err('squaremeters')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('bedrooms')">
                <label class="control-label" for="bedrooms">
                  {{ 'LABEL.FIELD.HOME.BEDROOMS'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="bedrooms"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.BEDROOMS':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('bedrooms')?.msg|translate:err('bedrooms')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('bathrooms')">
                <label class="control-label" for="bathrooms">
                  {{ 'LABEL.FIELD.HOME.BATHROOMS'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="bathrooms"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.BATHROOMS':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('bathrooms')?.msg|translate:err('bathrooms')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('garage')">
                <label class="control-label" for="garage">
                  {{ 'LABEL.FIELD.HOME.GARAGE'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="garage"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.GARAGE':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('garage')?.msg|translate:err('garage')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

      </div> <!-- end Tab -->
      <!-- Tab features -->
      <div class="tab-pane" id="tabfeatures">

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('image')">
                <label class="control-label" for="image">
                  {{ 'LABEL.FIELD.HOME.IMAGE'|translate }}
                </label>
                &nbsp;<i (click)="onFileDelete('image', $event)" class="fa fa-trash"></i>
                <input *ngIf="mode!='view'"
                  (change)="onFileChange('image', $event)"
                  type="file"
                  class="form-control" autocomplete="off" style="margin-bottom:10px;">
                <div *ngIf="mode!='add' && !data?.imageFile">
                  <div *ngIf="data?.imagetype?.startsWith('image/')" style="margin-bottom:10px;">
                    <!--<img width="120px" src="data:{{data.imagetype}};base64,{{data.imageBytes}}">-->
                    <img [hidden]="!imageLoaded" (load)="imageLoaded=true" width="120px" [src]="globals.server + 'homes/' + data.code + '/image' | secure | async">
                  </div>
                  <!--<a *ngIf="data?.imagetype" href="{{ globals.server }}homes/{{ data.code }}/image" type="{{ data.imagetype }}">-->
                  <a *ngIf="data?.imagetype" target="_blank" [href]="globals.server + 'homes/' + data.code + '/image' | secure | async" type="{{ data.imagetype }}">
                    <i [class]="'fa fa-lg ' + mimeIcon(data?.imagetype)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
                  </a>
                </div>
                <span class="help-block">{{err('image')?.msg|translate:err('image')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('features')">
                <label class="control-label" for="features">
                  {{ 'LABEL.FIELD.HOME.FEATURES'|translate }}
                </label>
                <textarea
                  formControlName="features"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="1000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.FEATURES':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('features')?.msg|translate:err('features')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('equipment')">
                <label class="control-label" for="equipment">
                  {{ 'LABEL.FIELD.HOME.EQUIPMENT'|translate }}
                </label>
                <textarea
                  formControlName="equipment"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="1000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.EQUIPMENT':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('equipment')?.msg|translate:err('equipment')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('building')">
                <label class="control-label" for="building">
                  {{ 'LABEL.FIELD.HOME.BUILDING'|translate }}
                </label>
                <textarea
                  formControlName="building"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="1000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.BUILDING':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('building')?.msg|translate:err('building')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('pricing')">
                <label class="control-label" for="pricing">
                  {{ 'LABEL.FIELD.HOME.PRICING'|translate }}
                </label>
                <textarea
                  formControlName="pricing"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="1000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.HOME.PRICING':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('pricing')?.msg|translate:err('pricing')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

      </div> <!-- end Tab -->
<!-- Button bar -->
<div class="box-footer">
  <div class="btn-grp">
    <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
    <button [disabled]="homeForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:HOME')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
    <button [disabled]="homeForm.invalid || homeForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:HOME')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
    <button *ngIf="mode=='edit' && auth.canShow('DELETE:HOME')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
  </div>
</div> <!-- end Button bar -->
      </div> <!-- end Tabs content -->

    </div> <!-- end Tabbed panel -->


    </form>
        <!-- Tabbed panel-->
        <div class="nav-tabs-custom">

          <!-- Tabs labels -->
          <ul class="nav nav-tabs">
            <li class="active">
              <a href="#tabPhoto" data-toggle="tab">{{ 'LABEL.ENTITY.PHOTO'|translate }}</a>
            </li>
            <li>
              <a href="#tabFloorPlan" data-toggle="tab">{{ 'LABEL.ENTITY.FLOORPLAN'|translate }}</a>
            </li>
            <li>
              <a href="#tabDocument" data-toggle="tab">{{ 'LABEL.ENTITY.DOCUMENT'|translate }}</a>
            </li>
            <li>
              <a href="#tabItems" data-toggle="tab">{{ 'LABEL.ENTITY.ITEMS'|translate }}</a>
            </li>
          </ul>

            <!-- Tabs content -->
            <div class="tab-content">

            <!-- Tab -->
            <div class="active tab-pane" id="tabPhoto">
              <div class="box-body">
              <app-photo-home-table *ngIf="mode!='add' && data.code" 
                parent="homes" 
                [value]="data.code">
              </app-photo-home-table>
              </div>
            </div> <!-- end tab -->
    
            <!-- Tab -->
            <div class=" tab-pane" id="tabFloorPlan">
              <div class="box-body">
              <app-floorplan-home-table *ngIf="mode!='add' && data.code" 
                parent="homes" 
                [value]="data.code">
              </app-floorplan-home-table>
              </div>
            </div> <!-- end tab -->
    
            <!-- Tab -->
            <div class=" tab-pane" id="tabDocument">
              <div class="box-body">
              <app-document-home-table *ngIf="mode!='add' && data.code" 
                parent="homes" 
                [value]="data.code">
              </app-document-home-table>
              </div>
            </div> <!-- end tab -->
    
            <!-- Tab -->
            <div class=" tab-pane" id="tabItems">
              <div class="box-body">
              <app-items-home-table *ngIf="mode!='add' && data.code" 
                parent="homes" 
                [value]="data.code">
              </app-items-home-table>
              </div>
            </div> <!-- end tab -->
    
            </div> <!-- end tab-content -->

        </div>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
