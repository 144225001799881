import { Audit } from 'app/shared/model/audit';

// Company class
export class Company {

  // Key
  id: number;

  // Fields
  name: string;
  address: string;
  phone: string;
  web: string;
  logo: any;
  logotype: string;
  logoname: string;
  logobytes: any;
  addprice: string;

  // Audit
  audit : Audit = new Audit();
}

