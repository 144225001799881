// Angular and infastructure imports
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Security
import { AuthService } from 'app/shared/services/auth.service';

// Layouts
import { FramedLayoutComponent } from 'app/layout/framed/framed.component';

// Crud components
import { HomeTableComponent } from 'app/crud/houses/home/home-table.component';
import { HomeFormComponent } from 'app/crud/houses/home/home-form.component';
import { CompanyTableComponent } from 'app/crud/houses/company/company-table.component';
import { CompanyFormComponent } from 'app/crud/houses/company/company-form.component';
import { CityTableComponent } from 'app/crud/houses/city/city-table.component';
import { CityFormComponent } from 'app/crud/houses/city/city-form.component';
import { HomeTypeTableComponent } from 'app/crud/houses/hometype/hometype-table.component';
import { HomeTypeFormComponent } from 'app/crud/houses/hometype/hometype-form.component';
import { PhotoHomeTableComponent } from 'app/crud/houses/photo/photo-home-table.component';
import { PhotoHomeFormComponent } from 'app/crud/houses/photo/photo-home-form.component';
import { FloorPlanHomeTableComponent } from 'app/crud/houses/floorplan/floorplan-home-table.component';
import { FloorPlanHomeFormComponent } from 'app/crud/houses/floorplan/floorplan-home-form.component';
import { DocumentHomeTableComponent } from 'app/crud/houses/document/document-home-table.component';
import { DocumentHomeFormComponent } from 'app/crud/houses/document/document-home-form.component';
import { ItemsHomeTableComponent } from 'app/crud/houses/items/items-home-table.component';
import { ItemsHomeFormComponent } from 'app/crud/houses/items/items-home-form.component';
import { ContactTableComponent } from 'app/crud/customers/contact/contact-table.component';
import { ContactFormComponent } from 'app/crud/customers/contact/contact-form.component';
import { ValuationTableComponent } from 'app/crud/customers/valuation/valuation-table.component';
import { ValuationFormComponent } from 'app/crud/customers/valuation/valuation-form.component';
import { ValuationContactTableComponent } from 'app/crud/customers/valuation/valuation-contact-table.component';
import { ValuationContactFormComponent } from 'app/crud/customers/valuation/valuation-contact-form.component';
import { InteractionContactTableComponent } from 'app/crud/customers/interaction/interaction-contact-table.component';
import { InteractionContactFormComponent } from 'app/crud/customers/interaction/interaction-contact-form.component';
import { TaskTableComponent } from 'app/crud/customers/task/task-table.component';
import { TaskFormComponent } from 'app/crud/customers/task/task-form.component';
import { TaskContactTableComponent } from 'app/crud/customers/task/task-contact-table.component';
import { TaskContactFormComponent } from 'app/crud/customers/task/task-contact-form.component';
import { NoteContactTableComponent } from 'app/crud/customers/note/note-contact-table.component';
import { NoteContactFormComponent } from 'app/crud/customers/note/note-contact-form.component';
import { SourceTableComponent } from 'app/crud/customers/source/source-table.component';
import { SourceFormComponent } from 'app/crud/customers/source/source-form.component';
import { TasktypeTableComponent } from 'app/crud/customers/tasktype/tasktype-table.component';
import { TasktypeFormComponent } from 'app/crud/customers/tasktype/tasktype-form.component';
import { ShopTableComponent } from 'app/crud/decoration/shop/shop-table.component';
import { ShopFormComponent } from 'app/crud/decoration/shop/shop-form.component';
import { CategoryTableComponent } from 'app/crud/decoration/category/category-table.component';
import { CategoryFormComponent } from 'app/crud/decoration/category/category-form.component';
import { FurnitureTableComponent } from 'app/crud/decoration/furniture/furniture-table.component';
import { FurnitureFormComponent } from 'app/crud/decoration/furniture/furniture-form.component';
import { ProvinceTableComponent } from 'app/crud/valuator/province/province-table.component';
import { ProvinceFormComponent } from 'app/crud/valuator/province/province-form.component';
import { TownTableComponent } from 'app/crud/valuator/town/town-table.component';
import { TownFormComponent } from 'app/crud/valuator/town/town-form.component';
import { HouseTableComponent } from 'app/crud/valuator/house/house-table.component';
import { HouseFormComponent } from 'app/crud/valuator/house/house-form.component';
import { HistoryHouseTableComponent } from 'app/crud/valuator/history/history-house-table.component';
import { HistoryHouseFormComponent } from 'app/crud/valuator/history/history-house-form.component';
import { ScanTableComponent } from 'app/crud/valuator/scan/scan-table.component';
import { ScanFormComponent } from 'app/crud/valuator/scan/scan-form.component';
import { CloseTableComponent } from 'app/crud/valuator/close/close-table.component';
import { CloseFormComponent } from 'app/crud/valuator/close/close-form.component';
import { PrincipalTableComponent } from 'app/crud/system/principal/principal-table.component';
import { PrincipalFormComponent } from 'app/crud/system/principal/principal-form.component';
import { PrivilegeRoleTableComponent } from 'app/crud/system/privilege/privilege-role-table.component';
import { PrivilegeRoleFormComponent } from 'app/crud/system/privilege/privilege-role-form.component';
import { GroupTableComponent } from 'app/crud/system/group/group-table.component';
import { GroupFormComponent } from 'app/crud/system/group/group-form.component';
import { RoleTableComponent } from 'app/crud/system/role/role-table.component';
import { RoleFormComponent } from 'app/crud/system/role/role-form.component';
import { ConfigTableComponent } from 'app/crud/system/config/config-table.component';
import { ConfigFormComponent } from 'app/crud/system/config/config-form.component';

const routes: Routes = [
  {
	path: '', component: FramedLayoutComponent, canActivate: [AuthService], children: [

	  { path: 'homes', canActivate: [AuthService], component: HomeTableComponent, data: { resource: 'HOME', mode: 'list' } },
	  { path: 'homes/add', canActivate: [AuthService], component: HomeFormComponent, data: { resource: 'HOME', mode: 'add' } },
	  { path: 'homes/:code/view', canActivate: [AuthService], component: HomeFormComponent, data: { resource: 'HOME', mode: 'view' } },
	  { path: 'homes/:code/edit', canActivate: [AuthService], component: HomeFormComponent, data: { resource: 'HOME', mode: 'edit' } },

	  { path: 'companies', canActivate: [AuthService], component: CompanyTableComponent, data: { resource: 'COMPANY', mode: 'list' } },
	  { path: 'companies/add', canActivate: [AuthService], component: CompanyFormComponent, data: { resource: 'COMPANY', mode: 'add' } },
	  { path: 'companies/:id/view', canActivate: [AuthService], component: CompanyFormComponent, data: { resource: 'COMPANY', mode: 'view' } },
	  { path: 'companies/:id/edit', canActivate: [AuthService], component: CompanyFormComponent, data: { resource: 'COMPANY', mode: 'edit' } },

	  { path: 'cities', canActivate: [AuthService], component: CityTableComponent, data: { resource: 'CITY', mode: 'list' } },
	  { path: 'cities/add', canActivate: [AuthService], component: CityFormComponent, data: { resource: 'CITY', mode: 'add' } },
	  { path: 'cities/:id/view', canActivate: [AuthService], component: CityFormComponent, data: { resource: 'CITY', mode: 'view' } },
	  { path: 'cities/:id/edit', canActivate: [AuthService], component: CityFormComponent, data: { resource: 'CITY', mode: 'edit' } },

	  { path: 'hometypes', canActivate: [AuthService], component: HomeTypeTableComponent, data: { resource: 'HOMETYPE', mode: 'list' } },
	  { path: 'hometypes/add', canActivate: [AuthService], component: HomeTypeFormComponent, data: { resource: 'HOMETYPE', mode: 'add' } },
	  { path: 'hometypes/:id/view', canActivate: [AuthService], component: HomeTypeFormComponent, data: { resource: 'HOMETYPE', mode: 'view' } },
	  { path: 'hometypes/:id/edit', canActivate: [AuthService], component: HomeTypeFormComponent, data: { resource: 'HOMETYPE', mode: 'edit' } },

	  { path: 'homes/:home/photos/add', canActivate: [AuthService], component: PhotoHomeFormComponent, data: { resource: 'HOME', mode: 'add' } },
	  { path: 'homes/:home/photos/:id/view', canActivate: [AuthService], component: PhotoHomeFormComponent, data: { resource: 'HOME', mode: 'view' } },
	  { path: 'homes/:home/photos/:id/edit', canActivate: [AuthService], component: PhotoHomeFormComponent, data: { resource: 'HOME', mode: 'edit' } },

	  { path: 'homes/:home/floorplans/add', canActivate: [AuthService], component: FloorPlanHomeFormComponent, data: { resource: 'HOME', mode: 'add' } },
	  { path: 'homes/:home/floorplans/:id/view', canActivate: [AuthService], component: FloorPlanHomeFormComponent, data: { resource: 'HOME', mode: 'view' } },
	  { path: 'homes/:home/floorplans/:id/edit', canActivate: [AuthService], component: FloorPlanHomeFormComponent, data: { resource: 'HOME', mode: 'edit' } },

	  { path: 'homes/:home/documents/add', canActivate: [AuthService], component: DocumentHomeFormComponent, data: { resource: 'HOME', mode: 'add' } },
	  { path: 'homes/:home/documents/:id/view', canActivate: [AuthService], component: DocumentHomeFormComponent, data: { resource: 'HOME', mode: 'view' } },
	  { path: 'homes/:home/documents/:id/edit', canActivate: [AuthService], component: DocumentHomeFormComponent, data: { resource: 'HOME', mode: 'edit' } },

	  { path: 'homes/:home/itemses/add', canActivate: [AuthService], component: ItemsHomeFormComponent, data: { resource: 'HOME', mode: 'add' } },
	  { path: 'homes/:home/itemses/:id/view', canActivate: [AuthService], component: ItemsHomeFormComponent, data: { resource: 'HOME', mode: 'view' } },
	  { path: 'homes/:home/itemses/:id/edit', canActivate: [AuthService], component: ItemsHomeFormComponent, data: { resource: 'HOME', mode: 'edit' } },

	  { path: 'contacts', canActivate: [AuthService], component: ContactTableComponent, data: { resource: 'CONTACT', mode: 'list' } },
	  { path: 'contacts/add', canActivate: [AuthService], component: ContactFormComponent, data: { resource: 'CONTACT', mode: 'add' } },
	  { path: 'contacts/:id/view', canActivate: [AuthService], component: ContactFormComponent, data: { resource: 'CONTACT', mode: 'view' } },
	  { path: 'contacts/:id/edit', canActivate: [AuthService], component: ContactFormComponent, data: { resource: 'CONTACT', mode: 'edit' } },

	  { path: 'valuations', canActivate: [AuthService], component: ValuationTableComponent, data: { resource: 'VALUATION', mode: 'list' } },
	  { path: 'valuations/add', canActivate: [AuthService], component: ValuationFormComponent, data: { resource: 'VALUATION', mode: 'add' } },
	  { path: 'valuations/:id/view', canActivate: [AuthService], component: ValuationFormComponent, data: { resource: 'VALUATION', mode: 'view' } },
	  { path: 'valuations/:id/edit', canActivate: [AuthService], component: ValuationFormComponent, data: { resource: 'VALUATION', mode: 'edit' } },

	  { path: 'contacts/:contact/valuations/add', canActivate: [AuthService], component: ValuationContactFormComponent, data: { resource: 'CONTACT', mode: 'add' } },
	  { path: 'contacts/:contact/valuations/:id/view', canActivate: [AuthService], component: ValuationContactFormComponent, data: { resource: 'CONTACT', mode: 'view' } },
	  { path: 'contacts/:contact/valuations/:id/edit', canActivate: [AuthService], component: ValuationContactFormComponent, data: { resource: 'CONTACT', mode: 'edit' } },

	  { path: 'contacts/:contact/interactions/add', canActivate: [AuthService], component: InteractionContactFormComponent, data: { resource: 'CONTACT', mode: 'add' } },
	  { path: 'contacts/:contact/interactions/:id/view', canActivate: [AuthService], component: InteractionContactFormComponent, data: { resource: 'CONTACT', mode: 'view' } },
	  { path: 'contacts/:contact/interactions/:id/edit', canActivate: [AuthService], component: InteractionContactFormComponent, data: { resource: 'CONTACT', mode: 'edit' } },

	  { path: 'tasks', canActivate: [AuthService], component: TaskTableComponent, data: { resource: 'TASK', mode: 'list' } },
	  { path: 'tasks/add', canActivate: [AuthService], component: TaskFormComponent, data: { resource: 'TASK', mode: 'add' } },
	  { path: 'tasks/:id/view', canActivate: [AuthService], component: TaskFormComponent, data: { resource: 'TASK', mode: 'view' } },
	  { path: 'tasks/:id/edit', canActivate: [AuthService], component: TaskFormComponent, data: { resource: 'TASK', mode: 'edit' } },

	  { path: 'contacts/:contact/tasks/add', canActivate: [AuthService], component: TaskContactFormComponent, data: { resource: 'CONTACT', mode: 'add' } },
	  { path: 'contacts/:contact/tasks/:id/view', canActivate: [AuthService], component: TaskContactFormComponent, data: { resource: 'CONTACT', mode: 'view' } },
	  { path: 'contacts/:contact/tasks/:id/edit', canActivate: [AuthService], component: TaskContactFormComponent, data: { resource: 'CONTACT', mode: 'edit' } },

	  { path: 'contacts/:contact/notes/add', canActivate: [AuthService], component: NoteContactFormComponent, data: { resource: 'CONTACT', mode: 'add' } },
	  { path: 'contacts/:contact/notes/:id/view', canActivate: [AuthService], component: NoteContactFormComponent, data: { resource: 'CONTACT', mode: 'view' } },
	  { path: 'contacts/:contact/notes/:id/edit', canActivate: [AuthService], component: NoteContactFormComponent, data: { resource: 'CONTACT', mode: 'edit' } },

	  { path: 'sources', canActivate: [AuthService], component: SourceTableComponent, data: { resource: 'SOURCE', mode: 'list' } },
	  { path: 'sources/add', canActivate: [AuthService], component: SourceFormComponent, data: { resource: 'SOURCE', mode: 'add' } },
	  { path: 'sources/:id/view', canActivate: [AuthService], component: SourceFormComponent, data: { resource: 'SOURCE', mode: 'view' } },
	  { path: 'sources/:id/edit', canActivate: [AuthService], component: SourceFormComponent, data: { resource: 'SOURCE', mode: 'edit' } },

	  { path: 'tasktypes', canActivate: [AuthService], component: TasktypeTableComponent, data: { resource: 'TASKTYPE', mode: 'list' } },
	  { path: 'tasktypes/add', canActivate: [AuthService], component: TasktypeFormComponent, data: { resource: 'TASKTYPE', mode: 'add' } },
	  { path: 'tasktypes/:id/view', canActivate: [AuthService], component: TasktypeFormComponent, data: { resource: 'TASKTYPE', mode: 'view' } },
	  { path: 'tasktypes/:id/edit', canActivate: [AuthService], component: TasktypeFormComponent, data: { resource: 'TASKTYPE', mode: 'edit' } },

	  { path: 'shops', canActivate: [AuthService], component: ShopTableComponent, data: { resource: 'SHOP', mode: 'list' } },
	  { path: 'shops/add', canActivate: [AuthService], component: ShopFormComponent, data: { resource: 'SHOP', mode: 'add' } },
	  { path: 'shops/:id/view', canActivate: [AuthService], component: ShopFormComponent, data: { resource: 'SHOP', mode: 'view' } },
	  { path: 'shops/:id/edit', canActivate: [AuthService], component: ShopFormComponent, data: { resource: 'SHOP', mode: 'edit' } },

	  { path: 'categories', canActivate: [AuthService], component: CategoryTableComponent, data: { resource: 'CATEGORY', mode: 'list' } },
	  { path: 'categories/add', canActivate: [AuthService], component: CategoryFormComponent, data: { resource: 'CATEGORY', mode: 'add' } },
	  { path: 'categories/:id/view', canActivate: [AuthService], component: CategoryFormComponent, data: { resource: 'CATEGORY', mode: 'view' } },
	  { path: 'categories/:id/edit', canActivate: [AuthService], component: CategoryFormComponent, data: { resource: 'CATEGORY', mode: 'edit' } },

	  { path: 'furnitures', canActivate: [AuthService], component: FurnitureTableComponent, data: { resource: 'FURNITURE', mode: 'list' } },
	  { path: 'furnitures/add', canActivate: [AuthService], component: FurnitureFormComponent, data: { resource: 'FURNITURE', mode: 'add' } },
	  { path: 'furnitures/:id/view', canActivate: [AuthService], component: FurnitureFormComponent, data: { resource: 'FURNITURE', mode: 'view' } },
	  { path: 'furnitures/:id/edit', canActivate: [AuthService], component: FurnitureFormComponent, data: { resource: 'FURNITURE', mode: 'edit' } },

	  { path: 'provinces', canActivate: [AuthService], component: ProvinceTableComponent, data: { resource: 'PROVINCE', mode: 'list' } },
	  { path: 'provinces/add', canActivate: [AuthService], component: ProvinceFormComponent, data: { resource: 'PROVINCE', mode: 'add' } },
	  { path: 'provinces/:id/view', canActivate: [AuthService], component: ProvinceFormComponent, data: { resource: 'PROVINCE', mode: 'view' } },
	  { path: 'provinces/:id/edit', canActivate: [AuthService], component: ProvinceFormComponent, data: { resource: 'PROVINCE', mode: 'edit' } },

	  { path: 'towns', canActivate: [AuthService], component: TownTableComponent, data: { resource: 'TOWN', mode: 'list' } },
	  { path: 'towns/add', canActivate: [AuthService], component: TownFormComponent, data: { resource: 'TOWN', mode: 'add' } },
	  { path: 'towns/:id/view', canActivate: [AuthService], component: TownFormComponent, data: { resource: 'TOWN', mode: 'view' } },
	  { path: 'towns/:id/edit', canActivate: [AuthService], component: TownFormComponent, data: { resource: 'TOWN', mode: 'edit' } },

	  { path: 'houses', canActivate: [AuthService], component: HouseTableComponent, data: { resource: 'HOUSE', mode: 'list' } },
	  { path: 'houses/add', canActivate: [AuthService], component: HouseFormComponent, data: { resource: 'HOUSE', mode: 'add' } },
	  { path: 'houses/:id/view', canActivate: [AuthService], component: HouseFormComponent, data: { resource: 'HOUSE', mode: 'view' } },
	  { path: 'houses/:id/edit', canActivate: [AuthService], component: HouseFormComponent, data: { resource: 'HOUSE', mode: 'edit' } },

	  { path: 'houses/:house/histories/add', canActivate: [AuthService], component: HistoryHouseFormComponent, data: { resource: 'HOUSE', mode: 'add' } },
	  { path: 'houses/:house/histories/:hash/view', canActivate: [AuthService], component: HistoryHouseFormComponent, data: { resource: 'HOUSE', mode: 'view' } },
	  { path: 'houses/:house/histories/:hash/edit', canActivate: [AuthService], component: HistoryHouseFormComponent, data: { resource: 'HOUSE', mode: 'edit' } },

	  { path: 'scans', canActivate: [AuthService], component: ScanTableComponent, data: { resource: 'SCAN', mode: 'list' } },
	  { path: 'scans/add', canActivate: [AuthService], component: ScanFormComponent, data: { resource: 'SCAN', mode: 'add' } },
	  { path: 'scans/:id/view', canActivate: [AuthService], component: ScanFormComponent, data: { resource: 'SCAN', mode: 'view' } },
	  { path: 'scans/:id/edit', canActivate: [AuthService], component: ScanFormComponent, data: { resource: 'SCAN', mode: 'edit' } },

	  { path: 'closes', canActivate: [AuthService], component: CloseTableComponent, data: { resource: 'CLOSE', mode: 'list' } },
	  { path: 'closes/add', canActivate: [AuthService], component: CloseFormComponent, data: { resource: 'CLOSE', mode: 'add' } },
	  { path: 'closes/:reference/view', canActivate: [AuthService], component: CloseFormComponent, data: { resource: 'CLOSE', mode: 'view' } },
	  { path: 'closes/:reference/edit', canActivate: [AuthService], component: CloseFormComponent, data: { resource: 'CLOSE', mode: 'edit' } },

	  { path: 'principals', canActivate: [AuthService], component: PrincipalTableComponent, data: { resource: 'PRINCIPAL', mode: 'list' } },
	  { path: 'principals/add', canActivate: [AuthService], component: PrincipalFormComponent, data: { resource: 'PRINCIPAL', mode: 'add' } },
	  { path: 'principals/:id/view', canActivate: [AuthService], component: PrincipalFormComponent, data: { resource: 'PRINCIPAL', mode: 'view' } },
	  { path: 'principals/:id/edit', canActivate: [AuthService], component: PrincipalFormComponent, data: { resource: 'PRINCIPAL', mode: 'edit' } },

	  { path: 'roles/:role/privileges/add', canActivate: [AuthService], component: PrivilegeRoleFormComponent, data: { resource: 'ROLE', mode: 'add' } },
	  { path: 'roles/:role/privileges/:id/view', canActivate: [AuthService], component: PrivilegeRoleFormComponent, data: { resource: 'ROLE', mode: 'view' } },
	  { path: 'roles/:role/privileges/:id/edit', canActivate: [AuthService], component: PrivilegeRoleFormComponent, data: { resource: 'ROLE', mode: 'edit' } },

	  { path: 'groups', canActivate: [AuthService], component: GroupTableComponent, data: { resource: 'GROUP', mode: 'list' } },
	  { path: 'groups/add', canActivate: [AuthService], component: GroupFormComponent, data: { resource: 'GROUP', mode: 'add' } },
	  { path: 'groups/:id/view', canActivate: [AuthService], component: GroupFormComponent, data: { resource: 'GROUP', mode: 'view' } },
	  { path: 'groups/:id/edit', canActivate: [AuthService], component: GroupFormComponent, data: { resource: 'GROUP', mode: 'edit' } },

	  { path: 'roles', canActivate: [AuthService], component: RoleTableComponent, data: { resource: 'ROLE', mode: 'list' } },
	  { path: 'roles/add', canActivate: [AuthService], component: RoleFormComponent, data: { resource: 'ROLE', mode: 'add' } },
	  { path: 'roles/:id/view', canActivate: [AuthService], component: RoleFormComponent, data: { resource: 'ROLE', mode: 'view' } },
	  { path: 'roles/:id/edit', canActivate: [AuthService], component: RoleFormComponent, data: { resource: 'ROLE', mode: 'edit' } },

	  { path: 'configs', canActivate: [AuthService], component: ConfigTableComponent, data: { resource: 'CONFIG', mode: 'list' } },
	  { path: 'configs/add', canActivate: [AuthService], component: ConfigFormComponent, data: { resource: 'CONFIG', mode: 'add' } },
	  { path: 'configs/:code/view', canActivate: [AuthService], component: ConfigFormComponent, data: { resource: 'CONFIG', mode: 'view' } },
	  { path: 'configs/:code/edit', canActivate: [AuthService], component: ConfigFormComponent, data: { resource: 'CONFIG', mode: 'edit' } },

	]
  }
];

@NgModule({
  imports: [
	RouterModule.forRoot(routes)
  ],
  exports: [
	RouterModule
  ]
})
export class CrudRoutingModule { }
