// tslint:disable: no-string-literal
import { Component, OnInit } from '@angular/core';

import { Login } from 'app/shared/model/login';
import { AuthService } from 'app/shared/services/auth.service';
import { SessionService } from 'app/shared/services/session.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[app-header-user]',
  templateUrl: './header-user.component.html'
})
export class HeaderUserComponent implements OnInit {

  login: Login = new Login();
  photo: string;

  constructor(private auth: AuthService) {}

  ngDoCheck() {
    // Get current login
    this.login = this.auth.login;
  }

  ngOnInit() {
    // Get current login
    this.login = this.auth.login;

    // Get photo, from the token, if exists
    const pic = this.login.decoded['picture'];
    if (pic) {
      this.photo = pic;
    } else {
      this.photo = 'assets/user.png';
    }
  }

}
