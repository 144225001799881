import { Audit } from 'app/shared/model/audit';

// Province class
export class Province {

  // Key
  id: string;

  // Fields
  name: string;
  prid: string;
  urlid: string;
  prfc: string;
  urlfc: string;
  prpc: string;
  urlpc: string;

  // Audit
  audit : Audit = new Audit();
}

