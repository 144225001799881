import { Component, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[app-header-tasks]',
  templateUrl: './header-tasks.component.html'
})
export class HeaderTasksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
