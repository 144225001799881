// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Company } from 'app/crud/houses/company/company';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all companies
  readAll(sort: string): Observable<Page<Company>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of companies
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Company>> {
    let url = `${this.globals.server}companies?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Company>>(url).pipe(
      // tap(_ => console.log('read companies')),
      catchError(this.handleError<Page<Company>>('read Company'))
    );
  }

  // Read one company
  readOne(id: number): Observable<Company> {
    return this.http.get<Company>(`${this.globals.server}companies/${id}`).pipe(
      // tap(_ => console.log(`readOne company ${id} `)),
      catchError(this.handleError<Company>(`readOne Company ${id} `))
    );
  }

  // Count companies
  count(): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.globals.server}companies/count`).pipe(
      // tap(_ => console.log('count companies')),
      catchError(this.handleError('count Company', []))
    );
  }

  // Create company
  create(o: Company): Observable<Company> {
    const input = new FormData();
    if (o.logo) { input.append('logo', o.logo, o.logoname); }
    delete o.logo;
    input.append('data', JSON.stringify(o));
    return this.http.post<Company>(`${this.globals.server}companies`, input).pipe(
      // tap(_ => console.log('create company')),
      catchError(this.handleError<Company>('create Company'))
    );
  }

  // Update company
  update(id: number, o: Company): Observable<any> {
    const input = new FormData();
    if (o.logo) { input.append('logo', o.logo, o.logoname); }
    delete o.logo;
    input.append('data', JSON.stringify(o));
    return this.http.put(`${this.globals.server}companies/${id}`, input).pipe(
      // tap(_ => console.log(`update company ${id} `)),
      catchError(this.handleError<any>(`update Company ${id} `))
    );
  }

  // Delete company
  delete(id: number): Observable<Company> {
    return this.http.delete<Company>(`${this.globals.server}companies/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete company ${id} `)),
      catchError(this.handleError<Company>(`delete Company ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
