	<!-- Loading spinner -->
	<app-spinner *ngIf="isLoading"></app-spinner>

	<!-- Data -->
	<div *ngIf="data">

	  <!-- Header -->
	  <div class="box-header with-border">
		<h3 class="box-title">{{ 'LABEL.ENTITY.FLOORPLAN.TABLE'|translate }}</h3>
		<button *ngIf="mode!='view' && auth.canShow('CREATE:FLOORPLAN')"
		  [routerLink]="['/', parent, value, 'floorplans', 'add']" 
		  type="button" class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }} {{ 'LABEL.ENTITY.FLOORPLAN'|translate }}</button>
	  </div> <!-- end Header -->
	
	  <!-- Body -->
	  <div class="box-body dataTables_wrapper form-inline dt-bootstrap">

		<!-- Table -->
		<div class="row">
		<div class="col-sm-12">
		<table id="table-floorplan" class="table table-bordered table-hover dataTable" role="grid">

		  <!-- Titles row -->	
		  <thead>
			<tr role="row">
			  <!-- <th style="width:1%;"><div class="checkbox"><input type="checkbox"><label></label></div></th> -->
			  <th class="col-lg-1 " [ngClass]="isSorted('position')" (click)="doSort('position')">
			    {{'LABEL.FIELD.FLOORPLAN.POSITION'|translate}}
			  </th>
			  <th class="col-lg-1 " [ngClass]="isSorted('floor')" (click)="doSort('floor')">
			    {{'LABEL.FIELD.FLOORPLAN.FLOOR'|translate}}
			  </th>
			  <th class="col-lg-1  hidden-xs">
			    {{'LABEL.FIELD.FLOORPLAN.BEFORE'|translate}}
			  </th>
			  <th class="col-lg-1  hidden-xs">
			    {{'LABEL.FIELD.FLOORPLAN.AFTER'|translate}}
			  </th>
			  <th style="width:1%;">{{'LABEL.ACTIONS'|translate}}</th>
			</tr>
		  </thead>

		  <!-- Filters row -->	
		  <thead>
			<tr role="row">
			  <!-- <th></th> -->
			  <th class="">
			    <input 
			  	[(ngModel)]="search.position" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.FLOORPLAN.POSITION'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th class="">
			    <input 
			  	[(ngModel)]="search.floor" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.FLOORPLAN.FLOOR'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th></th>
			  <th></th>
			  <th>
				<button (click)="clearFilter()" type="button" class="btn btn-default btn-sm">
				  <i class="fa fa-filter" style="position: relative;">
					<i class="fa fa-ban" style="position: absolute; left: 0px; color:Tomato"></i>
				  </i>
				</button>
			  </th>
			</tr>
		  </thead>

		  <!-- Data rows -->					
		  <tbody>
			<tr role="row"[ngClass]="{ odd:odd, even:even }" *ngFor="let o of data.content; odd as odd; even as even">
			  <!-- <td><div class="checkbox"><input type="checkbox"><label></label></div></td> -->
			  <td class="text-right ">
			    {{ o.position|localNumber:'0-true' }}
			  </td>
			  <td class="">
			    {{ o.floor }}
			  </td>
			  <td class=" hidden-xs">
			    <a *ngIf="o.beforeType" href="{{ globals.server }}floorplans/{{ o.id }}/before" type="{{ o.beforeType }}">
			  	<i [class]="'fa fa-lg ' + mimeIcon(o.beforeType)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
			    </a>
			  </td>
			  <td class=" hidden-xs">
			    <a *ngIf="o.afterType" href="{{ globals.server }}floorplans/{{ o.id }}/after" type="{{ o.afterType }}">
			  	<i [class]="'fa fa-lg ' + mimeIcon(o.afterType)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
			    </a>
			  </td>
			  <td>
				<div class="btn-group">
				  <button *ngIf="mode!='view' && auth.canShow('UPDATE:FLOORPLAN')"
					[routerLink]="['/', parent, value, 'floorplans', o.id, 'edit']"
					type="button" class="btn btn-default btn-sm"><i class="fa fa-edit"></i>
				  </button>
				  <button
					[routerLink]="['/', parent, value, 'floorplans', o.id, 'view']"
					type="button" class="btn btn-default btn-sm"><i class="fa fa-eye"></i>
				  </button>
				</div>
			  </td>
			</tr>
		  </tbody>

		</table>
		</div>
		</div> <!-- end Table -->
	
		<!-- Pagination -->
		<app-pagination [total]="data.totalElements" [size]="data.size" [num]="data.numberOfElements" (go)="doPage($event)"></app-pagination>
	
	  </div> <!-- end Body -->

	</div> <!-- end Data -->
