import { Contact } from 'app/crud/customers/contact/contact';
import { Audit } from 'app/shared/model/audit';

// Interaction class
export class Interaction {

  // Key
  id: number;

  // Fields
  contact: Contact; // = new Contact();
  interaction: string;
  subject: string;
  message: string;
  comments: string;
  tags: string;

  // Audit
  audit : Audit = new Audit();
}

