<a *ngIf="session.lang" href="#" class="dropdown-toggle" data-toggle="dropdown">
  <img class="img-circle flag" [src]="'assets/i18n/' + session.lang.code + '.png'" [alt]="session.lang.name">
  <span class="label label-success">{{ session.lang.code | uppercase }}</span>
</a>
<ul *ngIf="globals.langs" class="dropdown-menu">
  <li>
    <ul class="menu">
      <li *ngFor="let l of globals.langs">
        <a (click)="changeLang(l.code)">
        <div class="pull-left"><img [src]="'assets/i18n/' + l.code + '.png'" class="img-circle" [alt]="l.name"></div>
        <h4>{{ l.name }}</h4>
        </a>
      </li>
    </ul>
  </li>
</ul>