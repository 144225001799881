<aside class="control-sidebar control-sidebar-dark">

  <!-- Side bar header navigation -->
  <ul class="nav nav-tabs nav-justified control-sidebar-tabs">

    <!-- Tabs -->
    <li *ngFor="let tab of tabs; first as first" [ngClass]="{active: first}">
      <a [href]="'#control-sidebar-' + tab.name + '-tab'" data-toggle="tab">
        <i class="fa" [ngClass]="tab.icon"></i>
      </a>
    </li>

  </ul>

  <!-- Content -->
  <div class="tab-content">

    <!-- Tab for each content -->
    <div *ngFor="let tab of tabs; first as first" class="tab-pane" [ngClass]="{active: first}" [id]="'control-sidebar-' + tab.name + '-tab'" >

      <!-- Home -->
      <div *ngIf="tab.name == 'home'">

        <!-- Recent activity -->
        <h3 class="control-sidebar-heading">Recent Activity</h3>
        <ul class="control-sidebar-menu">
          <li>
            <a href="javascript:;">
            <i class="menu-icon fa fa-birthday-cake bg-red"></i>
            <div class="menu-info">
              <h4 class="control-sidebar-subheading">Langdon's Birthday</h4>
              <p>Will be 23 on April 24th</p>
            </div>
            </a>
          </li>
        </ul>
      
        <!-- Task progress -->
        <h3 class="control-sidebar-heading">Tasks Progress</h3>
        <ul class="control-sidebar-menu">
          <li>
            <a href="javascript:;">
              <h4 class="control-sidebar-subheading">
              Custom Template Design
              <span class="pull-right-container">
                <span class="label label-danger pull-right">70%</span>
              </span>
              </h4>
              <div class="progress progress-xxs">
                <div class="progress-bar progress-bar-danger" style="width: 70%"></div>
              </div>
              </a>
          </li>
        </ul>

      </div>

      <!-- Stats -->
      <div *ngIf="tab.name == 'stats'">
        <h3 class="control-sidebar-heading">Stats Tab Content</h3>
      </div>

      <!-- Settings -->
      <div *ngIf="tab.name == 'settings'">
        <form method="post">
          <h3 class="control-sidebar-heading">General Settings</h3>
          <div class="form-group">
            <label class="control-sidebar-subheading">
              Report panel usage
              <input type="checkbox" class="pull-right" checked>
            </label>
            <p>
              Some information about this general settings option
            </p>
          </div>
        </form>
      </div>

    </div> <!-- *ngFor -->
  </div> <!-- Content -->

</aside>

<!-- Sidebard background -->
<div class="control-sidebar-bg"></div>