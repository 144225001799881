<div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important">  
  <div class="modal-dialog custom-alert" role="document">  
    <div class="modal-content">  

      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" (click)="message.nFn()">×</span>
        </button>
        <h4 class="modal-title">{{ message.title }}</h4>
      </div>

      <div class="modal-body">
          <p>{{ message.text }}</p>
      </div>

      <div class="modal-footer">
        <button type="submit" (click)="message.nFn()" class="btn btn-default">{{ 'LABEL.NO' | translate }}</button>
        <button type="button" (click)="message.yFn()" class="btn btn-primary">{{ 'LABEL.YES' | translate }}</button>
      </div>

    </div>  
  </div>  
</div>  
