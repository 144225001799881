import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  doc: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public globals: Globals
  ) {}

  ngOnInit() {}

  fullScreen() {
    this.doc = document.documentElement;
    this.doc.requestFullscreen();
  }

}
