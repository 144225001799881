// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Document } from 'app/crud/houses/document/document';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all documents
  readAll(sort: string): Observable<Page<Document>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of documents
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Document>> {
    let url = `${this.globals.server}documents?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Document>>(url).pipe(
      // tap(_ => console.log('read documents')),
      catchError(this.handleError<Page<Document>>('read Document'))
    );
  }

  // Read pages of documents from homes, homes cannot have composite keys
  readFromHome(home: string, page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Document>> {
    let url = `${this.globals.server}homes/${home}/documents?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Document>>(url).pipe(
      // tap(_ => console.log('read documents')),
      catchError(this.handleError<Page<Document>>('read Document'))
    );
  }

  // Read one document
  readOne(id: number): Observable<Document> {
    return this.http.get<Document>(`${this.globals.server}documents/${id}`).pipe(
      // tap(_ => console.log(`readOne document ${id} `)),
      catchError(this.handleError<Document>(`readOne Document ${id} `))
    );
  }

  // Count documents
  count(): Observable<Document[]> {
    return this.http.get<Document[]>(`${this.globals.server}documents/count`).pipe(
      // tap(_ => console.log('count documents')),
      catchError(this.handleError('count Document', []))
    );
  }

  // Create document
  create(o: Document): Observable<Document> {
    const input = new FormData();
    if (o.file) { input.append('file', o.file, o.filename); }
    delete o.file;
    input.append('data', JSON.stringify(o));
    return this.http.post<Document>(`${this.globals.server}documents`, input).pipe(
      // tap(_ => console.log('create document')),
      catchError(this.handleError<Document>('create Document'))
    );
  }

  // Update document
  update(id: number, o: Document): Observable<any> {
    const input = new FormData();
    if (o.file) { input.append('file', o.file, o.filename); }
    delete o.file;
    input.append('data', JSON.stringify(o));
    return this.http.put(`${this.globals.server}documents/${id}`, input).pipe(
      // tap(_ => console.log(`update document ${id} `)),
      catchError(this.handleError<any>(`update Document ${id} `))
    );
  }

  // Delete document
  delete(id: number): Observable<Document> {
    return this.http.delete<Document>(`${this.globals.server}documents/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete document ${id} `)),
      catchError(this.handleError<Document>(`delete Document ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
