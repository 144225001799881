import { Contact } from 'app/crud/customers/contact/contact';
import { Audit } from 'app/shared/model/audit';

// Note class
export class Note {

  // Key
  id: number;

  // Fields
  contact: Contact; // = new Contact();
  notedate: string;
  note: string;

  // Audit
  audit : Audit = new Audit();
}

