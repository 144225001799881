<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.CLOSE'|translate" [optional]="'LABEL.ENTITY.CLOSE.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="closeForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.CLOSE'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Key row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('reference')">
              <label class="control-label" for="reference">
                {{ 'LABEL.FIELD.CLOSE.REFERENCE'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <input
                formControlName="reference"
                maxlength="30"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CLOSE.REFERENCE':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('reference')?.msg|translate:err('reference')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Key row -->

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('oldprice')">
              <label class="control-label" for="oldprice">
                {{ 'LABEL.FIELD.CLOSE.OLDPRICE'|translate }}
              </label>
              <input
                formControlName="oldprice"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CLOSE.OLDPRICE':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('oldprice')?.msg|translate:err('oldprice')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (2) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('newprice')">
              <label class="control-label" for="newprice">
                {{ 'LABEL.FIELD.CLOSE.NEWPRICE'|translate }}
              </label>
              <input
                formControlName="newprice"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CLOSE.NEWPRICE':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('newprice')?.msg|translate:err('newprice')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (3) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('renoprice')">
              <label class="control-label" for="renoprice">
                {{ 'LABEL.FIELD.CLOSE.RENOPRICE'|translate }}
              </label>
              <input
                formControlName="renoprice"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CLOSE.RENOPRICE':'')|translate }}"
                type="number"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('renoprice')?.msg|translate:err('renoprice')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (4) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('closedate')">
              <label class="control-label" for="closedate">
                {{ 'LABEL.FIELD.CLOSE.CLOSEDATE'|translate }}
              </label>
              <div class="input-group">
              <input
                formControlName="closedate"
                
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CLOSE.CLOSEDATE':'')|translate }}"
                type="text"
                bsDatepicker
                [bsConfig]="{containerClass:'theme-dark-blue'}"
                #closedate="bsDatepicker"
                class="form-control" autocomplete="off" >
              <div class="input-group-addon" (click)="closedate.toggle()"><i class="fa fa-calendar"></i></div>
              </div> <!-- End input group -->
              <span class="help-block">{{err('closedate')?.msg|translate:err('closedate')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (5) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('condition')">
              <label class="control-label" for="condition">
                {{ 'LABEL.FIELD.CLOSE.CONDITION'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <input
                formControlName="condition"
                maxlength="1"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CLOSE.CONDITION':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('condition')?.msg|translate:err('condition')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="closeForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:CLOSE')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="closeForm.invalid || closeForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:CLOSE')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:CLOSE')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
