// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Shop } from 'app/crud/decoration/shop/shop';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class ShopService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all shops
  readAll(sort: string): Observable<Page<Shop>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of shops
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Shop>> {
    let url = `${this.globals.server}shops?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Shop>>(url).pipe(
      // tap(_ => console.log('read shops')),
      catchError(this.handleError<Page<Shop>>('read Shop'))
    );
  }

  // Read one shop
  readOne(id: number): Observable<Shop> {
    return this.http.get<Shop>(`${this.globals.server}shops/${id}`).pipe(
      // tap(_ => console.log(`readOne shop ${id} `)),
      catchError(this.handleError<Shop>(`readOne Shop ${id} `))
    );
  }

  // Count shops
  count(): Observable<Shop[]> {
    return this.http.get<Shop[]>(`${this.globals.server}shops/count`).pipe(
      // tap(_ => console.log('count shops')),
      catchError(this.handleError('count Shop', []))
    );
  }

  // Create shop
  create(o: Shop): Observable<Shop> {
    const input = new FormData();
    if (o.logo) { input.append('logo', o.logo, o.logoname); }
    delete o.logo;
    input.append('data', JSON.stringify(o));
    return this.http.post<Shop>(`${this.globals.server}shops`, input).pipe(
      // tap(_ => console.log('create shop')),
      catchError(this.handleError<Shop>('create Shop'))
    );
  }

  // Update shop
  update(id: number, o: Shop): Observable<any> {
    const input = new FormData();
    if (o.logo) { input.append('logo', o.logo, o.logoname); }
    delete o.logo;
    input.append('data', JSON.stringify(o));
    return this.http.put(`${this.globals.server}shops/${id}`, input).pipe(
      // tap(_ => console.log(`update shop ${id} `)),
      catchError(this.handleError<any>(`update Shop ${id} `))
    );
  }

  // Delete shop
  delete(id: number): Observable<Shop> {
    return this.http.delete<Shop>(`${this.globals.server}shops/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete shop ${id} `)),
      catchError(this.handleError<Shop>(`delete Shop ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
