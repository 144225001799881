// Angular common modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap';

// Common modules
import { SharedModule } from 'app/shared/shared.module';
import { LayoutModule } from 'app/layout/layout.module';

// Crud components
import { HomeTableComponent } from 'app/crud/houses/home/home-table.component';
import { HomeFormComponent } from 'app/crud/houses/home/home-form.component';
import { CompanyTableComponent } from 'app/crud/houses/company/company-table.component';
import { CompanyFormComponent } from 'app/crud/houses/company/company-form.component';
import { CityTableComponent } from 'app/crud/houses/city/city-table.component';
import { CityFormComponent } from 'app/crud/houses/city/city-form.component';
import { HomeTypeTableComponent } from 'app/crud/houses/hometype/hometype-table.component';
import { HomeTypeFormComponent } from 'app/crud/houses/hometype/hometype-form.component';
import { PhotoHomeTableComponent } from 'app/crud/houses/photo/photo-home-table.component';
import { PhotoHomeFormComponent } from 'app/crud/houses/photo/photo-home-form.component';
import { FloorPlanHomeTableComponent } from 'app/crud/houses/floorplan/floorplan-home-table.component';
import { FloorPlanHomeFormComponent } from 'app/crud/houses/floorplan/floorplan-home-form.component';
import { DocumentHomeTableComponent } from 'app/crud/houses/document/document-home-table.component';
import { DocumentHomeFormComponent } from 'app/crud/houses/document/document-home-form.component';
import { ItemsHomeTableComponent } from 'app/crud/houses/items/items-home-table.component';
import { ItemsHomeFormComponent } from 'app/crud/houses/items/items-home-form.component';
import { ContactTableComponent } from 'app/crud/customers/contact/contact-table.component';
import { ContactFormComponent } from 'app/crud/customers/contact/contact-form.component';
import { ValuationTableComponent } from 'app/crud/customers/valuation/valuation-table.component';
import { ValuationFormComponent } from 'app/crud/customers/valuation/valuation-form.component';
import { ValuationContactTableComponent } from 'app/crud/customers/valuation/valuation-contact-table.component';
import { ValuationContactFormComponent } from 'app/crud/customers/valuation/valuation-contact-form.component';
import { InteractionContactTableComponent } from 'app/crud/customers/interaction/interaction-contact-table.component';
import { InteractionContactFormComponent } from 'app/crud/customers/interaction/interaction-contact-form.component';
import { TaskTableComponent } from 'app/crud/customers/task/task-table.component';
import { TaskFormComponent } from 'app/crud/customers/task/task-form.component';
import { TaskContactTableComponent } from 'app/crud/customers/task/task-contact-table.component';
import { TaskContactFormComponent } from 'app/crud/customers/task/task-contact-form.component';
import { NoteContactTableComponent } from 'app/crud/customers/note/note-contact-table.component';
import { NoteContactFormComponent } from 'app/crud/customers/note/note-contact-form.component';
import { SourceTableComponent } from 'app/crud/customers/source/source-table.component';
import { SourceFormComponent } from 'app/crud/customers/source/source-form.component';
import { TasktypeTableComponent } from 'app/crud/customers/tasktype/tasktype-table.component';
import { TasktypeFormComponent } from 'app/crud/customers/tasktype/tasktype-form.component';
import { ShopTableComponent } from 'app/crud/decoration/shop/shop-table.component';
import { ShopFormComponent } from 'app/crud/decoration/shop/shop-form.component';
import { CategoryTableComponent } from 'app/crud/decoration/category/category-table.component';
import { CategoryFormComponent } from 'app/crud/decoration/category/category-form.component';
import { FurnitureTableComponent } from 'app/crud/decoration/furniture/furniture-table.component';
import { FurnitureFormComponent } from 'app/crud/decoration/furniture/furniture-form.component';
import { ProvinceTableComponent } from 'app/crud/valuator/province/province-table.component';
import { ProvinceFormComponent } from 'app/crud/valuator/province/province-form.component';
import { TownTableComponent } from 'app/crud/valuator/town/town-table.component';
import { TownFormComponent } from 'app/crud/valuator/town/town-form.component';
import { HouseTableComponent } from 'app/crud/valuator/house/house-table.component';
import { HouseFormComponent } from 'app/crud/valuator/house/house-form.component';
import { HistoryHouseTableComponent } from 'app/crud/valuator/history/history-house-table.component';
import { HistoryHouseFormComponent } from 'app/crud/valuator/history/history-house-form.component';
import { ScanTableComponent } from 'app/crud/valuator/scan/scan-table.component';
import { ScanFormComponent } from 'app/crud/valuator/scan/scan-form.component';
import { CloseTableComponent } from 'app/crud/valuator/close/close-table.component';
import { CloseFormComponent } from 'app/crud/valuator/close/close-form.component';
import { PrincipalTableComponent } from 'app/crud/system/principal/principal-table.component';
import { PrincipalFormComponent } from 'app/crud/system/principal/principal-form.component';
import { PrivilegeRoleTableComponent } from 'app/crud/system/privilege/privilege-role-table.component';
import { PrivilegeRoleFormComponent } from 'app/crud/system/privilege/privilege-role-form.component';
import { GroupTableComponent } from 'app/crud/system/group/group-table.component';
import { GroupFormComponent } from 'app/crud/system/group/group-form.component';
import { RoleTableComponent } from 'app/crud/system/role/role-table.component';
import { RoleFormComponent } from 'app/crud/system/role/role-form.component';
import { ConfigTableComponent } from 'app/crud/system/config/config-table.component';
import { ConfigFormComponent } from 'app/crud/system/config/config-form.component';

@NgModule({
  declarations: [
	HomeTableComponent,
	HomeFormComponent,
	CompanyTableComponent,
	CompanyFormComponent,
	CityTableComponent,
	CityFormComponent,
	HomeTypeTableComponent,
	HomeTypeFormComponent,
	PhotoHomeTableComponent,
	PhotoHomeFormComponent,
	FloorPlanHomeTableComponent,
	FloorPlanHomeFormComponent,
	DocumentHomeTableComponent,
	DocumentHomeFormComponent,
	ItemsHomeTableComponent,
	ItemsHomeFormComponent,
	ContactTableComponent,
	ContactFormComponent,
	ValuationTableComponent,
	ValuationFormComponent,
	ValuationContactTableComponent,
	ValuationContactFormComponent,
	InteractionContactTableComponent,
	InteractionContactFormComponent,
	TaskTableComponent,
	TaskFormComponent,
	TaskContactTableComponent,
	TaskContactFormComponent,
	NoteContactTableComponent,
	NoteContactFormComponent,
	SourceTableComponent,
	SourceFormComponent,
	TasktypeTableComponent,
	TasktypeFormComponent,
	ShopTableComponent,
	ShopFormComponent,
	CategoryTableComponent,
	CategoryFormComponent,
	FurnitureTableComponent,
	FurnitureFormComponent,
	ProvinceTableComponent,
	ProvinceFormComponent,
	TownTableComponent,
	TownFormComponent,
	HouseTableComponent,
	HouseFormComponent,
	HistoryHouseTableComponent,
	HistoryHouseFormComponent,
	ScanTableComponent,
	ScanFormComponent,
	CloseTableComponent,
	CloseFormComponent,
	PrincipalTableComponent,
	PrincipalFormComponent,
	PrivilegeRoleTableComponent,
	PrivilegeRoleFormComponent,
	GroupTableComponent,
	GroupFormComponent,
	RoleTableComponent,
	RoleFormComponent,
	ConfigTableComponent,
	ConfigFormComponent,
  ],
  imports: [
	CommonModule,
	RouterModule,
	FormsModule,
	ReactiveFormsModule,
	NgSelectModule,
	LayoutModule,
	SharedModule,
	BsDatepickerModule
  ],
  providers: [
  ]
})
export class CrudModule { }
