<section class="content-header">

  <h1>
    {{ header }}
    <small>{{ optional }}</small>
  </h1>

  <!--
  <ol class="breadcrumb">
    <li><a href="#"><i class="fa fa-dashboard"></i> Level</a></li>
    <li class="active">Here</li>
  </ol>
  -->

</section>
