import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { SessionService } from 'app/shared/services/session.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

  constructor(
    private session: SessionService,
    private translate: TranslateService) {
    // Add one and default language
    this.session.setLang(this.translate.getBrowserLang());
    console.log('App created');
  }

}
