import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Validators, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap';
import * as moment from 'moment';

import { ValuationService } from 'app/crud/customers/valuation/valuation.service';
import { Valuation } from 'app/crud/customers/valuation/valuation';

import { CompanyService } from 'app/crud/houses/company/company.service';
import { Company } from 'app/crud/houses/company/company';
import { ContactService } from 'app/crud/customers/contact/contact.service';
import { Contact } from 'app/crud/customers/contact/contact';

import { ConfirmDialogService } from 'app/layout/modal/confirm-dialog.service';
import { SessionService } from 'app/shared/services/session.service';
import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-valuation-contact-form',
  templateUrl: './valuation-contact-form.component.html',
})
export class ValuationContactFormComponent implements OnInit {

  // Defalt form mode
  mode = 'add';

  // Select fields
  companyoptions: Company[] = [];

  // Form
  valuationForm = this.fb.group({
    // Fields
    company: [{ value: '', disabled: false }, []],
    reference: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(50), ]],
    zone: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(50), ]],
    address: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(1000), ]],
    zip: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(10), ]],
    city: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(1000), ]],
    form: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(1000), ]],
    why: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(100), ]],
    timeframe: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(100), ]],
    tags: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(1000), ]],
    comments: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(2000), ]],
    file: [{ value: '', disabled: false }, []],
    createdon: [{ value: '', disabled: true }],
    updatedon: [{ value: '', disabled: true }],
  });

  // Working record
  data: Valuation = new Valuation();

  // Loading indicators
  isLoading = false;
  fileLoaded = false;

  // Components constructor
  constructor(
    private apiCompany: CompanyService,
    private apiContact: ContactService,
    private api: ValuationService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private location: Location,
    private confirm: ConfirmDialogService,
    private session: SessionService,
    private auth: AuthService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
  }

  // Component initialization
  ngOnInit() {
    // Init data
    this.data = new Valuation();
    this.data.company = new Company();

    // Load company lookup
    this.loadCompany();

    // Set the master relation field
    this.data.contact = new Contact();
    this.data.contact.id = this.route.snapshot.params.contact;
    this.mapDataToForm(this.data, this.valuationForm);

    // Get form mode
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Disable fields in view mode
    if (this.mode === 'view') {
      Object.keys(this.valuationForm.controls).forEach(key => {
        this.valuationForm.get(key).disable();
      });
    }

    // Loads the record
    if (this.mode !== 'add') {
      this.load(
        this.route.snapshot.params.id
      );
    }
  }

  mapDataToForm(data: Valuation, form: FormGroup) {
    if (data.company) { form.get('company').setValue(data.company.id); } else { data.company = new Company(); }
    form.get('reference').setValue(data.reference);
    form.get('zone').setValue(data.zone);
    form.get('address').setValue(data.address);
    form.get('zip').setValue(data.zip);
    form.get('city').setValue(data.city);
    form.get('form').setValue(data.form);
    form.get('why').setValue(data.why);
    form.get('timeframe').setValue(data.timeframe);
    form.get('tags').setValue(data.tags);
    form.get('comments').setValue(data.comments);
    this.setDateTime(form.get('createdon'), data.audit.createdon, 'L LTS');
  }

  mapFormToData(form: FormGroup, data: Valuation) {
    if (!form.get('company').value) { data.company = null; } else { data.company.id = form.get('company').value; }
    data.reference = form.get('reference').value;
    data.zone = form.get('zone').value;
    data.address = form.get('address').value;
    data.zip = form.get('zip').value;
    data.city = form.get('city').value;
    data.form = form.get('form').value;
    data.why = form.get('why').value;
    data.timeframe = form.get('timeframe').value;
    data.tags = form.get('tags').value;
    data.comments = form.get('comments').value;
  }

  // Load selected record
  load(id) {
    this.isLoading = true;
    this.api.readOne(id).subscribe(
      res => {
        this.data = res;
        this.mapDataToForm(this.data, this.valuationForm);
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('view', 'VALUATION', err);
      });
  }

  // Create a new record
  createData() {
    this.isLoading = true;
    this.mapFormToData(this.valuationForm, this.data);
    this.api.create(this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('add', 'VALUATION');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('add', 'VALUATION', err);
      });
  }

  // Update record
  updateData() {
    this.isLoading = true;
    this.mapFormToData(this.valuationForm, this.data);
    this.api.update(this.data.id, this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('update', 'VALUATION');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('update', 'VALUATION', err);
      });
  }

  // Delete record
  deleteData() {
    const title = this.translate.instant('MESSAGE.DELETE') + ' ' + this.translate.instant('LABEL.ENTITY.VALUATION');
    const text  = this.translate.instant('MESSAGE.SURE');
    this.confirm.confirmThis(title, text, () => {}, () => {
      this.isLoading = true;
      this.api.delete(this.data.id).subscribe(
        res => {
          this.isLoading = false;
          this.globals.crudOk('delete', 'VALUATION');
          this.back();
        },
        err => {
          this.isLoading = false;
          this.globals.crudKo('delete', 'VALUATION', err);
        });
    });
  }

  // Return to list page
  back() {
    this.location.back();
  }

  // Selected file
  onFileChange(field, event) {
    if (event.target.files && event.target.files.length) {
      this.data[field] = event.target.files[0];
      this.data[field + 'type'] = event.target.files[0].type;
      this.data[field + 'name'] = event.target.files[0].name;
      this.valuationForm.markAsDirty();
    }
  }

  // Delete file
  onFileDelete(field, event) {
    this.data[field] = null;
    this.data[field + 'type'] = null;
    this.data[field + 'name'] = '{Delete}';
    this.valuationForm.markAsDirty();
  }

  // Field error status and  message
  err(name: string) {
    return Globals.err(this.valuationForm, name);
  }

  // Load company lookup
  loadCompany() {
    this.apiCompany.readAll('+name').subscribe(
      res => {
        this.companyoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  dateValidator(format: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (control.value === null || control.value === '') return null;
      moment.locale(this.session.lang.code);
      const date = moment.utc(control.value, format);
      return date.isValid() ? null : { invaliddate: true };
    };
  }

  getDateTime(control: AbstractControl, date: any, format: string) {
    if (control.value === null || control.value === '') return null;
    // Ñapa
    const aux = control.value;
    if (typeof aux != 'string')
      aux.setHours(aux.getHours() - aux.getTimezoneOffset() / 60);
    // Ñapa
    moment.locale(this.session.lang.code);
    const d = moment.utc(aux, format);
    if (d.isValid()) {
      return d.toISOString();
    } else {
      return date;
    }
  }

  setDateTime(control: AbstractControl, date: any, format: string) {
    moment.locale(this.session.lang.code);
    const d = moment.utc(date, 'YYYY-MM-DD');
    if (d.isValid()) {
      control.setValue(d.format(format));
    }
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
