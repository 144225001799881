import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap';

import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';
import { SessionService } from 'app/shared/services/session.service';
import { Page } from 'app/shared/model/page';

import { HomeService } from 'app/crud/houses/home/home.service';
import { Home } from 'app/crud/houses/home/home';

import { HomeTypeService } from 'app/crud/houses/hometype/hometype.service';
import { HomeType } from 'app/crud/houses/hometype/hometype';
import { CompanyService } from 'app/crud/houses/company/company.service';
import { Company } from 'app/crud/houses/company/company';
import { CityService } from 'app/crud/houses/city/city.service';
import { City } from 'app/crud/houses/city/city';

@Component({
  selector: 'app-home-table',
  templateUrl: './home-table.component.html',
})
export class HomeTableComponent implements OnInit {

  // Select fields
  hometypeoptions: HomeType[] = [];
  companyoptions: Company[] = [];
  cityoptions: City[] = [];

  // Defalt form mode
  mode = 'edit';

  // Search fields
  search = {
    code: '',
    active: '',
    web: '',
    sold: '',
    company_id: '',
    company_name: '',
    address: '',
    refcat: '',
    num: '',
    sellprice: '',
    renoprice: '',
    squaremeters: '',
  };

  // Current page
  page = 0;

  // Sort field and direction
  sort = 'code';
  dir = '+';

  // Current filter
  filter = '';

  // One page of data
  data: Page<Home>;

  // Loading indicator
  isLoading = true;

  // Event debouncer
  debounce: any;

  constructor(
    private apiHomeType: HomeTypeService,
    private apiCompany: CompanyService,
    private apiCity: CityService,
    private api: HomeService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private session: SessionService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
      if (this.session.search.form == 'Home') {
        this.search = this.session.search.data;
        this.sort = this.session.search.sort;
        this.dir = this.session.search.dir;
        this.setFilter();  
      }
    }

  ngOnInit() {
    // Mode, if sub-table
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Load company lookup
    this.loadCompany();

    // Loads the table
    this.load();
  }

  // Loads the table
  load() {
    this.isLoading = true;
    this.api.read(this.page, this.dir + this.sort, this.filter).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('read', 'HOME', err);
      }
    );
  }

  isSorted(field: string) {
    if (field === this.sort && this.dir === '-') {
      return 'sorting_desc';
    } else if (field === this.sort && this.dir === '+') {
      return 'sorting_asc';
    }
    return 'sorting';
  }

  doPage(page: number) {
    // Change page
    this.page = page;
    this.load();
  }

  doSort(field: string) {
    // Not sorted? Sort asc
    if (this.sort !== field) {
      if (field === 'createdon' || field === 'updatedon')
      	this.sort = 'audit.' + field;
      else
      	this.sort = field;
      this.dir = '+';
    } else {
      // Sorted asc? Sort desc
      if (this.dir === '+') {
        this.dir = '-';
      } else {
        // Else do not sort
        this.sort = '';
        this.dir = '';
      }
    }

    // Save sort
    this.session.search.form = 'Home';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;

    // Reload
    this.load();
  }

  setFilter() {
    // Filter string
    const f: string[] = [];
    Globals.pushFilter(f, 'code', this.search.code, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'active', this.search.active, 'eq', this.session.lang.code, 'Boolean');
    Globals.pushFilter(f, 'web', this.search.web, 'eq', this.session.lang.code, 'Boolean');
    Globals.pushFilter(f, 'sold', this.search.sold, 'eq', this.session.lang.code, 'Boolean');
    Globals.pushFilter(f, 'company.id', this.search.company_id, 'eq', this.session.lang.code, 'Long');
    Globals.pushFilter(f, 'company.name', this.search.company_name, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'address', this.search.address, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'refcat', this.search.refcat, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'num', this.search.num, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'sellprice', this.search.sellprice, 'eq', this.session.lang.code, 'Long');
    Globals.pushFilter(f, 'renoprice', this.search.renoprice, 'eq', this.session.lang.code, 'Long');
    Globals.pushFilter(f, 'squaremeters', this.search.squaremeters, 'eq', this.session.lang.code, 'Double');
    this.filter = Globals.encode(f.join(','));

    // Save filter
    this.session.search.form = 'Home';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;
  }

  doFilter(event: any) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.setFilter();
      this.page = 0;
      this.load();
    }, 400);
  }

  // Clear filter
  clearFilter() {
    // Clear filter string
    this.search.code = '';
    this.search.active = '';
    this.search.web = '';
    this.search.sold = '';
    this.search.company_id = '';
    this.search.company_name = '';
    this.search.address = '';
    this.search.refcat = '';
    this.search.num = '';
    this.search.sellprice = '';
    this.search.renoprice = '';
    this.search.squaremeters = '';

    // Clear
    this.session.search.data = this.search;

    // Search
    this.page = 0;
    this.filter = '';
    this.load();
  }

  // Load company lookup
  loadCompany() {
    this.apiCompany.readAll('').subscribe(
      res => {
        this.companyoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'COMPANY', err);
      }
    );
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
