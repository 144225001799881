import { Contact } from 'app/crud/customers/contact/contact';
import { Tasktype } from 'app/crud/customers/tasktype/tasktype';
import { Audit } from 'app/shared/model/audit';

// Task class
export class Task {

  // Key
  id: number;

  // Fields
  contact: Contact; // = new Contact();
  tasktype: Tasktype; // = new Tasktype();
  details: string;
  plandate: string;
  donedate: string;

  // Audit
  audit : Audit = new Audit();
}

