<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.PRINCIPAL'|translate" [optional]="'LABEL.ENTITY.PRINCIPAL.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="principalForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.PRINCIPAL'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Key row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('id')">
              <label class="control-label" for="id">
                {{ 'LABEL.FIELD.PRINCIPAL.ID'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <input
                formControlName="id"
                maxlength="80"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PRINCIPAL.ID':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('id')?.msg|translate:err('id')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Key row -->

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('email')">
              <label class="control-label" for="email">
                {{ 'LABEL.FIELD.PRINCIPAL.EMAIL'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <input
                formControlName="email"
                maxlength="120"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PRINCIPAL.EMAIL':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('email')?.msg|translate:err('email')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (2) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('name')">
              <label class="control-label" for="name">
                {{ 'LABEL.FIELD.PRINCIPAL.NAME'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <input
                formControlName="name"
                maxlength="120"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PRINCIPAL.NAME':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('name')?.msg|translate:err('name')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (3) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('password')">
              <label class="control-label" for="password">
                {{ 'LABEL.FIELD.PRINCIPAL.PASSWORD'|translate }}
              </label>
              <input
                formControlName="password"
                maxlength="80"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PRINCIPAL.PASSWORD':'')|translate }}"
                type="password" passwordview
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('password')?.msg|translate:err('password')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (4) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('role')">
              <label class="control-label" for="role">
                {{ 'LABEL.FIELD.PRINCIPAL.ROLE'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <ng-select 
                formControlName="role"
                multiple="true"
                clearable="false"
                [items]="roleoptions"
                bindLabel="name"
                bindValue="id"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PRINCIPAL.ROLE':'')|translate }}"
                style="width: 100%;">
              </ng-select>
              <span class="help-block">{{err('role')?.msg|translate:err('role')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (5) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('group')">
              <label class="control-label" for="group">
                {{ 'LABEL.FIELD.PRINCIPAL.GROUP'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <ng-select 
                formControlName="group"
                clearable="false"
                [items]="groupoptions"
                bindLabel="name"
                bindValue="id"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PRINCIPAL.GROUP':'')|translate }}"
                style="width: 100%;">
              </ng-select>
              <span class="help-block">{{err('group')?.msg|translate:err('group')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (6) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('provider')">
              <label class="control-label" for="provider">
                {{ 'LABEL.FIELD.PRINCIPAL.PROVIDER'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <ng-select 
                formControlName="provider"
                clearable="false"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PRINCIPAL.PROVIDER':'')|translate }}"
                style="width: 100%;">
                <ng-option *ngFor="let item of provideroptions" value="{{ item|uppercase }}">{{ 'ENUM.PROVIDER.'+item|translate }}</ng-option>
              </ng-select>
              <span class="help-block">{{err('provider')?.msg|translate:err('provider')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="principalForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:PRINCIPAL')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="principalForm.invalid || principalForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:PRINCIPAL')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:PRINCIPAL')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
